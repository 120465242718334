// import { Auth } from 'aws-amplify';
import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider, defaultTheme, Loading, useAuthenticated } from '@smarttime/core-ui';
import { TypographyProvider } from '@smarttime/typography';
import { App, Login } from '@src/pages';
import { trace } from '@logs';
import { SmartThemeProvider, cssVars } from '@src/themes';

const { defaults, modifiers } = cssVars;

// evergreen theme customization
const theme = {
	...defaultTheme,
};
theme.typography.fontFamilies.ui = 'var(--main-font-family)';
theme.typography.fontFamilies.display = 'var(--main-font-family)';

const AppIndex: React.FC = () => {

	return (
		<>
			<TypographyProvider Theme="Rubik" Size="S" Scale="S" />
			<SmartThemeProvider defaults={defaults} modifiers={modifiers} />
			<BrowserRouter>
				<ThemeProvider value={theme}>

					<App />

				</ThemeProvider>
			</BrowserRouter>
		</>
	);
};

export default (AppIndex);
