import { TypographyTheme } from '../../types/theme.types';

export const BitterTheme: TypographyTheme = {
	title: 'Bitter',
	combination: {
		headings: {
			font: 'Bitter',
			weight: [300, 400, 500, 700],
		},
		body: {
			font: 'Bitter',
			// font: 'PT Sans',
			weight: [300, 400, '400i', 500, '500i', 600, 700],
		},
		subsets: ['cyrillic'],
	},
	style: 'Sans-Serif',
};
