// eslint-disable-next-line import/named
import { getSnapshot, IAnyModelType, types } from 'mobx-state-tree';
import { DocumentPreset, createDocument } from '@smarttime/core';

import { ContentType } from '.';
import { DocumentInfoActionsList } from '../store.types';
import { trace } from '../../logger';

const DocumentInfoModel$1: IAnyModelType = types.model('DocumentInfo', {
	cid: types.identifier,
	dbId: types.maybeNull(types.string),
	type: types.number,
	content: ContentType,
	title: types.maybeNull(types.string),

	isToday: types.maybeNull(types.boolean),
	isPast: types.maybeNull(types.boolean),
	isFuture: types.maybeNull(types.boolean),
	isNew: types.maybeNull(types.boolean),
	isUpdated: types.maybeNull(types.boolean),
	isRemoved: types.maybeNull(types.boolean),

	startDate: types.maybeNull(types.Date),
	withStartTime: types.maybeNull(types.boolean),
	startTime: types.maybeNull(types.string),
	updatedAt: types.maybeNull(types.Date),
	createdAt: types.maybeNull(types.Date),
});

type DocumentInfoKeys = keyof DocumentPreset;
const actions: DocumentInfoActionsList = self => ({
	update(changes: DocumentPreset) {
		const oldDoc: DocumentPreset = createDocument(getSnapshot(self));
		const newDoc: DocumentPreset = createDocument(changes, oldDoc);

		const propertiesToUpdate: Record<string, unknown> = {};
		Object.keys(newDoc).forEach(key => {
			if (key === 'cid') {
				return;
			}
			const newValue = newDoc[key as DocumentInfoKeys];
			const oldValue = oldDoc[key as DocumentInfoKeys];
			if (newValue !== oldValue) {
				propertiesToUpdate[key as DocumentInfoKeys] = newValue;
			}
		});

		trace('SAVE CHANGES', { changes, propertiesToUpdate, oldDoc, newDoc });
		Object.entries(propertiesToUpdate).forEach(entry => {
			self[entry[0]] = entry[1];
		});
	},
	setUpdated(isUpdated: boolean) {
		self.isUpdated = isUpdated;
	},
	setRemoved(isRemoved: boolean) {
		self.isRemoved = isRemoved;
	},
});
export const DocumentInfoModel: IAnyModelType = DocumentInfoModel$1.actions(actions);
