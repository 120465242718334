import { DayType } from '@smarttime/core';

export type MappedDayProps = {
	day: DayType;
};
export type DayProps = {
	day: Date;
};

export enum DocumentViewMode {
	Editor = 'editor',
	Card = 'card',
	Link = 'link',
	Block = 'block',
}
export type SmartPanelType = 'inbox' | 'focus' | 'templates' | 'projects';
// | 'goals' | 'search';
