import { TypographyTheme } from '../../types/theme.types';

export const RobotoCondensedCabinTheme: TypographyTheme = {
	title: 'Roboto Condensed + Cabin',
	combination: {
		headings: {
			font: 'Roboto Condensed',
			weight: [400, 700],
		},
		body: {
			font: 'Cabin',
			weight: [400, 700],
		},
		subsets: ['cyrillic'],
	},
	style: 'Sans-Serif',
};
