import { useLayoutEffect, useState, useEffect } from 'react';

const cssValues = ([key, value]) => `${key}: ${value};`;
const styleNodeId = 'SmartThemeNode';
const createStyle = (defaults, modifiers) => {
	const style = document.createElement('style');
	style.type = 'text/css';
	style.id = styleNodeId;
	const defaultStyle = `html { ${Object.entries(defaults).map(cssValues).join('\n')} }`;

	const modifierStyles = Object.entries(modifiers)
		.map(
			([key, value]) =>
				`html[data-theme='${key}'] { ${Object.entries(value).map(cssValues).join('\n')} }`
		)
		.join('\n');

	style.innerHTML = `${defaultStyle} ${modifierStyles}`;
	return style;
};

// TODO: Run me from provider
export const SmartThemeProvider = ({ defaults, modifiers }) => {
	const [themeStyleNode, setThemeStyleNode] = useState(
		() => createStyle(defaults, modifiers),
		[defaults, modifiers]
	);

	useLayoutEffect(() => {
		const existedStyle = document.getElementById(styleNodeId);
		if (existedStyle) existedStyle.remove();
		document.head.appendChild(themeStyleNode);
		return () => document.head.removeChild(themeStyleNode);
	}, [themeStyleNode]);

	useEffect(() => {
		setThemeStyleNode(createStyle(defaults, modifiers));
	}, [defaults, modifiers]);

	return null;
};
