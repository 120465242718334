// eslint-disable-next-line
import { types } from 'mobx-state-tree';
// import { DocumentContentNode } from '@smarttime/core';

type DocumentContentNode = {
	type: string;
	text?: string;
	children: DocumentContentNode[];
	cid?: string;
	isMarked?: boolean;
	url?: string;
};
export const ContentType = types.custom<Array<DocumentContentNode>, Array<DocumentContentNode>>({
	name: 'ContentType',
	fromSnapshot(value: Array<DocumentContentNode>) {
		return value;
	},
	toSnapshot(value: Array<DocumentContentNode>) {
		return value;
	},
	isTargetType(value: Array<DocumentContentNode>): boolean {
		return Boolean(value instanceof Array && value.length > 0 && value[0].type);
	},
	getValidationMessage(): string {
		return '';
	},
});
