import React, { useState, useEffect, useMemo } from 'react';
import { Typography, getThemeByName } from '../../helpers';
import { buildCSS } from '../../utils';
import { FontLoader } from './FontLoader';
import { TypographyProps } from '../../types/typography.types';
import { FontPack } from 'types/font.types';

const createStyle = (props: TypographyProps) => {
	const style = document.createElement('style');
	style.id = 'stylesheet-demo';
	const theme = getThemeByName(props.Theme);

	if (typeof props.UseMaxWidth !== 'undefined') {
		theme.useMaxWidth = props.UseMaxWidth;
	}

	const css = buildCSS(Typography(props), theme);
	style.innerHTML = `
  ${css.cssVariables}
	${css.cssResetStyles}`;
	return style;
};

export const TypographyProvider: React.FC<Partial<TypographyProps>> = props => {
	const style = useMemo(() => createStyle(props), [props]);
	const theme = getThemeByName(props.Theme);
	const [stylesApplied, setStylesApplied] = useState(false);

	console.log('TypographyProvider RENDER', { theme });

	const fontPack: FontPack = useMemo(() => {
		return {
			body: {
				font: theme.combination.body.font,
				weight: theme.combination.body.weight,
			},
			headings: {
				font: theme.combination.headings.font,
				weight: theme.combination.headings.weight,
			},
			subsets: theme.combination.subsets,
		};
	}, [theme]);

	useEffect(() => {
		document.head.appendChild(style);
		setStylesApplied(true);
		return () => {
			document.head.removeChild(style);
			return;
		};
	}, [style]);

	return stylesApplied ? <FontLoader fontPack={fontPack} /> : null;
};

export type TypographyProviderProps = Partial<TypographyProps>;
