import React, { useMemo, forwardRef } from 'react';
import {
	Button as BaseButton,
	IconButton as BaseIconButton,
	IconProps,
	ButtonProps as BaseButtonProps,
	IconButtonProps as BaseIconButtonProps,
} from '../../';

export type IconComponent = React.ForwardRefExoticComponent<
	React.PropsWithoutRef<IconProps> & React.RefAttributes<SVGElement>
>;

export type ButtonStyledAppearance = 'minimal' | 'default' | 'primary';
export type ButtonScale = 'small' | 'default' | 'large' | 'xlarge';
export type ButtonStyledProps = Partial<{
	sz: ButtonScale | undefined;
}> &
	BaseButtonProps;

export type IconButtonStyledProps = Partial<{
	// icon: IconComponent;
	sz: ButtonScale;
	// appearance?: ButtonAppearance;
	// height?: number;
	// className?: string;
	// onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> &
	BaseIconButtonProps;

export type GetButtonClass = (appearance: ButtonStyledAppearance, className: string) => string;

export type GetButtonHeight = (sz: ButtonScale, height: number) => number;

export type ButtonFactoryProps = {
	getButtonClass: GetButtonClass;
	getButtonHeight: GetButtonHeight;
	// sz: ButtonScale;
};

export const ButtonStyled = forwardRef<HTMLButtonElement, ButtonStyledProps & ButtonFactoryProps>(
	(
		{
			children,
			height = 0,
			sz = 'default',
			appearance = 'default',
			className = '',
			getButtonHeight,
			getButtonClass,
			...restProps
		},
		ref
	) => {
		const buttonClass = useMemo(() => {
			return getButtonClass(appearance, className);
		}, [appearance, className, getButtonClass]);

		const resultHeight = useMemo(
			() => getButtonHeight(sz as ButtonScale, height as number),
			[sz, height, getButtonHeight]
		);

		const buttonProps = useMemo(
			() => ({
				...restProps,
				className: buttonClass,
				height: resultHeight,
			}),
			[buttonClass, resultHeight, restProps]
		);

		return (
			<BaseButton {...buttonProps} ref={ref}>
				{children}
			</BaseButton>
		);
	}
);

export const IconButtonStyled = forwardRef<
	HTMLButtonElement,
	IconButtonStyledProps & ButtonFactoryProps
>(
	(
		{
			appearance = 'default',
			height = 0,
			className = '',
			sz = 'default',
			getButtonClass,
			getButtonHeight,
			...restProps
		},
		ref
	) => {
		const buttonClass = useMemo(() => {
			return getButtonClass(appearance, className);
		}, [appearance, className, getButtonClass]);
		const resultHeight = useMemo(
			() => getButtonHeight(sz as ButtonScale, height as number),
			[sz, height, getButtonHeight]
		);
		const buttonProps = useMemo(
			() => ({
				...restProps,
				className: buttonClass,
				height: resultHeight,
			}),
			[buttonClass, resultHeight, restProps]
		);
		return <BaseIconButton {...buttonProps} ref={ref} />;
	}
);
