import { useEffect, useLayoutEffect, useState } from 'react';

export const ColorSchemeSwitcher = ({ schemeName = null }) => {
	const [colorSchemeName, setColorSchemeName] = useState('');

	useLayoutEffect(() => {
		document.documentElement.classList.remove('color-theme-in-transition');
		document.documentElement.classList.add('color-theme-in-transition');
		document.documentElement.setAttribute('data-theme', colorSchemeName);
		const timer = setTimeout(() => {
			document.documentElement.classList.remove('color-theme-in-transition');
		}, 1000);

		return () => {
			clearTimeout(timer);
			document.documentElement.classList.remove('color-theme-in-transition');
		};
	}, [colorSchemeName]);

	useEffect(() => {
		setColorSchemeName(schemeName);
	}, [schemeName]);

	return null;
};
