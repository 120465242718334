import { FontDetails, ScaleInfo } from '../../types/typography.types';
import { Size } from '../../types/base.types';
import { TypographyElement, FontThemeOverride } from '../../types/theme.types';

export const buildVariableName = (
	name: TypographyElement,
	size: Size | 'default',
	property: keyof FontDetails
): string => `--${name}-${size}-${property}`;

export const buildVariables = (
	name: TypographyElement,
	size: Size | 'default',
	settings: FontDetails
): Array<string> => {
	return Object.entries(settings).map(
		([key, value]) => `${buildVariableName(name, size, key as keyof FontDetails)}: ${value};`
	);
};

export const buildThemeOverridesVariableName = (
	name: TypographyElement,
	property: keyof FontThemeOverride
): string => `--theme-${name}-${property}`;

export const buildThemeOverrides = (
	name: TypographyElement,
	overrides: Partial<FontThemeOverride>
): Array<string> => {
	return Object.entries(overrides).map(
		([key, value]) =>
			`${buildThemeOverridesVariableName(name, key as keyof FontThemeOverride)}: ${value};`
	);
};

const buildSizeVarName = (property: string, size: Size) => `--${property}-size-${size}`;

export const buildMaxWidthVariableName = (size: Size): string =>
	buildSizeVarName('max-width', size);

export const buildSpaceVariableName = (size: Size): string => buildSizeVarName('space', size);

export const generateScaleVariableName = (scaleAttribute: keyof ScaleInfo): string =>
	`--typography-scale-width-${scaleAttribute}`;

export const buildScaleVariables = (scale: ScaleInfo): Array<string> => {
	return Object.keys(scale).map(
		attr =>
			`${generateScaleVariableName(attr as keyof ScaleInfo)}: ${
				scale[attr as keyof ScaleInfo]
			}%`
	);
};
