import { getEnv, getSnapshot, flow } from 'mobx-state-tree';
import { createDocument, DocumentInfo, DocumentPreset } from '@smarttime/core';
import { storeLogger } from '../../logger';

import {
	RootActionsList,
	SubscribeHandler,
	LoadUserDocuments,
	SetUserDocuments,
	SetDocument,
	SettleDocument,
	SelectDocument,
	InitDocument,
	SaveDocument,
	PushDocument,
	SaveAsTemplate,
	CreateFromTemplate,
	DuplicateDocument,
	DeleteDocument,
	IDocumentInfo,
} from '../store.types';

import {
	BaseDataService,
	ErrorHandler,
	UploadFileMethod,
	UriMetaProviderMethod,
	DocumentApi,
	ProjectApi,
} from '@smarttime/aws-server';
import { getLocalState, setLocalState } from '../../utils';

const getLocal = getLocalState('files');
const setLocal = setLocalState('files');

const logger = storeLogger.extend('documents');
const { trace } = logger;
export const rootActionsDocuments: RootActionsList = self => {
	const dataService = getEnv(self).data as BaseDataService;
	const documentService = dataService.apis.documents as DocumentApi;
	const projectService = dataService.apis.projects as ProjectApi;
	const areasMap = getEnv(self).documentAreasMap;

	const handleErrors: (err:string) => void = flow(function* (err) {
		try {
			yield dataService.currentUser();
		} catch {
			self.googleLogin();
		} finally {
			console.error("STORE::error: ", err);
		}
	})

	const loadUserDocuments: LoadUserDocuments = flow(function* () {
		trace('loadUserDocuments', { activeBoard: self.activeBoard });
		if (self.activeBoard && self.activeBoard.id) {
			if (projectService) {
				self.setProjects(
					yield projectService.getList({
						board: self.activeBoard.id,
					})
				);
			}
			try {
				const documents = yield documentService.getList({
					board: self.activeBoard.id,
				});

				self.setUserDocuments(documents);
			} catch (err) {
				handleErrors(err);
			}
			const syncFilter = (dbId: string): boolean => {
				if (dbId && dbId.startsWith('USR')) {
					// TODO: add boards and projects!
					trace('SKIP user objects', { dbId });
					return true;
				} else if (
					dbId &&
					self.activeBoard &&
					self.activeBoard.id &&
					!dbId.startsWith(`DOC#${self.activeBoard.id}#`)
				) {
					// TODO: handle this other way
					trace('SKIP doc from other board', { dbId });
					return true;
				}
				return false;
			};
			const onCreate: SubscribeHandler = document => {
				if (document) {
					const { cid, dbId } = document;

					if (dbId && syncFilter(dbId)) {
						return;
					}

					// trace('ON CREATE SYNC', { cid, dbId, document });
					const existingDocument: DocumentInfo = self.documents.get(cid);
					if (!existingDocument) {
						const documentToAdd = createDocument(document);
						// trace('ON CREATE SYNC -- ADD DOCUMENT', { cid, dbId, documentToAdd });
						self.settleDocument(documentToAdd);
					} else {
						// trace('ON CREATE SYNC -- SKIP', { cid, dbId, existingDocument });
					}
				}
			};

			const onDelete: SubscribeHandler = document => {
				if (document) {
					const { cid, dbId } = document;

					if (dbId && syncFilter(dbId)) {
						return;
					}
					const existingDocument: DocumentInfo = self.documents.get(cid);
					if (existingDocument) {
						self.deleteDocument(cid, true);
					}
				}
			};

			const onUpdate: SubscribeHandler = document => {
				if (document) {
					const { cid, dbId } = document;

					if (dbId && syncFilter(dbId)) {
						return;
					}

					const existingDocument: IDocumentInfo = self.documents.get(cid);
					// trace('ON UPDATE SYNC', { cid, dbId, document, existingDocument });
					if (existingDocument) {
						if (existingDocument.isUpdated) {
							existingDocument.setUpdated(false);
							// trace('ON UPDATE SYNC 2 - SELF UPDATE', { cid, dbId, updatedDocument });
						} else {
							self.smartKeysHandle(
								existingDocument,
								createDocument({
									...existingDocument.toJSON(),
									...document,
								}),
								document
							);
							existingDocument.update(document);
						}
					}
				}
			};
			const errorHandler: ErrorHandler = (type, error) => {
				trace('APPSYNC ERR', { type, error });
			};
			trace('INIT SUBSCRIPTIONS');

			return documentService.subscribe(onUpdate, onCreate, onDelete, errorHandler);
		}
		return null;
	});

	const selectDocument: SelectDocument = cid => {
		// // trace('SELECT DOCUMENT', { cid });
		self.selectedDocument = cid;
	};

	const setDocument: SetDocument = document => {
		const { cid } = document;
		self.documents.set(cid, document);
		return cid;
	};

	const setUserDocuments: SetUserDocuments = items => {
		items.map(item => {
			self.settleDocument(createDocument(item));
			// // trace('ADD new document', newDocument);
		});
	};

	const deleteDocument: DeleteDocument = (cid, isLocally = false) => {
		const document = self.documents.get(cid);
		const { selectedDocument } = self;
		if (document) {
			if (!isLocally && document.dbId) {
				// trace('DELETING', { id: document.dbId, document });
				documentService.delete(document.dbId);
			}

			if (selectedDocument && selectedDocument.cid === cid) {
				self.selectedDocument = null;
			}
			self.clearAreaKeys(document);
			self.documents.delete(cid);
			// document.setRemoved();
		}
	};

	const settleDocument: SettleDocument = (document, resetAreaKeys = false) => {
		const { dbId } = document;
		let { cid } = document;

		const existedDocument = dbId && self.getDocumentByDbId(dbId);

		if (existedDocument) {
			cid = document.cid = existedDocument.cid;
		}
		if (resetAreaKeys) {
			self.clearAreaKeys(document);
		}
		// trace('SET DOC', { document });
		self.setDocument(document);
		self.setAreaKeys(document);
		return cid;
	};
	const setAreaKeys: (document: IDocumentInfo) => void = document => {
		// // trace('KEYS', {
		// 	cid: document.cid,
		// 	startDate: document.startDate,
		// 	startTime: document.startTime,
		// 	withStartTime: document.withStartTime,
		// 	keys: areasMap.getAllKeys(document),
		// });
		const keys = areasMap.getAllKeys(document);
		// trace('SET KEYS', document.cid, ...keys);

		keys.forEach((key: string) => {
			let area = self.areas.get(key);
			if (!area) {
				self.areas.set(key, { key, items: [] });
				area = self.areas.get(key);
				area.items.push(document.cid);
			} else if (!area.items.some((doc: IDocumentInfo) => doc.cid === document.cid)) {
				area.items.push(document.cid);
			}
		});
	};
	// const resetAreaKeys:
	const clearAreaKeys: (document: IDocumentInfo) => void = document => {
		const keys = areasMap.getAllKeys(document);
		trace('REMOVE KEYS', document.cid, ...keys);

		keys.forEach((key: string) => {
			const area = self.areas.get(key);
			if (area) {
				area.items.remove(document);
				if (!area.items.length) {
					self.areas.delete(key);
				}
			}
		});
	};

	const saveDocument: SaveDocument = async (cid, changes = undefined) => {
		if (changes) {
			const document = self.documents.get(cid);

			// self.clearAreaKeys(document);
			// trace('SAVE CHANGES', { cid, changes });
			// const doc = document.toJSON();
			self.smartKeysHandle(
				document,
				createDocument({ ...document.toJSON(), ...changes }),
				changes
			);
			document.update(changes);
			// self.setAreaKeys(document);
		}
	};
	const smartKeysHandle: (
		document: DocumentInfo,
		nextDocument: DocumentInfo,
		changes: DocumentPreset
	) => void = (document, nextDocument, changes) => {
		trace('REMOVE FROM KEYS', {
			cid: document.cid,
			keys: areasMap.getAllKeys(document),
		});
		const { toRemove, toAdd } = areasMap.getKeysDiff(document, nextDocument, changes);
		trace({ toRemove, toAdd });
		toRemove.forEach((key: string) => {
			const area = self.areas.get(key);
			if (area) {
				area.items.remove(document);
				if (!area.items.length) {
					self.areas.delete(key);
				}
			}
		});
		toAdd.forEach((key: string) => {
			let area = self.areas.get(key);
			if (!area) {
				self.areas.set(key, { key, items: [] });
				area = self.areas.get(key);
			}
			if (!area.items.some((doc: IDocumentInfo) => doc.cid === document.cid)) {
				area.items.push(document.cid);
			}
		});
	};
	const pushDocument: PushDocument = async cid => {
		const document = self.documents.get(cid);
		// trace('PUSH DOCUMENT - before', { cid, document });
		const isUpdated = !document.isNew;

		document.update({ isUpdated, isNew: false });

		const documentToSave: DocumentInfo = {
			...document.toJSON(),
			board: self.activeBoard.id,
		};
		trace('PUSH DOCUMENT', { cid, documentToSave });
		try {
			const dbDocument = isUpdated
				? await documentService.update(documentToSave)
				: await documentService.create(documentToSave);

			if (dbDocument && dbDocument.dbId) {
				const doc = self.documents.get(cid);
				doc.update({ dbId: dbDocument.dbId });
			} else {
				// trace('ERR:', 'UNABLE to save document', { dbDocument });
			}
		} catch (err) {
			await self.handleErrors(err);
			// if ()
		}
	};
	const initDocument: InitDocument = (preset, basePreset = {}) => {
		const document = createDocument(preset, basePreset);
		document.isNew = true;
		const cid = self.settleDocument(document);
		return self.documents.get(cid);
	};
	const saveAsTemplate: SaveAsTemplate = document => {
		const newTpl: DocumentPreset = { ...document };

		delete newTpl.cid;
		delete newTpl.dbId;
		delete newTpl.startDate;
		delete newTpl.withStartTime;
		delete newTpl.endDate;
		delete newTpl.withEndTime;
		delete newTpl.isFocused;

		newTpl.isTemplate = true;

		const { cid } = self.initDocument(newTpl);
		// trace('SAVE AS TPL', { oldCid: document.cid, cid, newTpl, doc: self.documents.get(cid) });

		self.pushDocument(cid);

		return cid;
	};
	const createFromTemplate: CreateFromTemplate = (cid, preset = {}) => {
		const template = self.documents.get(cid);
		if (template) {
			const newDoc: DocumentPreset = { ...getSnapshot(template) };

			delete newDoc.isTemplate;

			return self.duplicateDocument(newDoc, preset);
		}
		return null;
	};
	const duplicateDocument: DuplicateDocument = (document, changes = undefined) => {
		const newDoc: DocumentPreset = { ...document, ...changes };

		delete newDoc.cid;
		delete newDoc.dbId;

		const { cid } = self.initDocument(newDoc);

		self.pushDocument(cid);

		return cid;
	};

	const getFileUri: (key: string) => Promise<string> = async key => {
		return await dataService.getFileUri(key);
		// const fileInfo: { expires: number; uri: string } = getLocal(key);
		// if (fileInfo && fileInfo.expires > Date.now()) {
		// 	return fileInfo.uri;
		// }
		// const newFileInfo = {
		// 	uri: await dataService.getFileUri(key),
		// 	expires: Date.now() + 3600000,
		// };

		// setLocal(key, newFileInfo);
		// return newFileInfo.uri;
	};

	const uploadFile: UploadFileMethod = async file => {
		return await dataService.uploadFile(file);
	};

	const getUriMeta: UriMetaProviderMethod = async uri => {
		try {
			return await dataService.getUriMeta(uri);
		} catch (e) {
			console.log(e);
			return {
				mediaType: 'link',
				uri,
			};
		}
	};

	return {
		loadUserDocuments,
		setUserDocuments,
		settleDocument,
		setDocument,
		initDocument,
		saveDocument,
		pushDocument,
		deleteDocument,
		saveAsTemplate,
		createFromTemplate,
		duplicateDocument,
		selectDocument,
		setAreaKeys,
		clearAreaKeys,
		smartKeysHandle,
		getUriMeta,
		uploadFile,
		getFileUri,
		handleErrors,
	};
};
