import { defaults } from './defaults';

import { default as light } from './light';

import { default as dark } from './dark';
import { fontsConfig } from './fontsConfig';

export { fontsConfig } from './fontsConfig';
export default {
	defaults: { ...defaults(fontsConfig) },
	modifiers: {
		dark: { ...dark },
		light: { ...light },
	},
};
