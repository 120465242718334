import { TypographySettings } from 'types/typography.types';
import { TypographyTheme, TypographyElement, FontThemeOverride } from 'types/theme.types';
import { Size } from 'types/base.types';
import {
	buildVariables,
	buildMaxWidthVariableName,
	buildSpaceVariableName,
	buildThemeOverrides,
	buildScaleVariables,
} from './variables';
import { resetBodyStyle, resetStyle } from './reset';
import { overrideElementStyles } from './override';

export const generateVariables = (
	typography: TypographySettings,
	theme: TypographyTheme
): Array<string> => {
	return [
		...buildVariables('body', 'default', typography.body),
		...Object.keys(theme.overrides === undefined ? {} : theme.overrides)
			.map(key =>
				buildThemeOverrides(
					key as TypographyElement,
					(theme.overrides === undefined ? {} : theme.overrides)[
						key as TypographyElement
					] as Partial<FontThemeOverride>
				)
			)
			.flat(),
		...Object.keys(typography.headings)
			.map(size => buildVariables('heading', size as Size, typography.headings[size as Size]))
			.flat(),
		...Object.keys(typography.space).map(
			size => `${buildSpaceVariableName(size as Size)}: ${typography.space[size as Size]}rem;`
		),
		...Object.keys(typography.width.headings).map(
			size =>
				`${buildMaxWidthVariableName(size as Size)}: ${
					typography.width.headings[size as Size].maxWidth
				};`
		),
		...buildScaleVariables(typography.scale).map(scale => `${scale};`),
		`--space-size-default: ${typography.space.L}rem;`,
		`--max-width-size-default: ${typography.width.body.maxWidth};`,
	];
};

export const generateElementClasses = (
	typography: TypographySettings,
	theme: TypographyTheme
): Array<string> => {
	const useMaxWidth = theme.useMaxWidth === true;
	return [
		resetBodyStyle(theme.combination.body.font),
		resetStyle('p', 'default', theme.combination.body.font, 'body', useMaxWidth),
		...Object.keys(typography.headings).map((size, index) =>
			resetStyle(
				`h${6 - index}` as Exclude<TypographyElement, 'body'>,
				size as Size,
				typography.headingsFont,
				'heading',
				useMaxWidth
			)
		),
		...Object.keys(theme.overrides === undefined ? {} : theme.overrides).map(
			key =>
				`${key} {
					${overrideElementStyles(
						key as TypographyElement,
						(theme.overrides === undefined ? {} : theme.overrides)[
							key as TypographyElement
						] as Partial<FontThemeOverride>
					).join('\n')}
				}`
		),
	];
};

export const generateRootClass = (
	typography: TypographySettings,
	theme: TypographyTheme
): string => {
	return `:root {
    ${generateVariables(typography, theme).join('\n')}
  }`;
};
