import startOfDay from 'date-fns/startOfDay';
import { getEnv, flow } from 'mobx-state-tree';
import { UserBoard, BoardType, UserProfileMeta, UserProfile } from '@smarttime/core';
import { storeLogger } from '../../logger';
import { setLocale, getLocale } from '../../resources';

import {
	RootActionsList,
	LoadUserData,
	SetUser,
	SelectDay,
	SetUserBoard,
	SelectUserBoard,
	UpdateUserData,
	CreateUserBoard,
} from '../store.types';

import { BaseDataService, UserBoardApi, UserProfileApi } from '@smarttime/aws-server';

const logger = storeLogger.extend('root action');
const { trace } = logger;

export const rootActionsUserData: RootActionsList = self => {
	const dataService = getEnv(self).data as BaseDataService;
	// const documentService = dataService.apis.documents as DocumentApi;
	const profileService = dataService.apis.profile as UserProfileApi;
	const boardService = dataService.apis.boards as UserBoardApi;
	const config = getEnv(self).config || {
		baseTitle: 'smarttime based store',
	};

	const getRandomImage = flow(function* () {
		return yield dataService.getRandomImage();
	});
	type InitUserProfile = (defaultProfile: UserProfileMeta) => Promise<UserProfileMeta>;
	const initDefaultUserProfile: InitUserProfile = flow(function* (preset = {}) {
		const newProfile: UserProfile = {
			...(config.defaultProfile || {}),
			...preset,
		};
		yield profileService.create(newProfile);
		trace('YIELD result', {
			newProfile,
		});
		return newProfile;
	});

	type InitUserBoard = () => Promise<void>;
	const initDefaultBoard: InitUserBoard = flow(function* () {
		const newBoard = yield self.createUserBoard({
			id: self.user.defaultBoard,
			title: 'Default Board',
			type: BoardType.PrivateBoard,
		});
		self.activeBoard = newBoard.id;
	});

	const loadUserData: LoadUserData = flow(function* () {
		let profile = yield profileService.get(profileService.getId());
		if (!profile) {
			profile = yield self.initDefaultUserProfile();
		}
		// trace('PROFILE', { profile });
		self.user.update(profile);
		if (boardService) {
			const boards = yield boardService.getList({});
			if (boards.length) {
				// trace('Boards!', { boards });

				boards.forEach((board: UserBoard) => {
					self.boards.set(board.id, board);
				});
			} else {
				// trace('no boards!');
				yield self.initDefaultBoard();
			}
		}
	});

	const setUser: SetUser = user => {
		if (user === null) {
			self.user = null;
		} else {
			self.user = {
				id: user.id,
				email: user.id,
				name: user.name,
				photo: user.photo,
			};
		}
		trace('SET USER', {
			user: self.user && self.user.toJSON(),
		});
		return self.user;
	};

	const selectDay: SelectDay = date => {
		self.selectedDay = startOfDay(date);
	};

	const selectUserBoard: SelectUserBoard = boardId => {
		const activeBoard = self.boards.has(boardId) ? boardId : self.user.defaultBoard;
		self.activeBoard = activeBoard;
		window.document.title = `${self.activeBoard.title} :: ${config.baseTitle}`;

		return self.activeBoard;
	};

	const cleanUp: () => void = () => {
		self.selectDocument(null);
		self.areas = {};
		self.documents = {};
		self.projects = {};
	};

	const updateUserProfile: UpdateUserData = flow(function* (changes) {
		self.user.update(changes);
		yield profileService.update(self.user.toJSON());
		if (self.user.language !== getLocale()) {
			setLocale(self.user.language);
			window.location.reload();
		}
	});
	const createUserBoard: CreateUserBoard = async board => {
		return self.setUserBoard(await boardService.create(board));
	};
	const setUserBoard: SetUserBoard = board => {
		if (board) {
			self.boards.set(board.id, board);
			return self.boards.get(board.id);
		}
		return null;
	};

	return {
		loadUserData,
		getRandomImage,
		setUser,
		selectDay,
		cleanUp,
		updateUserProfile,
		createUserBoard,
		setUserBoard,
		initDefaultBoard,
		selectUserBoard,
		initDefaultUserProfile,
	};
};
