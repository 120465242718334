import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import getWeek from 'date-fns/getWeek';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isToday from 'date-fns/isToday';
import startOfDay from 'date-fns/startOfDay';
import getDay from 'date-fns/getDay';

import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import uk from 'date-fns/locale/uk';
import { Locale } from 'date-fns';
import { LocaleUtils } from 'react-day-picker';
import { getLocale } from '../resources';
import { DayType, MonthType, YearType, DateMap, dateKey } from '@smarttime/core';
import calendarResources from '../resources/calendar';

export type Locales = { en: Locale; ru: Locale; uk: Locale };
export type AvailableLocales = keyof Locales;
export const firstDayOfWeek: Record<AvailableLocales, 0 | 1> = {
	en: 0,
	ru: 1,
	uk: 1,
};
export const locales: Locales = { en, ru, uk };

export const format = (date: Date | number, dateFormat: string): string => {
	const currentLocale = getLocale() as AvailableLocales;
	return dateFnsFormat(date, dateFormat, {
		locale: locales[currentLocale],
		weekStartsOn: firstDayOfWeek[currentLocale],
	});
};

const formatDate: typeof LocaleUtils.formatDate = (date, dateFormat = '', locale = 'en') => {
	return dateFnsFormat(date, dateFormat as string, {
		locale: locales[locale as AvailableLocales],
		weekStartsOn: firstDayOfWeek[locale as AvailableLocales],
	});
};
const formatDay: typeof LocaleUtils.formatDay = (day, locale = 'en') => {
	return formatDate(day, 'EEEE dd MMMM yyyy', locale);
};

const formatMonthTitle: typeof LocaleUtils.formatMonthTitle = (month, locale = 'en') => {
	return formatDate(month, 'LLLL yyyy', locale);
};

const formatWeekdayShort: typeof LocaleUtils.formatWeekdayShort = (weekday, locale) => {
	return calendarResources[locale as AvailableLocales].weekDaysShort[weekday];
};

const formatWeekdayLong: typeof LocaleUtils.formatWeekdayLong = (weekday, locale) => {
	return dateFnsFormat(weekday, 'EEEE', {
		locale: locales[locale as AvailableLocales],
		weekStartsOn: firstDayOfWeek[locale as AvailableLocales],
	});
};

const getMonths: typeof LocaleUtils.getMonths = locale => {
	return calendarResources[locale as AvailableLocales].months as [
		string,
		string,
		string,
		string,
		string,
		string,
		string,
		string,
		string,
		string,
		string,
		string
	];
};

const getFirstDayOfWeek: typeof LocaleUtils.getFirstDayOfWeek = locale => {
	return firstDayOfWeek[locale as AvailableLocales];
};
const parseDate: typeof LocaleUtils.parseDate = (str, format = '', locale = 'en') => {
	return dateFnsParse(str, format, 0, {
		locale: locales[locale as AvailableLocales],
		weekStartsOn: firstDayOfWeek[locale as AvailableLocales],
	});
};

export const dayPickerLocaleUtils: LocaleUtils = {
	formatDay,
	formatMonthTitle,
	formatWeekdayShort,
	formatWeekdayLong,
	getFirstDayOfWeek,
	parseDate,
	formatDate,
	getMonths,
};

export const mapDay: DateMap<DayType> = (dayDate: Date, today?: Date) => {
	const todayDate = today || startOfDay(new Date());
	const date = startOfDay(dayDate);
	const dayConfig: DayType = {
		type: 'DAY',
		id: dateKey(date),
		date,
		week: getWeek(date),
		month: getMonth(date),
		monthTitle: format(date, 'MMMM'),
		monthTitleShort: format(date, 'MMM'),
		year: getYear(date),
		day: format(date, 'dd'),
		weekDay: getDay(date),
		weekDayTitle: format(date, 'EEEE'),
		weekDayTitleShort: format(date, 'eee'),
		isToday: isToday(date),
		isPast: isBefore(date, todayDate),
		isFuture: isAfter(date, todayDate),
		isWeekStart: false,
		isWeekLastDay: false,
		isMonday: false,
		isWeekEnd: false,
		isSunday: false,
		isSaturday: false,
	};

	// Todo:  Start of week can be sunday: handle as a configuration
	dayConfig.isWeekStart = dayConfig.isMonday = dayConfig.weekDay === 1;
	dayConfig.isWeekLastDay = dayConfig.isWeekEnd = dayConfig.isSunday = dayConfig.weekDay === 0;
	dayConfig.isWeekEnd = dayConfig.isSaturday = dayConfig.weekDay === 6;

	return dayConfig;
};

export const mapMonth: DateMap<MonthType> = date => {
	return {
		type: 'MONTH',
		id: `M${format(date, 'YYYYMM')}`,
		date,
		month: getMonth(date),
		monthTitle: format(date, 'MMMM'),
		year: getYear(date),
	};
};

export const mapYear: DateMap<YearType> = date => {
	return {
		type: 'YEAR',
		id: `Y${format(date, 'YYYY')}`,
		date,
		year: getYear(date),
	};
};
