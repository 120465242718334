import React from 'react';
import { css } from '@linaria/core';
import { Spinner, Column } from '@smarttime/core-ui';

import { logger } from '@logs';

const { trace } = logger;
const appLoadingStyles = css`
	background: var(--fade-color);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10;
`;
export default () => {
	trace('AppLoading');
	return (
		<Column className={appLoadingStyles} alignItems="center" justifyContent="center">
			<Spinner margin={50} size={50} delay={1} />
		</Column>
	);
};
