import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import addDays from 'date-fns/addDays';

const DaysGridConfig = {
	defaultDaysBeforeNow: 1000,
	defaultDaysAfterNow: 1000,
	navigateToGridOffsetUp: 5, // plus N and minus N days from specified date (navigateTo)
	navigateToGridOffsetDown: 10, // plus N and minus N days from specified date (navigateTo)
	defaultDaysStep: 10, // the number of days to append/preppend when scroll timeline by default
	maximumDaysInGrid: 30,
};

export type DaysGridMutation = (grid: DaysGrid, step: number) => DaysGrid;
export type DaysGridFactory = (from: Date, to?: Date) => DaysGrid;

export class DaysGrid extends Array<Date> {
	get from(): Date {
		return this[0];
	}

	get to(): Date {
		return this[this.length - 1];
	}

	pushDay(day: Date): void {
		this.push(day);
	}

	get dayBefore(): Date {
		return addDays(this.from, -1);
	}
	get dayAfter(): Date {
		return addDays(this.to, 1);
	}

	nDaysBefore(step: number): Date {
		return addDays(this.from, -step);
	}
	nDaysAfter(step: number): Date {
		return addDays(this.to, step);
	}
}

export const getDaysGrid: DaysGridFactory = (fromDay, toDay) => {
	const from = startOfDay(fromDay || new Date());
	const to = toDay ? startOfDay(toDay) : null;

	const grid = new DaysGrid();
	const daysOffsetUp = DaysGridConfig.navigateToGridOffsetUp;
	const daysOffsetDown = DaysGridConfig.navigateToGridOffsetDown;
	const startDay = to ? startOfDay(from) : addDays(from, -daysOffsetUp);
	const endDay = to ? startOfDay(to) : addDays(from, daysOffsetDown);

	let date = addDays(startDay, -1);

	while (isBefore(date, endDay)) {
		date = addDays(date, 1);
		grid.pushDay(date);
	}

	return grid;
};

export const appendDays: DaysGridMutation = (grid, step = DaysGridConfig.defaultDaysStep) => {
	const from = grid.dayAfter;
	const to = addDays(from, step - 1);

	return new DaysGrid(...grid, ...getDaysGrid(from, to));
};

export const moveDown: DaysGridMutation = (grid, step = DaysGridConfig.defaultDaysStep) => {
	const newGrid = appendDays(grid, step);
	newGrid.splice(0, step);
	return newGrid;
};

export const prependDays: DaysGridMutation = (grid, step = DaysGridConfig.defaultDaysStep) => {
	const from = grid.nDaysBefore(step);
	const to = grid.dayBefore;

	return new DaysGrid(...getDaysGrid(from, to), ...grid);
};
export const moveUp: DaysGridMutation = (grid, step = DaysGridConfig.defaultDaysStep) => {
	const newGrid = prependDays(grid, step);
	newGrid.splice(newGrid.length - step, step);
	return newGrid;
};
