import { TypographyTheme } from '../../types/theme.types';

export const MonospaceDefaultTheme: TypographyTheme = {
	title: 'Monospace Default',
	combination: {
		headings: {
			font: 'Noto Serif',
			weight: [400, 700],
		},
		body: {
			font: 'Anonymous Pro',
			weight: [400, '400i', 700, '700i'],
		},
		subsets: ['cyrillic'],
	},
	style: 'Monospace',
};
