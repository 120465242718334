import React, { useEffect, useState } from 'react';
import { buildFontURI } from '../../utils';
import { FontPack } from 'types/font.types';

const createLink = (props: FontPack) => {
	const link = document.createElement('link');
	link.rel = 'stylesheet';
	link.href = buildFontURI(props);
	return link;
};

export const FontLoader: React.FC<{ fontPack: FontPack }> = ({ fontPack }) => {
	const [link, setLink] = useState(createLink(fontPack));

	useEffect(() => {
		document.head.appendChild(link);
		return () => {
			document.head.removeChild(link);
		};
	}, [link]);

	useEffect(() => {
		setLink(createLink(fontPack));
	}, [fontPack]);

	return <></>;
};
