import React, { useEffect, useCallback } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { styled } from '@linaria/react';
import isHotkey from 'is-hotkey';
import { ColorSchemeSwitcher } from '@src/themes';
import {
	boardRoute,
	boardPageRoute,
	route,
	BoardPages,
	boardPageRouteWithId,
} from '@src/pages/routes';
import Login from './Login';


const AppLayout = styled.div`
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	position: relative;
	overflow-x: auto;
	background: black;
`;

const App = () => {

	const onKeyDown = useCallback(event => {
		if (isHotkey('mod+s')(event)) {
			event.preventDefault();
		}
	}, []);

	useEffect(() => {
		window.addEventListener('keydown', onKeyDown, true);

		return () => {
			window.removeEventListener('keydown', onKeyDown, true);
		};
	}, [onKeyDown]);

	return (
		<AppLayout data-desktop="true">
			<ColorSchemeSwitcher schemeName={'default'} />
			<Login />
		</AppLayout>
	);
};
export default React.memo(App);
