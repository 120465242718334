import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLocalState, setLocalState } from '../utils';

const getLocal = getLocalState('settings');
const setLocal = setLocalState('settings');

export const getLocale = () => {
	return getLocal('language', 'en', false);
	// window.location.reload();
};

export const setLocale = language => {
	setLocal('language', language, false);
	// window.location.reload();
};
export const initI18n = resources => {
	const language = getLocal('language', 'en', false);
	console.log();
	i18n.use(initReactI18next).init({
		resources,
		lng: language,
		fallbackLng: language,
	});
	return i18n;
};
