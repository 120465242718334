import React from 'react';
import { css } from '@linaria/core';
import { Img } from 'react-progressive-loader';

const background = css`
	z-index: 1;
	height: 100vh !important;
	width: 100vw !important;
	position: fixed !important;
	top: 0;
	left: 0;

	img {
		min-width: 100vw;
		min-height: 100vh;
		height: auto !important;
		width: auto !important;
	}
`;
interface Props {
	src: string;
	placeholderSrc: string;
	bgColor?: string;
	rand?: number;
}

export const Background: React.FC<Props> = ({ rand, src, placeholderSrc, bgColor = '#000000' }) => {
	const srcUrl = rand ? (src.indexOf('?') === -1 ? `${src}?${rand}` : `${src}&${rand}`) : src;
	return (
		<Img
			className={background}
			src={`${srcUrl}`}
			placeholderSrc={placeholderSrc}
			bgColor={bgColor}
			loadOnScreen
		/>
	);
};
export { Img };
