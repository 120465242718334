import { TypographyTheme } from '../../types/theme.types';

export const PalanquinTheme: TypographyTheme = {
	title: 'Palanquin',
	combination: {
		headings: {
			font: 'Halant',
			weight: [600],
		},
		body: {
			font: 'Palanquin',
			weight: [100, 300, 400, '400i', 600, '600i'],
		},
		subsets: ['cyrillic'],
	},
	style: 'Serif',
};
