import { TypographyTheme } from '../../types/theme.types';

export const CharterInterTheme: TypographyTheme = {
	title: 'Charter Inter',
	combination: {
		headings: {
			font: 'Charter',
			weight: [200, 300, 400, 500, 600, 700],
		},
		body: {
			font: 'Inter',
			weight: [100, 200, 300, 400, 500, 600, 700],
		},
		subsets: ['cyrillic'],
	},
	style: 'Serif',
};
