export const hsla = (name, values) => {
	const [h, s, l, a] = values;
	return `hsla(${h}, ${s}%, ${l}%, ${a || 1})`;
};
export const color = (name, main, lighter, lightest, darker, darkest) => {
	// console.log({ name, main, lighter, lightest });
	const res = {
		[`--${name}-color`]: hsla(name, main),
	};
	if (lighter) {
		res[`--${name}-color-lighter`] = hsla(name, lighter, 'lighter');
	}
	if (lightest) {
		res[`--${name}-color-lightest`] = hsla(name, lightest, 'lightest');
	}
	if (darker) {
		res[`--${name}-color-darker`] = hsla(name, darker, 'darker');
	}
	if (darkest) {
		res[`--${name}-color-darkest`] = hsla(name, darkest, 'darkest');
	}
	return res;
};

const addLightness = (color, inc) => [color[0], color[1], color[2] + inc];

export const getColorsSet = (mainColor, step = 15, darkerStep) => [
	mainColor,
	addLightness(mainColor, step),
	addLightness(mainColor, step * 2),
	addLightness(mainColor, -(darkerStep || step / 2)),
];

export const themeColorMap = colorVars => {
	const themeObj = {};
	Object.keys(colorVars).forEach(name => {
		Object.assign(themeObj, color(name, ...colorVars[name]));
	});
	return themeObj;
};
