import { TypographyTheme } from '../../types/theme.types';

export const AlegreyaTheme: TypographyTheme = {
	title: 'Alegreya',
	combination: {
		headings: {
			font: 'Alegreya Sans',
			weight: [500],
		},
		body: {
			font: 'Alegreya',
			weight: [400, '400i', 700, '700i'],
		},
		subsets: ['cyrillic'],
	},
	style: 'Serif',
};
