import { FontCategory, FontPair, AvailableFonts, FontDetailInfo, FontPack } from 'types/font.types';

const TypsetCombinations: Record<FontCategory, FontPair> = {
	'Sans-Serif': {
		headings: {
			font: 'Merriweather',
			weight: [400, 700],
		},
		body: {
			font: 'Open Sans',
			weight: [400, '400i', 600, '600i', 700],
		},
	},
	Serif: {
		headings: {
			font: 'Oswald',
			weight: [400, 500],
		},
		body: {
			font: 'PT Serif',
			weight: [400, '400i', 700, '700i'],
		},
	},
	Monospace: {
		headings: {
			font: 'Noto Serif',
			weight: [400, 700],
		},
		body: {
			font: 'Anonymous Pro',
			weight: [400, '400i', 700, '700i'],
		},
	},
	Display: {
		headings: {
			font: 'Rubik',
			weight: [400, 500, 700],
		},
		body: {
			font: 'Rubik',
			weight: [400, 500, 600, 700],
		},
	},
	Handwriting: {
		headings: {
			font: 'Playfair Display',
			weight: [400, 500, 700],
		},
		body: {
			font: 'Comfortaa',
			weight: [400, 500, 600, 700],
		},
	},
};

export const FontsDetail: Record<AvailableFonts, FontDetailInfo> = {
	ABeeZee: {
		name: 'ABeeZee',
		contrast: 0.14,
		weight: 0.08,
		xHeight: 0.76,
		width: 0.45,
		category: 'Sans-Serif',
	},
	Abel: {
		name: 'Abel',
		contrast: 0.06,
		weight: 0.05,
		xHeight: 0.71,
		width: 0.33,
		category: 'Sans-Serif',
	},
	'Abhaya Libre': {
		name: 'Abhaya Libre',
		contrast: 0.71,
		weight: 0.04,
		xHeight: 0.71,
		width: 0.43,
		category: 'Serif',
	},
	'Abril Fatface': {
		name: 'Abril Fatface',
		contrast: 0.91,
		weight: 0.09,
		xHeight: 0.68,
		width: 0.53,
		category: 'Display',
	},
	Aclonica: {
		name: 'Aclonica',
		contrast: 0.6,
		weight: 0.11,
		xHeight: 0.8,
		width: 0.56,
		category: 'Sans-Serif',
	},
	Acme: {
		name: 'Acme',
		contrast: 0.3,
		weight: 0.08,
		xHeight: 0.73,
		width: 0.41,
		category: 'Sans-Serif',
	},
	Actor: {
		name: 'Actor',
		contrast: 0.21,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Adamina: {
		name: 'Adamina',
		contrast: 0.59,
		weight: 0.07,
		xHeight: 0.67,
		width: 0.52,
		category: 'Serif',
	},
	'Advent Pro': {
		name: 'Advent Pro',
		contrast: 0.11,
		weight: 0.05,
		xHeight: 0.72,
		width: 0.34,
		category: 'Sans-Serif',
	},
	'Aguafina Script': {
		name: 'Aguafina Script',
		contrast: 0.74,
		weight: 0.05,
		xHeight: 0.58,
		width: 0.54,
		category: 'Handwriting',
	},
	Akronim: {
		name: 'Akronim',
		contrast: 0.47,
		weight: 0.05,
		xHeight: 0.64,
		width: 0.39,
		category: 'Display',
	},
	Aladin: {
		name: 'Aladin',
		contrast: 0,
		weight: 0.06,
		xHeight: 0.64,
		width: 0.36,
		category: 'Handwriting',
	},
	Aldrich: {
		name: 'Aldrich',
		contrast: 0.01,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.45,
		category: 'Sans-Serif',
	},
	Alef: {
		name: 'Alef',
		contrast: 0.19,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.42,
		category: 'Sans-Serif',
	},
	Alegreya: {
		name: 'Alegreya',
		contrast: 0.52,
		weight: 0.05,
		xHeight: 0.71,
		width: 0.43,
		category: 'Serif',
	},
	'Alegreya SC': {
		name: 'Alegreya SC',
		contrast: 0.5,
		weight: 0.06,
		xHeight: 0.78,
		width: 0.46,
		category: 'Serif',
	},
	'Alegreya Sans': {
		name: 'Alegreya Sans',
		contrast: 0.27,
		weight: 0.05,
		xHeight: 0.72,
		width: 0.37,
		category: 'Sans-Serif',
	},
	'Alegreya Sans SC': {
		name: 'Alegreya Sans SC',
		contrast: 0.2,
		weight: 0.06,
		xHeight: 0.78,
		width: 0.38,
		category: 'Sans-Serif',
	},
	'Alex Brush': {
		name: 'Alex Brush',
		contrast: 0.62,
		weight: 0.03,
		xHeight: 0.57,
		width: 0.5,
		category: 'Handwriting',
	},
	'Alfa Slab One': {
		name: 'Alfa Slab One',
		contrast: 0.27,
		weight: 0.15,
		xHeight: 0.71,
		width: 0.6,
		category: 'Display',
	},
	Alice: {
		name: 'Alice',
		contrast: 0.64,
		weight: 0.06,
		xHeight: 0.73,
		width: 0.48,
		category: 'Serif',
	},
	Alike: {
		name: 'Alike',
		contrast: 0.6,
		weight: 0.07,
		xHeight: 0.72,
		width: 0.49,
		category: 'Serif',
	},
	'Alike Angular': {
		name: 'Alike Angular',
		contrast: 0.61,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.49,
		category: 'Serif',
	},
	Allan: {
		name: 'Allan',
		contrast: 0.18,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.39,
		category: 'Display',
	},
	Allerta: {
		name: 'Allerta',
		contrast: 0.28,
		weight: 0.09,
		xHeight: 0.77,
		width: 0.44,
		category: 'Sans-Serif',
	},
	'Allerta Stencil': {
		name: 'Allerta Stencil',
		contrast: 0.88,
		weight: 0.09,
		xHeight: 0.77,
		width: 0.44,
		category: 'Sans-Serif',
	},
	Allura: {
		name: 'Allura',
		contrast: 0,
		weight: 0.03,
		xHeight: 0.5,
		width: 0.47,
		category: 'Handwriting',
	},
	Almendra: {
		name: 'Almendra',
		contrast: 0.73,
		weight: 0.05,
		xHeight: 0.74,
		width: 0.42,
		category: 'Serif',
	},
	'Almendra Display': {
		name: 'Almendra Display',
		contrast: 0.23,
		weight: 0.02,
		xHeight: 0.76,
		width: 0.42,
		category: 'Display',
	},
	'Almendra SC': {
		name: 'Almendra SC',
		contrast: 0.72,
		weight: 0.06,
		xHeight: 0.62,
		width: 0.49,
		category: 'Serif',
	},
	Amarante: {
		name: 'Amarante',
		contrast: 0.6,
		weight: 0.07,
		xHeight: 0.66,
		width: 0.42,
		category: 'Display',
	},
	Amaranth: {
		name: 'Amaranth',
		contrast: 0.32,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.43,
		category: 'Sans-Serif',
	},
	'Amatic SC': {
		name: 'Amatic SC',
		contrast: 0.44,
		weight: 0.04,
		xHeight: 0.87,
		width: 0.22,
		category: 'Handwriting',
	},
	Amethysta: {
		name: 'Amethysta',
		contrast: 0.55,
		weight: 0.06,
		xHeight: 0.69,
		width: 0.5,
		category: 'Serif',
	},
	Amiko: {
		name: 'Amiko',
		contrast: 0.21,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.43,
		category: 'Sans-Serif',
	},
	Amiri: {
		name: 'Amiri',
		contrast: 0.63,
		weight: 0.05,
		xHeight: 0.65,
		width: 0.41,
		category: 'Serif',
	},
	Amita: {
		name: 'Amita',
		contrast: 0,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.45,
		category: 'Handwriting',
	},
	Anaheim: {
		name: 'Anaheim',
		contrast: 0.18,
		weight: 0.05,
		xHeight: 0.77,
		width: 0.36,
		category: 'Sans-Serif',
	},
	Andada: {
		name: 'Andada',
		contrast: 0.39,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.48,
		category: 'Serif',
	},
	Andika: {
		name: 'Andika',
		contrast: 0.14,
		weight: 0.07,
		xHeight: 0.7,
		width: 0.44,
		category: 'Sans-Serif',
	},
	'Annie Use Your Telescope': {
		name: 'Annie Use Your Telescope',
		contrast: 0.27,
		weight: 0.03,
		xHeight: 0.65,
		width: 0.34,
		category: 'Handwriting',
	},
	'Anonymous Pro': {
		name: 'Anonymous Pro',
		contrast: 0.16,
		weight: 0.05,
		xHeight: 0.71,
		width: 0.43,
		category: 'Monospace',
	},
	Antic: {
		name: 'Antic',
		contrast: 0.16,
		weight: 0.06,
		xHeight: 0.72,
		width: 0.38,
		category: 'Sans-Serif',
	},
	'Antic Didone': {
		name: 'Antic Didone',
		contrast: 0.72,
		weight: 0.05,
		xHeight: 0.71,
		width: 0.45,
		category: 'Serif',
	},
	'Antic Slab': {
		name: 'Antic Slab',
		contrast: 0.16,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.45,
		category: 'Serif',
	},
	Anton: {
		name: 'Anton',
		contrast: 0.24,
		weight: 0.14,
		xHeight: 0.85,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Arapey: {
		name: 'Arapey',
		contrast: 0.71,
		weight: 0.04,
		xHeight: 0.7,
		width: 0.4,
		category: 'Serif',
	},
	Arbutus: {
		name: 'Arbutus',
		contrast: 0.57,
		weight: 0.13,
		xHeight: 0.74,
		width: 0.65,
		category: 'Display',
	},
	'Arbutus Slab': {
		name: 'Arbutus Slab',
		contrast: 0.48,
		weight: 0.07,
		xHeight: 0.68,
		width: 0.51,
		category: 'Serif',
	},
	'Architects Daughter': {
		name: 'Architects Daughter',
		contrast: 0.34,
		weight: 0.05,
		xHeight: 0.66,
		width: 0.48,
		category: 'Handwriting',
	},
	Archivo: {
		name: 'Archivo',
		contrast: 0.2,
		weight: 0.07,
		xHeight: 0.77,
		width: 0.42,
		category: 'Sans-Serif',
	},
	'Archivo Black': {
		name: 'Archivo Black',
		contrast: 0.32,
		weight: 0.13,
		xHeight: 0.77,
		width: 0.55,
		category: 'Sans-Serif',
	},
	'Archivo Narrow': {
		name: 'Archivo Narrow',
		contrast: 0.15,
		weight: 0.06,
		xHeight: 0.77,
		width: 0.34,
		category: 'Sans-Serif',
	},
	'Aref Ruqaa': {
		name: 'Aref Ruqaa',
		contrast: 0.58,
		weight: 0.06,
		xHeight: 0.67,
		width: 0.5,
		category: 'Serif',
	},
	'Arima Madurai': {
		name: 'Arima Madurai',
		contrast: 0.58,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.43,
		category: 'Display',
	},
	Arimo: {
		name: 'Arimo',
		contrast: 0.3,
		weight: 0.07,
		xHeight: 0.77,
		width: 0.42,
		category: 'Sans-Serif',
	},
	Arizonia: {
		name: 'Arizonia',
		contrast: 0.78,
		weight: 0.03,
		xHeight: 0.52,
		width: 0.5,
		category: 'Handwriting',
	},
	Armata: {
		name: 'Armata',
		contrast: 0.2,
		weight: 0.08,
		xHeight: 0.78,
		width: 0.44,
		category: 'Sans-Serif',
	},
	Arsenal: {
		name: 'Arsenal',
		contrast: 0.43,
		weight: 0.06,
		xHeight: 0.77,
		width: 0.36,
		category: 'Sans-Serif',
	},
	Artifika: {
		name: 'Artifika',
		contrast: 0.57,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.56,
		category: 'Serif',
	},
	Arvo: {
		name: 'Arvo',
		contrast: 0.19,
		weight: 0.07,
		xHeight: 0.68,
		width: 0.49,
		category: 'Serif',
	},
	Arya: {
		name: 'Arya',
		contrast: 0.51,
		weight: 0.06,
		xHeight: 0.78,
		width: 0.39,
		category: 'Sans-Serif',
	},
	Asap: {
		name: 'Asap',
		contrast: 0.18,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.4,
		category: 'Sans-Serif',
	},
	'Asap Condensed': {
		name: 'Asap Condensed',
		contrast: 0.19,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.34,
		category: 'Sans-Serif',
	},
	Asar: {
		name: 'Asar',
		contrast: 0.58,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.44,
		category: 'Serif',
	},
	Asset: {
		name: 'Asset',
		contrast: 0.82,
		weight: 0.22,
		xHeight: 0.81,
		width: 1.28,
		category: 'Display',
	},
	Assistant: {
		name: 'Assistant',
		contrast: 0.2,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.38,
		category: 'Sans-Serif',
	},
	Astloch: {
		name: 'Astloch',
		contrast: 0.13,
		weight: 0.03,
		xHeight: 0.78,
		width: 0.32,
		category: 'Display',
	},
	Asul: {
		name: 'Asul',
		contrast: 0.55,
		weight: 0.07,
		xHeight: 0.69,
		width: 0.45,
		category: 'Sans-Serif',
	},
	Athiti: {
		name: 'Athiti',
		contrast: 0.34,
		weight: 0.05,
		xHeight: 0.77,
		width: 0.35,
		category: 'Sans-Serif',
	},
	Atma: {
		name: 'Atma',
		contrast: 0.35,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.36,
		category: 'Display',
	},
	'Atomic Age': {
		name: 'Atomic Age',
		contrast: 0.38,
		weight: 0.09,
		xHeight: 0.69,
		width: 0.5,
		category: 'Display',
	},
	Aubrey: {
		name: 'Aubrey',
		contrast: 0.39,
		weight: 0.05,
		xHeight: 0.75,
		width: 0.32,
		category: 'Display',
	},
	Audiowide: {
		name: 'Audiowide',
		contrast: 0.01,
		weight: 0.11,
		xHeight: 0.75,
		width: 0.55,
		category: 'Display',
	},
	'Autour One': {
		name: 'Autour One',
		contrast: 0.08,
		weight: 0.1,
		xHeight: 0.78,
		width: 0.58,
		category: 'Display',
	},
	Average: {
		name: 'Average',
		contrast: 0.46,
		weight: 0.06,
		xHeight: 0.66,
		width: 0.45,
		category: 'Serif',
	},
	'Average Sans': {
		name: 'Average Sans',
		contrast: 0.26,
		weight: 0.06,
		xHeight: 0.66,
		width: 0.38,
		category: 'Sans-Serif',
	},
	'Averia Gruesa Libre': {
		name: 'Averia Gruesa Libre',
		contrast: 0.4,
		weight: 0.07,
		xHeight: 0.7,
		width: 0.41,
		category: 'Display',
	},
	'Averia Libre': {
		name: 'Averia Libre',
		contrast: 0.42,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.41,
		category: 'Display',
	},
	'Averia Sans Libre': {
		name: 'Averia Sans Libre',
		contrast: 0.32,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.39,
		category: 'Display',
	},
	'Averia Serif Libre': {
		name: 'Averia Serif Libre',
		contrast: 0.59,
		weight: 0.07,
		xHeight: 0.69,
		width: 0.47,
		category: 'Display',
	},
	'Bad Script': {
		name: 'Bad Script',
		contrast: 0.14,
		weight: 0.04,
		xHeight: 0.53,
		width: 0.42,
		category: 'Handwriting',
	},
	Bahiana: {
		name: 'Bahiana',
		contrast: 0.18,
		weight: 0.06,
		xHeight: 1,
		width: 0.23,
		category: 'Display',
	},
	Baloo: {
		name: 'Baloo',
		contrast: 0.24,
		weight: 0.1,
		xHeight: 0.8,
		width: 0.46,
		category: 'Display',
	},
	'Baloo 2': {
		name: 'Baloo 2',
		contrast: 0.24,
		weight: 0.1,
		xHeight: 0.8,
		width: 0.46,
		category: 'Display',
	},
	'Baloo Bhai': {
		name: 'Baloo Bhai',
		contrast: 0.24,
		weight: 0.1,
		xHeight: 0.8,
		width: 0.46,
		category: 'Display',
	},
	'Baloo Bhaijaan': {
		name: 'Baloo Bhaijaan',
		contrast: 0.24,
		weight: 0.1,
		xHeight: 0.8,
		width: 0.46,
		category: 'Display',
	},
	'Baloo Bhaina': {
		name: 'Baloo Bhaina',
		contrast: 0.24,
		weight: 0.1,
		xHeight: 0.8,
		width: 0.46,
		category: 'Display',
	},
	'Baloo Chettan': {
		name: 'Baloo Chettan',
		contrast: 0.24,
		weight: 0.1,
		xHeight: 0.8,
		width: 0.46,
		category: 'Display',
	},
	'Baloo Da': {
		name: 'Baloo Da',
		contrast: 0.24,
		weight: 0.1,
		xHeight: 0.8,
		width: 0.46,
		category: 'Display',
	},
	'Baloo Paaji': {
		name: 'Baloo Paaji',
		contrast: 0.24,
		weight: 0.1,
		xHeight: 0.8,
		width: 0.46,
		category: 'Display',
	},
	'Baloo Tamma': {
		name: 'Baloo Tamma',
		contrast: 0.24,
		weight: 0.1,
		xHeight: 0.8,
		width: 0.46,
		category: 'Display',
	},
	'Baloo Tammudu': {
		name: 'Baloo Tammudu',
		contrast: 0.24,
		weight: 0.1,
		xHeight: 0.8,
		width: 0.46,
		category: 'Display',
	},
	'Baloo Thambi': {
		name: 'Baloo Thambi',
		contrast: 0.24,
		weight: 0.1,
		xHeight: 0.8,
		width: 0.46,
		category: 'Display',
	},
	Balthazar: {
		name: 'Balthazar',
		contrast: 0.39,
		weight: 0.05,
		xHeight: 0.66,
		width: 0.39,
		category: 'Serif',
	},
	Bangers: {
		name: 'Bangers',
		contrast: 0.4,
		weight: 0.13,
		xHeight: 0.99,
		width: 0.49,
		category: 'Display',
	},
	Barrio: {
		name: 'Barrio',
		contrast: 0.36,
		weight: 0.08,
		xHeight: 0.98,
		width: 0.47,
		category: 'Display',
	},
	Basic: {
		name: 'Basic',
		contrast: 0.29,
		weight: 0.08,
		xHeight: 0.75,
		width: 0.41,
		category: 'Sans-Serif',
	},
	Baumans: {
		name: 'Baumans',
		contrast: 0.09,
		weight: 0.07,
		xHeight: 0.69,
		width: 0.4,
		category: 'Display',
	},
	Belgrano: {
		name: 'Belgrano',
		contrast: 0.19,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.52,
		category: 'Serif',
	},
	Bellefair: {
		name: 'Bellefair',
		contrast: 0.55,
		weight: 0.04,
		xHeight: 0.53,
		width: 0.36,
		category: 'Serif',
	},
	Belleza: {
		name: 'Belleza',
		contrast: 0.65,
		weight: 0.05,
		xHeight: 0.68,
		width: 0.38,
		category: 'Sans-Serif',
	},
	BenchNine: {
		name: 'BenchNine',
		contrast: 0.22,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.25,
		category: 'Sans-Serif',
	},
	Bentham: {
		name: 'Bentham',
		contrast: 0.79,
		weight: 0.05,
		xHeight: 0.63,
		width: 0.42,
		category: 'Serif',
	},
	'Berkshire Swash': {
		name: 'Berkshire Swash',
		contrast: 0.67,
		weight: 0.08,
		xHeight: 0.56,
		width: 0.5,
		category: 'Handwriting',
	},
	Bevan: {
		name: 'Bevan',
		contrast: 0.39,
		weight: 0.13,
		xHeight: 0.67,
		width: 0.61,
		category: 'Display',
	},
	'Bigelow Rules': {
		name: 'Bigelow Rules',
		contrast: 0.69,
		weight: 0.03,
		xHeight: 0.74,
		width: 0.26,
		category: 'Display',
	},
	'Bigshot One': {
		name: 'Bigshot One',
		contrast: 0.72,
		weight: 0.08,
		xHeight: 0.73,
		width: 0.48,
		category: 'Display',
	},
	Bilbo: {
		name: 'Bilbo',
		contrast: 0.53,
		weight: 0.03,
		xHeight: 0.58,
		width: 0.36,
		category: 'Handwriting',
	},
	'Bilbo Swash Caps': {
		name: 'Bilbo Swash Caps',
		contrast: 0.53,
		weight: 0.03,
		xHeight: 0.56,
		width: 0.39,
		category: 'Handwriting',
	},
	BioRhyme: {
		name: 'BioRhyme',
		contrast: 0.23,
		weight: 0.07,
		xHeight: 0.69,
		width: 0.57,
		category: 'Serif',
	},
	'BioRhyme Expanded': {
		name: 'BioRhyme Expanded',
		contrast: 0.23,
		weight: 0.1,
		xHeight: 0.69,
		width: 0.95,
		category: 'Serif',
	},
	Biryani: {
		name: 'Biryani',
		contrast: 0.13,
		weight: 0.09,
		xHeight: 0.73,
		width: 0.44,
		category: 'Sans-Serif',
	},
	Bitter: {
		name: 'Bitter',
		contrast: 0.25,
		weight: 0.08,
		xHeight: 0.76,
		width: 0.51,
		category: 'Serif',
	},
	'Black Ops One': {
		name: 'Black Ops One',
		contrast: 0.9,
		weight: 0.13,
		xHeight: 0.8,
		width: 0.55,
		category: 'Display',
	},
	Bonbon: {
		name: 'Bonbon',
		contrast: 0.21,
		weight: 0.06,
		xHeight: 0.77,
		width: 0.54,
		category: 'Handwriting',
	},
	Boogaloo: {
		name: 'Boogaloo',
		contrast: 0.21,
		weight: 0.07,
		xHeight: 0.68,
		width: 0.33,
		category: 'Display',
	},
	'Bowlby One': {
		name: 'Bowlby One',
		contrast: 0.46,
		weight: 0.19,
		xHeight: 0.79,
		width: 0.66,
		category: 'Display',
	},
	'Bowlby One SC': {
		name: 'Bowlby One SC',
		contrast: 0.36,
		weight: 0.19,
		xHeight: 0.91,
		width: 0.63,
		category: 'Display',
	},
	Brawler: {
		name: 'Brawler',
		contrast: 0.46,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.51,
		category: 'Serif',
	},
	'Bree Serif': {
		name: 'Bree Serif',
		contrast: 0.29,
		weight: 0.08,
		xHeight: 0.76,
		width: 0.48,
		category: 'Serif',
	},
	'Bubblegum Sans': {
		name: 'Bubblegum Sans',
		contrast: 0.21,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.37,
		category: 'Display',
	},
	'Bubbler One': {
		name: 'Bubbler One',
		contrast: 0.12,
		weight: 0.04,
		xHeight: 0.73,
		width: 0.34,
		category: 'Sans-Serif',
	},
	Buenard: {
		name: 'Buenard',
		contrast: 0.56,
		weight: 0.05,
		xHeight: 0.64,
		width: 0.44,
		category: 'Serif',
	},
	Bungee: {
		name: 'Bungee',
		contrast: 0.15,
		weight: 0.2,
		xHeight: 1,
		width: 0.6,
		category: 'Display',
	},
	'Bungee Hairline': {
		name: 'Bungee Hairline',
		contrast: 0.18,
		weight: 0.02,
		xHeight: 1,
		width: 0.39,
		category: 'Display',
	},
	'Bungee Inline': {
		name: 'Bungee Inline',
		contrast: 0.23,
		weight: 0.2,
		xHeight: 1,
		width: 0.6,
		category: 'Display',
	},
	'Bungee Outline': {
		name: 'Bungee Outline',
		contrast: 0.24,
		weight: 0.07,
		xHeight: 1,
		width: 0.62,
		category: 'Display',
	},
	'Bungee Shade': {
		name: 'Bungee Shade',
		contrast: 0.94,
		weight: 0.16,
		xHeight: 1,
		width: 0.75,
		category: 'Display',
	},
	Butcherman: {
		name: 'Butcherman',
		contrast: 0.61,
		weight: 0.16,
		xHeight: 1.01,
		width: 0.58,
		category: 'Display',
	},
	'Butterfly Kids': {
		name: 'Butterfly Kids',
		contrast: 0.29,
		weight: 0.01,
		xHeight: 0.42,
		width: 0.32,
		category: 'Handwriting',
	},
	Cabin: {
		name: 'Cabin',
		contrast: 0.13,
		weight: 0.07,
		xHeight: 0.7,
		width: 0.42,
		category: 'Sans-Serif',
	},
	'Cabin Condensed': {
		name: 'Cabin Condensed',
		contrast: 0.14,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.36,
		category: 'Sans-Serif',
	},
	'Cabin Sketch': {
		name: 'Cabin Sketch',
		contrast: 0.53,
		weight: 0.07,
		xHeight: 0.76,
		width: 0.42,
		category: 'Display',
	},
	'Caesar Dressing': {
		name: 'Caesar Dressing',
		contrast: 0.11,
		weight: 0.1,
		xHeight: 1.02,
		width: 0.45,
		category: 'Display',
	},
	Cagliostro: {
		name: 'Cagliostro',
		contrast: 0.3,
		weight: 0.06,
		xHeight: 0.63,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Cairo: {
		name: 'Cairo',
		contrast: 0.16,
		weight: 0.06,
		xHeight: 0.72,
		width: 0.39,
		category: 'Sans-Serif',
	},
	Calligraffitti: {
		name: 'Calligraffitti',
		contrast: 0,
		weight: 0.04,
		xHeight: 0.63,
		width: 0.52,
		category: 'Handwriting',
	},
	Cambay: {
		name: 'Cambay',
		contrast: 0.24,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Cambo: {
		name: 'Cambo',
		contrast: 0.48,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.47,
		category: 'Serif',
	},
	Candal: {
		name: 'Candal',
		contrast: 0.35,
		weight: 0.13,
		xHeight: 0.76,
		width: 0.57,
		category: 'Sans-Serif',
	},
	Cantarell: {
		name: 'Cantarell',
		contrast: 0.19,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.4,
		category: 'Sans-Serif',
	},
	'Cantata One': {
		name: 'Cantata One',
		contrast: 0.7,
		weight: 0.08,
		xHeight: 0.67,
		width: 0.54,
		category: 'Serif',
	},
	'Cantora One': {
		name: 'Cantora One',
		contrast: 0.39,
		weight: 0.08,
		xHeight: 0.71,
		width: 0.43,
		category: 'Sans-Serif',
	},
	Capriola: {
		name: 'Capriola',
		contrast: 0.17,
		weight: 0.1,
		xHeight: 0.74,
		width: 0.48,
		category: 'Sans-Serif',
	},
	Cardo: {
		name: 'Cardo',
		contrast: 0.72,
		weight: 0.05,
		xHeight: 0.64,
		width: 0.46,
		category: 'Serif',
	},
	Carme: {
		name: 'Carme',
		contrast: 0.07,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.41,
		category: 'Sans-Serif',
	},
	'Carrois Gothic': {
		name: 'Carrois Gothic',
		contrast: 0.26,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.38,
		category: 'Sans-Serif',
	},
	'Carrois Gothic SC': {
		name: 'Carrois Gothic SC',
		contrast: 0.23,
		weight: 0.07,
		xHeight: 0.78,
		width: 0.41,
		category: 'Sans-Serif',
	},
	'Carter One': {
		name: 'Carter One',
		contrast: 0.36,
		weight: 0.12,
		xHeight: 0.76,
		width: 0.54,
		category: 'Display',
	},
	Catamaran: {
		name: 'Catamaran',
		contrast: 0.17,
		weight: 0.06,
		xHeight: 0.73,
		width: 0.39,
		category: 'Sans-Serif',
	},
	Caudex: {
		name: 'Caudex',
		contrast: 0.73,
		weight: 0.05,
		xHeight: 0.67,
		width: 0.45,
		category: 'Serif',
	},
	Caveat: {
		name: 'Caveat',
		contrast: 0.34,
		weight: 0.03,
		xHeight: 0.51,
		width: 0.37,
		category: 'Handwriting',
	},
	'Caveat Brush': {
		name: 'Caveat Brush',
		contrast: 0.36,
		weight: 0.05,
		xHeight: 0.62,
		width: 0.34,
		category: 'Handwriting',
	},
	'Cedarville Cursive': {
		name: 'Cedarville Cursive',
		contrast: 0.54,
		weight: 0.04,
		xHeight: 0.49,
		width: 0.55,
		category: 'Handwriting',
	},
	'Ceviche One': {
		name: 'Ceviche One',
		contrast: 0.49,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.42,
		category: 'Display',
	},
	Changa: {
		name: 'Changa',
		contrast: 0.39,
		weight: 0.07,
		xHeight: 0.8,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Changa One': {
		name: 'Changa One',
		contrast: 0.33,
		weight: 0.12,
		xHeight: 0.8,
		width: 0.47,
		category: 'Display',
	},
	Chango: {
		name: 'Chango',
		contrast: 0.49,
		weight: 0.17,
		xHeight: 0.73,
		width: 0.71,
		category: 'Display',
	},
	Charter: {
		name: 'Charter',
		contrast: 0.49,
		weight: 0.17,
		xHeight: 0.73,
		width: 0.71,
		category: 'Display',
	},
	Chathura: {
		name: 'Chathura',
		contrast: 0.11,
		weight: 0.02,
		xHeight: 0.73,
		width: 0.17,
		category: 'Sans-Serif',
	},
	'Chau Philomene One': {
		name: 'Chau Philomene One',
		contrast: 0.34,
		weight: 0.09,
		xHeight: 0.67,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Chela One': {
		name: 'Chela One',
		contrast: 0.51,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.4,
		category: 'Display',
	},
	'Chelsea Market': {
		name: 'Chelsea Market',
		contrast: 0.37,
		weight: 0.1,
		xHeight: 0.8,
		width: 0.51,
		category: 'Display',
	},
	'Cherry Cream Soda': {
		name: 'Cherry Cream Soda',
		contrast: 0.31,
		weight: 0.1,
		xHeight: 0.67,
		width: 0.59,
		category: 'Display',
	},
	'Cherry Swash': {
		name: 'Cherry Swash',
		contrast: 0.12,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.5,
		category: 'Display',
	},
	Chewy: {
		name: 'Chewy',
		contrast: 0.13,
		weight: 0.1,
		xHeight: 0.75,
		width: 0.43,
		category: 'Display',
	},
	Chicle: {
		name: 'Chicle',
		contrast: 0.33,
		weight: 0.08,
		xHeight: 0.68,
		width: 0.38,
		category: 'Display',
	},
	Chivo: {
		name: 'Chivo',
		contrast: 0.21,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.43,
		category: 'Sans-Serif',
	},
	Chonburi: {
		name: 'Chonburi',
		contrast: 0.87,
		weight: 0.11,
		xHeight: 0.69,
		width: 0.6,
		category: 'Display',
	},
	Cinzel: {
		name: 'Cinzel',
		contrast: 0.6,
		weight: 0.07,
		xHeight: 0.86,
		width: 0.58,
		category: 'Serif',
	},
	'Cinzel Decorative': {
		name: 'Cinzel Decorative',
		contrast: 0.62,
		weight: 0.08,
		xHeight: 0.84,
		width: 0.61,
		category: 'Display',
	},
	'Clicker Script': {
		name: 'Clicker Script',
		contrast: 0.14,
		weight: 0.03,
		xHeight: 0.43,
		width: 0.4,
		category: 'Handwriting',
	},
	Coda: {
		name: 'Coda',
		contrast: 0.31,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.4,
		category: 'Display',
	},
	Codystar: {
		name: 'Codystar',
		contrast: 0.43,
		weight: 0.02,
		xHeight: 0.98,
		width: 0.55,
		category: 'Display',
	},
	Coiny: {
		name: 'Coiny',
		contrast: 0.48,
		weight: 0.11,
		xHeight: 0.7,
		width: 0.51,
		category: 'Display',
	},
	Combo: {
		name: 'Combo',
		contrast: 0.49,
		weight: 0.05,
		xHeight: 0.77,
		width: 0.34,
		category: 'Display',
	},
	Comfortaa: {
		name: 'Comfortaa',
		contrast: 0.08,
		weight: 0.07,
		xHeight: 0.7,
		width: 0.48,
		category: 'Display',
	},
	'Coming Soon': {
		name: 'Coming Soon',
		contrast: 0.17,
		weight: 0.05,
		xHeight: 0.73,
		width: 0.45,
		category: 'Handwriting',
	},
	'Concert One': {
		name: 'Concert One',
		contrast: 0.31,
		weight: 0.09,
		xHeight: 0.71,
		width: 0.39,
		category: 'Display',
	},
	Condiment: {
		name: 'Condiment',
		contrast: 0.69,
		weight: 0.06,
		xHeight: 0.59,
		width: 0.58,
		category: 'Handwriting',
	},
	'Contrail One': {
		name: 'Contrail One',
		contrast: 0.19,
		weight: 0.09,
		xHeight: 0.78,
		width: 0.4,
		category: 'Display',
	},
	Convergence: {
		name: 'Convergence',
		contrast: 0.28,
		weight: 0.08,
		xHeight: 0.75,
		width: 0.45,
		category: 'Sans-Serif',
	},
	Cookie: {
		name: 'Cookie',
		contrast: 0.79,
		weight: 0.04,
		xHeight: 0.56,
		width: 0.39,
		category: 'Handwriting',
	},
	Copse: {
		name: 'Copse',
		contrast: 0.26,
		weight: 0.07,
		xHeight: 0.67,
		width: 0.5,
		category: 'Serif',
	},
	Corben: {
		name: 'Corben',
		contrast: 0.5,
		weight: 0.07,
		xHeight: 0.68,
		width: 0.51,
		category: 'Display',
	},
	Cormorant: {
		name: 'Cormorant',
		contrast: 0.79,
		weight: 0.04,
		xHeight: 0.62,
		width: 0.42,
		category: 'Serif',
	},
	'Cormorant Garamond': {
		name: 'Cormorant Garamond',
		contrast: 0.79,
		weight: 0.04,
		xHeight: 0.62,
		width: 0.42,
		category: 'Serif',
	},
	'Cormorant Infant': {
		name: 'Cormorant Infant',
		contrast: 0.79,
		weight: 0.04,
		xHeight: 0.62,
		width: 0.42,
		category: 'Serif',
	},
	'Cormorant SC': {
		name: 'Cormorant SC',
		contrast: 0.8,
		weight: 0.04,
		xHeight: 0.75,
		width: 0.48,
		category: 'Serif',
	},
	'Cormorant Unicase': {
		name: 'Cormorant Unicase',
		contrast: 0.8,
		weight: 0.04,
		xHeight: 0.75,
		width: 0.47,
		category: 'Serif',
	},
	'Cormorant Upright': {
		name: 'Cormorant Upright',
		contrast: 0.7,
		weight: 0.03,
		xHeight: 0.63,
		width: 0.42,
		category: 'Serif',
	},
	Courgette: {
		name: 'Courgette',
		contrast: 0.5,
		weight: 0.07,
		xHeight: 0.67,
		width: 0.5,
		category: 'Handwriting',
	},
	Cousine: {
		name: 'Cousine',
		contrast: 0.3,
		weight: 0.07,
		xHeight: 0.8,
		width: 0.46,
		category: 'Monospace',
	},
	Coustard: {
		name: 'Coustard',
		contrast: 0.27,
		weight: 0.09,
		xHeight: 0.73,
		width: 0.55,
		category: 'Serif',
	},
	'Covered By Your Grace': {
		name: 'Covered By Your Grace',
		contrast: 0.3,
		weight: 0.04,
		xHeight: 0.63,
		width: 0.35,
		category: 'Handwriting',
	},
	'Crafty Girls': {
		name: 'Crafty Girls',
		contrast: 0.28,
		weight: 0.06,
		xHeight: 0.67,
		width: 0.52,
		category: 'Handwriting',
	},
	Creepster: {
		name: 'Creepster',
		contrast: 0.3,
		weight: 0.13,
		xHeight: 0.98,
		width: 0.42,
		category: 'Display',
	},
	'Crete Round': {
		name: 'Crete Round',
		contrast: 0.31,
		weight: 0.08,
		xHeight: 0.73,
		width: 0.48,
		category: 'Serif',
	},
	'Crimson Text': {
		name: 'Crimson Text',
		contrast: 0.56,
		weight: 0.05,
		xHeight: 0.66,
		width: 0.42,
		category: 'Serif',
	},
	'Croissant One': {
		name: 'Croissant One',
		contrast: 0.73,
		weight: 0.09,
		xHeight: 0.74,
		width: 0.57,
		category: 'Display',
	},
	Crushed: {
		name: 'Crushed',
		contrast: 0.3,
		weight: 0.08,
		xHeight: 1,
		width: 0.35,
		category: 'Display',
	},
	Cuprum: {
		name: 'Cuprum',
		contrast: 0.39,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.34,
		category: 'Sans-Serif',
	},
	Cutive: {
		name: 'Cutive',
		contrast: 0.24,
		weight: 0.09,
		xHeight: 0.66,
		width: 0.64,
		category: 'Serif',
	},
	'Cutive Mono': {
		name: 'Cutive Mono',
		contrast: 0.24,
		weight: 0.04,
		xHeight: 0.66,
		width: 0.44,
		category: 'Monospace',
	},
	Damion: {
		name: 'Damion',
		contrast: 0.73,
		weight: 0.06,
		xHeight: 0.5,
		width: 0.53,
		category: 'Handwriting',
	},
	'Dancing Script': {
		name: 'Dancing Script',
		contrast: 0.38,
		weight: 0.04,
		xHeight: 0.46,
		width: 0.45,
		category: 'Handwriting',
	},
	'David Libre': {
		name: 'David Libre',
		contrast: 0.46,
		weight: 0.05,
		xHeight: 0.74,
		width: 0.44,
		category: 'Serif',
	},
	'Dawning of a New Day': {
		name: 'Dawning of a New Day',
		contrast: 0.4,
		weight: 0.03,
		xHeight: 0.39,
		width: 0.43,
		category: 'Handwriting',
	},
	'Days One': {
		name: 'Days One',
		contrast: 0.35,
		weight: 0.12,
		xHeight: 0.76,
		width: 0.54,
		category: 'Sans-Serif',
	},
	Dekko: {
		name: 'Dekko',
		contrast: 0.33,
		weight: 0.06,
		xHeight: 0.8,
		width: 0.38,
		category: 'Handwriting',
	},
	Delius: {
		name: 'Delius',
		contrast: 0.09,
		weight: 0.06,
		xHeight: 0.68,
		width: 0.43,
		category: 'Handwriting',
	},
	'Delius Swash Caps': {
		name: 'Delius Swash Caps',
		contrast: 0.09,
		weight: 0.06,
		xHeight: 0.68,
		width: 0.43,
		category: 'Handwriting',
	},
	'Delius Unicase': {
		name: 'Delius Unicase',
		contrast: 0.12,
		weight: 0.11,
		xHeight: 1,
		width: 0.61,
		category: 'Handwriting',
	},
	'Della Respira': {
		name: 'Della Respira',
		contrast: 0.45,
		weight: 0.06,
		xHeight: 0.58,
		width: 0.46,
		category: 'Serif',
	},
	'Denk One': {
		name: 'Denk One',
		contrast: 0.54,
		weight: 0.1,
		xHeight: 0.71,
		width: 0.41,
		category: 'Sans-Serif',
	},
	Devonshire: {
		name: 'Devonshire',
		contrast: 0.45,
		weight: 0.04,
		xHeight: 0.66,
		width: 0.34,
		category: 'Handwriting',
	},
	Dhurjati: {
		name: 'Dhurjati',
		contrast: 0.42,
		weight: 0.05,
		xHeight: 0.75,
		width: 0.34,
		category: 'Sans-Serif',
	},
	'Didact Gothic': {
		name: 'Didact Gothic',
		contrast: 0.09,
		weight: 0.06,
		xHeight: 0.69,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Diplomata: {
		name: 'Diplomata',
		contrast: 0.96,
		weight: 0.19,
		xHeight: 0.86,
		width: 1.06,
		category: 'Display',
	},
	'Diplomata SC': {
		name: 'Diplomata SC',
		contrast: 0.84,
		weight: 0.18,
		xHeight: 0.86,
		width: 1.13,
		category: 'Display',
	},
	Domine: {
		name: 'Domine',
		contrast: 0.52,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.51,
		category: 'Serif',
	},
	'Donegal One': {
		name: 'Donegal One',
		contrast: 0.57,
		weight: 0.07,
		xHeight: 0.67,
		width: 0.51,
		category: 'Serif',
	},
	'Doppio One': {
		name: 'Doppio One',
		contrast: 0.2,
		weight: 0.09,
		xHeight: 0.74,
		width: 0.43,
		category: 'Sans-Serif',
	},
	Dorsa: {
		name: 'Dorsa',
		contrast: 0.58,
		weight: 0.03,
		xHeight: 0.71,
		width: 0.15,
		category: 'Sans-Serif',
	},
	Dosis: {
		name: 'Dosis',
		contrast: 0.08,
		weight: 0.05,
		xHeight: 0.66,
		width: 0.35,
		category: 'Sans-Serif',
	},
	'Dr Sugiyama': {
		name: 'Dr Sugiyama',
		contrast: 0.92,
		weight: 0.03,
		xHeight: 0.3,
		width: 0.41,
		category: 'Handwriting',
	},
	'Droid Sans': {
		name: 'Droid Sans',
		contrast: 0.2,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.41,
		category: 'Sans-Serif',
	},
	'Droid Sans Mono': {
		name: 'Droid Sans Mono',
		contrast: 0.2,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.46,
		category: 'Monospace',
	},
	'Droid Serif': {
		name: 'Droid Serif',
		contrast: 0.47,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.5,
		category: 'Serif',
	},
	'Duru Sans': {
		name: 'Duru Sans',
		contrast: 0.21,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.45,
		category: 'Sans-Serif',
	},
	Dynalight: {
		name: 'Dynalight',
		contrast: 0.69,
		weight: 0.03,
		xHeight: 0.5,
		width: 0.41,
		category: 'Display',
	},
	'EB Garamond': {
		name: 'EB Garamond',
		contrast: 0.67,
		weight: 0.04,
		xHeight: 0.62,
		width: 0.42,
		category: 'Serif',
	},
	'Eagle Lake': {
		name: 'Eagle Lake',
		contrast: 0.79,
		weight: 0.08,
		xHeight: 0.72,
		width: 0.67,
		category: 'Handwriting',
	},
	Eater: {
		name: 'Eater',
		contrast: 0.67,
		weight: 0.13,
		xHeight: 1.04,
		width: 0.76,
		category: 'Display',
	},
	Economica: {
		name: 'Economica',
		contrast: 0.16,
		weight: 0.05,
		xHeight: 0.75,
		width: 0.26,
		category: 'Sans-Serif',
	},
	Eczar: {
		name: 'Eczar',
		contrast: 0.36,
		weight: 0.06,
		xHeight: 0.72,
		width: 0.47,
		category: 'Serif',
	},
	'El Messiri': {
		name: 'El Messiri',
		contrast: 0.58,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.44,
		category: 'Sans-Serif',
	},
	Electrolize: {
		name: 'Electrolize',
		contrast: 0.17,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.42,
		category: 'Sans-Serif',
	},
	Elsie: {
		name: 'Elsie',
		contrast: 0.82,
		weight: 0.05,
		xHeight: 0.73,
		width: 0.45,
		category: 'Display',
	},
	'Elsie Swash Caps': {
		name: 'Elsie Swash Caps',
		contrast: 0.82,
		weight: 0.05,
		xHeight: 0.71,
		width: 0.45,
		category: 'Display',
	},
	'Emblema One': {
		name: 'Emblema One',
		contrast: 0.88,
		weight: 0.11,
		xHeight: 0.73,
		width: 0.6,
		category: 'Display',
	},
	'Emilys Candy': {
		name: 'Emilys Candy',
		contrast: 0.79,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.49,
		category: 'Display',
	},
	'Encode Sans': {
		name: 'Encode Sans',
		contrast: 0.21,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.42,
		category: 'Sans-Serif',
	},
	'Encode Sans Condensed': {
		name: 'Encode Sans Condensed',
		contrast: 0.21,
		weight: 0.06,
		xHeight: 0.73,
		width: 0.37,
		category: 'Sans-Serif',
	},
	'Encode Sans Expanded': {
		name: 'Encode Sans Expanded',
		contrast: 0.2,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.47,
		category: 'Sans-Serif',
	},
	'Encode Sans Semi Condensed': {
		name: 'Encode Sans Semi Condensed',
		contrast: 0.21,
		weight: 0.06,
		xHeight: 0.73,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Encode Sans Semi Expanded': {
		name: 'Encode Sans Semi Expanded',
		contrast: 0.21,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.44,
		category: 'Sans-Serif',
	},
	Engagement: {
		name: 'Engagement',
		contrast: 0.58,
		weight: 0.03,
		xHeight: 0.42,
		width: 0.31,
		category: 'Handwriting',
	},
	Englebert: {
		name: 'Englebert',
		contrast: 0.13,
		weight: 0.06,
		xHeight: 0.73,
		width: 0.37,
		category: 'Sans-Serif',
	},
	Enriqueta: {
		name: 'Enriqueta',
		contrast: 0.35,
		weight: 0.06,
		xHeight: 0.67,
		width: 0.47,
		category: 'Serif',
	},
	'Erica One': {
		name: 'Erica One',
		contrast: 0.3,
		weight: 0.17,
		xHeight: 0.86,
		width: 0.58,
		category: 'Display',
	},
	Esteban: {
		name: 'Esteban',
		contrast: 0.44,
		weight: 0.06,
		xHeight: 0.68,
		width: 0.47,
		category: 'Serif',
	},
	'Euphoria Script': {
		name: 'Euphoria Script',
		contrast: 0,
		weight: 0.03,
		xHeight: 0.6,
		width: 0.35,
		category: 'Handwriting',
	},
	Ewert: {
		name: 'Ewert',
		contrast: 0.87,
		weight: 0.15,
		xHeight: 1,
		width: 0.7,
		category: 'Display',
	},
	Exo: {
		name: 'Exo',
		contrast: 0.19,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.43,
		category: 'Sans-Serif',
	},
	'Exo 2': {
		name: 'Exo 2',
		contrast: 0.19,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.42,
		category: 'Sans-Serif',
	},
	'Expletus Sans': {
		name: 'Expletus Sans',
		contrast: 0.27,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.43,
		category: 'Display',
	},
	'Fanwood Text': {
		name: 'Fanwood Text',
		contrast: 0.6,
		weight: 0.04,
		xHeight: 0.57,
		width: 0.41,
		category: 'Serif',
	},
	Farsan: {
		name: 'Farsan',
		contrast: 0.07,
		weight: 0.04,
		xHeight: 0.67,
		width: 0.32,
		category: 'Display',
	},
	Fascinate: {
		name: 'Fascinate',
		contrast: 0.72,
		weight: 0.13,
		xHeight: 0.71,
		width: 0.56,
		category: 'Display',
	},
	'Fascinate Inline': {
		name: 'Fascinate Inline',
		contrast: 0.72,
		weight: 0.12,
		xHeight: 0.71,
		width: 0.56,
		category: 'Display',
	},
	'Faster One': {
		name: 'Faster One',
		contrast: 0.15,
		weight: 0.16,
		xHeight: 0.85,
		width: 0.94,
		category: 'Display',
	},
	'Fauna One': {
		name: 'Fauna One',
		contrast: 0.23,
		weight: 0.07,
		xHeight: 0.78,
		width: 0.5,
		category: 'Serif',
	},
	Faustina: {
		name: 'Faustina',
		contrast: 0.39,
		weight: 0.06,
		xHeight: 0.76,
		width: 0.45,
		category: 'Serif',
	},
	Federant: {
		name: 'Federant',
		contrast: 0.64,
		weight: 0.08,
		xHeight: 0.73,
		width: 0.48,
		category: 'Display',
	},
	Federo: {
		name: 'Federo',
		contrast: 0.58,
		weight: 0.06,
		xHeight: 0.63,
		width: 0.38,
		category: 'Sans-Serif',
	},
	Felipa: {
		name: 'Felipa',
		contrast: 0.65,
		weight: 0.04,
		xHeight: 0.69,
		width: 0.44,
		category: 'Handwriting',
	},
	Fenix: {
		name: 'Fenix',
		contrast: 0.4,
		weight: 0.06,
		xHeight: 0.69,
		width: 0.44,
		category: 'Serif',
	},
	'Finger Paint': {
		name: 'Finger Paint',
		contrast: 0.74,
		weight: 0.11,
		xHeight: 0.82,
		width: 0.53,
		category: 'Display',
	},
	'Fira Mono': {
		name: 'Fira Mono',
		contrast: 0.24,
		weight: 0.07,
		xHeight: 0.76,
		width: 0.44,
		category: 'Monospace',
	},
	'Fira Sans': {
		name: 'Fira Sans',
		contrast: 0.25,
		weight: 0.07,
		xHeight: 0.76,
		width: 0.42,
		category: 'Sans-Serif',
	},
	'Fira Sans Condensed': {
		name: 'Fira Sans Condensed',
		contrast: 0.24,
		weight: 0.07,
		xHeight: 0.76,
		width: 0.38,
		category: 'Sans-Serif',
	},
	'Fira Sans Extra Condensed': {
		name: 'Fira Sans Extra Condensed',
		contrast: 0.24,
		weight: 0.07,
		xHeight: 0.76,
		width: 0.35,
		category: 'Sans-Serif',
	},
	'Fjalla One': {
		name: 'Fjalla One',
		contrast: 0.27,
		weight: 0.1,
		xHeight: 0.81,
		width: 0.36,
		category: 'Sans-Serif',
	},
	'Fjord One': {
		name: 'Fjord One',
		contrast: 0.44,
		weight: 0.06,
		xHeight: 0.66,
		width: 0.47,
		category: 'Serif',
	},
	Flamenco: {
		name: 'Flamenco',
		contrast: 0.16,
		weight: 0.04,
		xHeight: 0.63,
		width: 0.42,
		category: 'Display',
	},
	Flavors: {
		name: 'Flavors',
		contrast: 0.94,
		weight: 0.08,
		xHeight: 0.91,
		width: 0.45,
		category: 'Display',
	},
	Fondamento: {
		name: 'Fondamento',
		contrast: 0.79,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.49,
		category: 'Handwriting',
	},
	'Fontdiner Swanky': {
		name: 'Fontdiner Swanky',
		contrast: 0.51,
		weight: 0.1,
		xHeight: 0.81,
		width: 0.72,
		category: 'Display',
	},
	Forum: {
		name: 'Forum',
		contrast: 0.51,
		weight: 0.04,
		xHeight: 0.64,
		width: 0.38,
		category: 'Display',
	},
	'Francois One': {
		name: 'Francois One',
		contrast: 0.3,
		weight: 0.09,
		xHeight: 0.75,
		width: 0.4,
		category: 'Sans-Serif',
	},
	'Frank Ruhl Libre': {
		name: 'Frank Ruhl Libre',
		contrast: 0.66,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.45,
		category: 'Serif',
	},
	'Freckle Face': {
		name: 'Freckle Face',
		contrast: 0.53,
		weight: 0.07,
		xHeight: 0.78,
		width: 0.46,
		category: 'Display',
	},
	'Fredericka the Great': {
		name: 'Fredericka the Great',
		contrast: 0.21,
		weight: 0.07,
		xHeight: 0.65,
		width: 0.52,
		category: 'Display',
	},
	'Fredoka One': {
		name: 'Fredoka One',
		contrast: 0.02,
		weight: 0.12,
		xHeight: 0.73,
		width: 0.5,
		category: 'Display',
	},
	Fresca: {
		name: 'Fresca',
		contrast: 0.25,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Frijole: {
		name: 'Frijole',
		contrast: 0.97,
		weight: 0.18,
		xHeight: 0.92,
		width: 0.75,
		category: 'Display',
	},
	Fruktur: {
		name: 'Fruktur',
		contrast: 0.39,
		weight: 0.11,
		xHeight: 0.79,
		width: 0.5,
		category: 'Display',
	},
	'Fugaz One': {
		name: 'Fugaz One',
		contrast: 0.21,
		weight: 0.11,
		xHeight: 0.68,
		width: 0.54,
		category: 'Display',
	},
	'GFS Didot': {
		name: 'GFS Didot',
		contrast: 0.72,
		weight: 0.06,
		xHeight: 0.66,
		width: 0.47,
		category: 'Serif',
	},
	'GFS Neohellenic': {
		name: 'GFS Neohellenic',
		contrast: 0.14,
		weight: 0.04,
		xHeight: 0.62,
		width: 0.34,
		category: 'Sans-Serif',
	},
	Gabriela: {
		name: 'Gabriela',
		contrast: 0.5,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.5,
		category: 'Serif',
	},
	'Bellota Text': {
		name: 'Bellota Text',
		contrast: 0.5,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.5,
		category: 'Serif',
	},
	Commissioner: {
		name: 'Commissioner',
		contrast: 0.5,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.5,
		category: 'Serif',
	},
	Gafata: {
		name: 'Gafata',
		contrast: 0.31,
		weight: 0.05,
		xHeight: 0.76,
		width: 0.36,
		category: 'Sans-Serif',
	},
	Galada: {
		name: 'Galada',
		contrast: 0.59,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.52,
		category: 'Display',
	},
	Galdeano: {
		name: 'Galdeano',
		contrast: 0.44,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.37,
		category: 'Sans-Serif',
	},
	Galindo: {
		name: 'Galindo',
		contrast: 0.46,
		weight: 0.11,
		xHeight: 0.72,
		width: 0.54,
		category: 'Display',
	},
	'Gentium Basic': {
		name: 'Gentium Basic',
		contrast: 0.45,
		weight: 0.05,
		xHeight: 0.74,
		width: 0.44,
		category: 'Serif',
	},
	'Gentium Book Basic': {
		name: 'Gentium Book Basic',
		contrast: 0.47,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.45,
		category: 'Serif',
	},
	Geo: {
		name: 'Geo',
		contrast: 0.1,
		weight: 0.06,
		xHeight: 0.79,
		width: 0.31,
		category: 'Sans-Serif',
	},
	Geostar: {
		name: 'Geostar',
		contrast: 0.73,
		weight: 0.07,
		xHeight: 0.67,
		width: 0.71,
		category: 'Display',
	},
	'Geostar Fill': {
		name: 'Geostar Fill',
		contrast: 0.73,
		weight: 0.08,
		xHeight: 0.67,
		width: 0.71,
		category: 'Display',
	},
	'Germania One': {
		name: 'Germania One',
		contrast: 0.18,
		weight: 0.08,
		xHeight: 0.76,
		width: 0.4,
		category: 'Display',
	},
	Gidugu: {
		name: 'Gidugu',
		contrast: 0.51,
		weight: 0.04,
		xHeight: 0.78,
		width: 0.28,
		category: 'Sans-Serif',
	},
	'Gilda Display': {
		name: 'Gilda Display',
		contrast: 0.73,
		weight: 0.05,
		xHeight: 0.68,
		width: 0.48,
		category: 'Serif',
	},
	'Give You Glory': {
		name: 'Give You Glory',
		contrast: 0.47,
		weight: 0.03,
		xHeight: 0.71,
		width: 0.46,
		category: 'Handwriting',
	},
	'Glass Antiqua': {
		name: 'Glass Antiqua',
		contrast: 0.18,
		weight: 0.05,
		xHeight: 0.75,
		width: 0.38,
		category: 'Display',
	},
	Glegoo: {
		name: 'Glegoo',
		contrast: 0.16,
		weight: 0.07,
		xHeight: 0.78,
		width: 0.48,
		category: 'Serif',
	},
	'Gloria Hallelujah': {
		name: 'Gloria Hallelujah',
		contrast: 0.33,
		weight: 0.07,
		xHeight: 0.72,
		width: 0.53,
		category: 'Handwriting',
	},
	'Goblin One': {
		name: 'Goblin One',
		contrast: 0.66,
		weight: 0.17,
		xHeight: 0.77,
		width: 0.84,
		category: 'Display',
	},
	'Gochi Hand': {
		name: 'Gochi Hand',
		contrast: 0.23,
		weight: 0.07,
		xHeight: 0.88,
		width: 0.46,
		category: 'Handwriting',
	},
	Gorditas: {
		name: 'Gorditas',
		contrast: 0.32,
		weight: 0.09,
		xHeight: 0.72,
		width: 0.55,
		category: 'Display',
	},
	'Goudy Bookletter 1911': {
		name: 'Goudy Bookletter 1911',
		contrast: 0.39,
		weight: 0.05,
		xHeight: 0.6,
		width: 0.43,
		category: 'Serif',
	},
	Graduate: {
		name: 'Graduate',
		contrast: 0.07,
		weight: 0.1,
		xHeight: 0.87,
		width: 0.54,
		category: 'Display',
	},
	'Grand Hotel': {
		name: 'Grand Hotel',
		contrast: 0.29,
		weight: 0.06,
		xHeight: 0.52,
		width: 0.41,
		category: 'Handwriting',
	},
	'Gravitas One': {
		name: 'Gravitas One',
		contrast: 0.89,
		weight: 0.16,
		xHeight: 0.79,
		width: 0.78,
		category: 'Display',
	},
	'Great Vibes': {
		name: 'Great Vibes',
		contrast: 0.79,
		weight: 0.03,
		xHeight: 0.44,
		width: 0.47,
		category: 'Handwriting',
	},
	Griffy: {
		name: 'Griffy',
		contrast: 0.84,
		weight: 0.06,
		xHeight: 0.69,
		width: 0.5,
		category: 'Display',
	},
	Gruppo: {
		name: 'Gruppo',
		contrast: 0.27,
		weight: 0.03,
		xHeight: 0.69,
		width: 0.41,
		category: 'Display',
	},
	Gudea: {
		name: 'Gudea',
		contrast: 0.14,
		weight: 0.06,
		xHeight: 0.72,
		width: 0.37,
		category: 'Sans-Serif',
	},
	Gurajada: {
		name: 'Gurajada',
		contrast: 0.29,
		weight: 0.04,
		xHeight: 0.73,
		width: 0.29,
		category: 'Serif',
	},
	Habibi: {
		name: 'Habibi',
		contrast: 0.34,
		weight: 0.06,
		xHeight: 0.66,
		width: 0.48,
		category: 'Serif',
	},
	Halant: {
		name: 'Halant',
		contrast: 0.52,
		weight: 0.05,
		xHeight: 0.76,
		width: 0.43,
		category: 'Serif',
	},
	'Hammersmith One': {
		name: 'Hammersmith One',
		contrast: 0.13,
		weight: 0.09,
		xHeight: 0.78,
		width: 0.45,
		category: 'Sans-Serif',
	},
	Hanalei: {
		name: 'Hanalei',
		contrast: 0.19,
		weight: 0.1,
		xHeight: 0.95,
		width: 0.51,
		category: 'Display',
	},
	'Hanalei Fill': {
		name: 'Hanalei Fill',
		contrast: 0.19,
		weight: 0.16,
		xHeight: 0.95,
		width: 0.51,
		category: 'Display',
	},
	Handlee: {
		name: 'Handlee',
		contrast: 0.43,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.4,
		category: 'Handwriting',
	},
	Hanuman: {
		name: 'Hanuman',
		contrast: 0.03,
		weight: 0.07,
		xHeight: 0.76,
		width: 0.4,
		category: 'Serif',
	},
	'Happy Monkey': {
		name: 'Happy Monkey',
		contrast: 0.1,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.5,
		category: 'Display',
	},
	Harmattan: {
		name: 'Harmattan',
		contrast: 0.14,
		weight: 0.04,
		xHeight: 0.7,
		width: 0.33,
		category: 'Sans-Serif',
	},
	'Headland One': {
		name: 'Headland One',
		contrast: 0.52,
		weight: 0.09,
		xHeight: 0.78,
		width: 0.54,
		category: 'Serif',
	},
	Heebo: {
		name: 'Heebo',
		contrast: 0.2,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.41,
		category: 'Sans-Serif',
	},
	'Henny Penny': {
		name: 'Henny Penny',
		contrast: 0.85,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.54,
		category: 'Display',
	},
	'Herr Von Muellerhoff': {
		name: 'Herr Von Muellerhoff',
		contrast: 0.94,
		weight: 0.02,
		xHeight: 0.31,
		width: 0.47,
		category: 'Handwriting',
	},
	Hind: {
		name: 'Hind',
		contrast: 0.15,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Hind Guntur': {
		name: 'Hind Guntur',
		contrast: 0.15,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Hind Madurai': {
		name: 'Hind Madurai',
		contrast: 0.15,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Hind Siliguri': {
		name: 'Hind Siliguri',
		contrast: 0.15,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Hind Vadodara': {
		name: 'Hind Vadodara',
		contrast: 0.15,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Holtwood One SC': {
		name: 'Holtwood One SC',
		contrast: 0.29,
		weight: 0.23,
		xHeight: 0.92,
		width: 0.75,
		category: 'Serif',
	},
	'Homemade Apple': {
		name: 'Homemade Apple',
		contrast: 0.6,
		weight: 0.06,
		xHeight: 0.6,
		width: 0.8,
		category: 'Handwriting',
	},
	Homenaje: {
		name: 'Homenaje',
		contrast: 0.21,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.28,
		category: 'Sans-Serif',
	},
	'IM Fell DW Pica': {
		name: 'IM Fell DW Pica',
		contrast: 0.59,
		weight: 0.06,
		xHeight: 0.61,
		width: 0.42,
		category: 'Serif',
	},
	'IM Fell DW Pica SC': {
		name: 'IM Fell DW Pica SC',
		contrast: 0.59,
		weight: 0.06,
		xHeight: 0.61,
		width: 0.42,
		category: 'Serif',
	},
	'IM Fell Double Pica': {
		name: 'IM Fell Double Pica',
		contrast: 0.82,
		weight: 0.05,
		xHeight: 0.61,
		width: 0.44,
		category: 'Serif',
	},
	'IM Fell Double Pica SC': {
		name: 'IM Fell Double Pica SC',
		contrast: 0.81,
		weight: 0.05,
		xHeight: 0.61,
		width: 0.44,
		category: 'Serif',
	},
	'IM Fell English': {
		name: 'IM Fell English',
		contrast: 0.66,
		weight: 0.06,
		xHeight: 0.68,
		width: 0.45,
		category: 'Serif',
	},
	'IM Fell English SC': {
		name: 'IM Fell English SC',
		contrast: 0.71,
		weight: 0.05,
		xHeight: 0.64,
		width: 0.45,
		category: 'Serif',
	},
	'IM Fell French Canon': {
		name: 'IM Fell French Canon',
		contrast: 0.76,
		weight: 0.05,
		xHeight: 0.68,
		width: 0.49,
		category: 'Serif',
	},
	'IM Fell French Canon SC': {
		name: 'IM Fell French Canon SC',
		contrast: 0.78,
		weight: 0.05,
		xHeight: 0.68,
		width: 0.49,
		category: 'Serif',
	},
	'IM Fell Great Primer': {
		name: 'IM Fell Great Primer',
		contrast: 0.6,
		weight: 0.05,
		xHeight: 0.65,
		width: 0.46,
		category: 'Serif',
	},
	'IM Fell Great Primer SC': {
		name: 'IM Fell Great Primer SC',
		contrast: 0.63,
		weight: 0.05,
		xHeight: 0.65,
		width: 0.48,
		category: 'Serif',
	},
	Iceberg: {
		name: 'Iceberg',
		contrast: 0.29,
		weight: 0.07,
		xHeight: 0.79,
		width: 0.36,
		category: 'Display',
	},
	Iceland: {
		name: 'Iceland',
		contrast: 0.14,
		weight: 0.04,
		xHeight: 0.71,
		width: 0.34,
		category: 'Display',
	},
	Imprima: {
		name: 'Imprima',
		contrast: 0.11,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Inconsolata: {
		name: 'Inconsolata',
		contrast: 0.21,
		weight: 0.05,
		xHeight: 0.73,
		width: 0.39,
		category: 'Monospace',
	},
	Inder: {
		name: 'Inder',
		contrast: 0.26,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.42,
		category: 'Sans-Serif',
	},
	'Indie Flower': {
		name: 'Indie Flower',
		contrast: 0.35,
		weight: 0.04,
		xHeight: 0.57,
		width: 0.43,
		category: 'Handwriting',
	},
	Inika: {
		name: 'Inika',
		contrast: 0.36,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.5,
		category: 'Serif',
	},

	'Inknut Antiqua': {
		name: 'Inknut Antiqua',
		contrast: 0.55,
		weight: 0.09,
		xHeight: 0.68,
		width: 0.57,
		category: 'Serif',
	},
	'Irish Grover': {
		name: 'Irish Grover',
		contrast: 0.65,
		weight: 0.07,
		xHeight: 0.8,
		width: 0.51,
		category: 'Display',
	},
	'Istok Web': {
		name: 'Istok Web',
		contrast: 0.3,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.41,
		category: 'Sans-Serif',
	},
	Italiana: {
		name: 'Italiana',
		contrast: 0.88,
		weight: 0.04,
		xHeight: 0.71,
		width: 0.41,
		category: 'Serif',
	},
	Italianno: {
		name: 'Italianno',
		contrast: 0.78,
		weight: 0.02,
		xHeight: 0.46,
		width: 0.42,
		category: 'Handwriting',
	},
	Itim: {
		name: 'Itim',
		contrast: 0.21,
		weight: 0.06,
		xHeight: 0.69,
		width: 0.43,
		category: 'Handwriting',
	},
	'Jacques Francois': {
		name: 'Jacques Francois',
		contrast: 0.69,
		weight: 0.05,
		xHeight: 0.6,
		width: 0.47,
		category: 'Serif',
	},
	'Jacques Francois Shadow': {
		name: 'Jacques Francois Shadow',
		contrast: 0.63,
		weight: 0.07,
		xHeight: 0.61,
		width: 0.54,
		category: 'Display',
	},
	Jaldi: {
		name: 'Jaldi',
		contrast: 0.18,
		weight: 0.05,
		xHeight: 0.75,
		width: 0.34,
		category: 'Sans-Serif',
	},
	'Jim Nightshade': {
		name: 'Jim Nightshade',
		contrast: 0.7,
		weight: 0.03,
		xHeight: 0.82,
		width: 0.36,
		category: 'Handwriting',
	},
	'Jockey One': {
		name: 'Jockey One',
		contrast: 0.23,
		weight: 0.08,
		xHeight: 0.73,
		width: 0.37,
		category: 'Sans-Serif',
	},
	'Jolly Lodger': {
		name: 'Jolly Lodger',
		contrast: 0.44,
		weight: 0.05,
		xHeight: 0.77,
		width: 0.29,
		category: 'Display',
	},
	Jomhuria: {
		name: 'Jomhuria',
		contrast: 0.67,
		weight: 0.04,
		xHeight: 0.73,
		width: 0.27,
		category: 'Display',
	},
	'Josefin Sans': {
		name: 'Josefin Sans',
		contrast: 0.19,
		weight: 0.06,
		xHeight: 0.57,
		width: 0.4,
		category: 'Sans-Serif',
	},
	'Josefin Slab': {
		name: 'Josefin Slab',
		contrast: 0.09,
		weight: 0.03,
		xHeight: 0.54,
		width: 0.42,
		category: 'Serif',
	},
	'Joti One': {
		name: 'Joti One',
		contrast: 0.57,
		weight: 0.08,
		xHeight: 0.77,
		width: 0.47,
		category: 'Display',
	},
	Judson: {
		name: 'Judson',
		contrast: 0.69,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.44,
		category: 'Serif',
	},
	Julee: {
		name: 'Julee',
		contrast: 0.53,
		weight: 0.06,
		xHeight: 0.72,
		width: 0.37,
		category: 'Handwriting',
	},
	'Julius Sans One': {
		name: 'Julius Sans One',
		contrast: 0.15,
		weight: 0.06,
		xHeight: 0.91,
		width: 0.48,
		category: 'Sans-Serif',
	},
	Junge: {
		name: 'Junge',
		contrast: 0.61,
		weight: 0.05,
		xHeight: 0.72,
		width: 0.48,
		category: 'Serif',
	},
	Jura: {
		name: 'Jura',
		contrast: 0.02,
		weight: 0.05,
		xHeight: 0.73,
		width: 0.4,
		category: 'Sans-Serif',
	},
	'Just Another Hand': {
		name: 'Just Another Hand',
		contrast: 0.18,
		weight: 0.03,
		xHeight: 0.65,
		width: 0.24,
		category: 'Handwriting',
	},
	'Just Me Again Down Here': {
		name: 'Just Me Again Down Here',
		contrast: 0.27,
		weight: 0.03,
		xHeight: 0.52,
		width: 0.29,
		category: 'Handwriting',
	},
	Kadwa: {
		name: 'Kadwa',
		contrast: 0.25,
		weight: 0.08,
		xHeight: 0.76,
		width: 0.51,
		category: 'Serif',
	},
	Kalam: {
		name: 'Kalam',
		contrast: 0.29,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.43,
		category: 'Handwriting',
	},
	Kameron: {
		name: 'Kameron',
		contrast: 0.4,
		weight: 0.06,
		xHeight: 0.69,
		width: 0.47,
		category: 'Serif',
	},
	Kanit: {
		name: 'Kanit',
		contrast: 0.17,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.43,
		category: 'Sans-Serif',
	},
	Karla: {
		name: 'Karla',
		contrast: 0.16,
		weight: 0.06,
		xHeight: 0.76,
		width: 0.41,
		category: 'Sans-Serif',
	},
	Karma: {
		name: 'Karma',
		contrast: 0.33,
		weight: 0.06,
		xHeight: 0.72,
		width: 0.45,
		category: 'Serif',
	},
	Katibeh: {
		name: 'Katibeh',
		contrast: 0.79,
		weight: 0.04,
		xHeight: 0.71,
		width: 0.34,
		category: 'Display',
	},
	'Kaushan Script': {
		name: 'Kaushan Script',
		contrast: 0.76,
		weight: 0.06,
		xHeight: 0.64,
		width: 0.49,
		category: 'Handwriting',
	},
	Kavivanar: {
		name: 'Kavivanar',
		contrast: 0.59,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.44,
		category: 'Handwriting',
	},
	Kavoon: {
		name: 'Kavoon',
		contrast: 0.27,
		weight: 0.12,
		xHeight: 0.76,
		width: 0.54,
		category: 'Display',
	},
	'Keania One': {
		name: 'Keania One',
		contrast: 0.89,
		weight: 0.09,
		xHeight: 0.69,
		width: 0.41,
		category: 'Display',
	},
	'Kelly Slab': {
		name: 'Kelly Slab',
		contrast: 0.17,
		weight: 0.06,
		xHeight: 0.67,
		width: 0.41,
		category: 'Display',
	},
	Kenia: {
		name: 'Kenia',
		contrast: 0.95,
		weight: 0.08,
		xHeight: 0.7,
		width: 0.35,
		category: 'Display',
	},
	Khand: {
		name: 'Khand',
		contrast: 0.12,
		weight: 0.06,
		xHeight: 0.85,
		width: 0.31,
		category: 'Sans-Serif',
	},
	Khula: {
		name: 'Khula',
		contrast: 0.2,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.41,
		category: 'Sans-Serif',
	},
	'Kite One': {
		name: 'Kite One',
		contrast: 0.24,
		weight: 0.06,
		xHeight: 0.58,
		width: 0.44,
		category: 'Sans-Serif',
	},
	Knewave: {
		name: 'Knewave',
		contrast: 0.49,
		weight: 0.12,
		xHeight: 0.64,
		width: 0.56,
		category: 'Display',
	},
	'Kotta One': {
		name: 'Kotta One',
		contrast: 0.39,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.44,
		category: 'Serif',
	},
	Kranky: {
		name: 'Kranky',
		contrast: 0.74,
		weight: 0.04,
		xHeight: 0.56,
		width: 0.5,
		category: 'Display',
	},
	Kreon: {
		name: 'Kreon',
		contrast: 0.22,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.43,
		category: 'Serif',
	},
	Kristi: {
		name: 'Kristi',
		contrast: 0.73,
		weight: 0.02,
		xHeight: 0.63,
		width: 0.36,
		category: 'Handwriting',
	},
	'Krona One': {
		name: 'Krona One',
		contrast: 0.17,
		weight: 0.13,
		xHeight: 0.76,
		width: 0.64,
		category: 'Sans-Serif',
	},
	'Kumar One': {
		name: 'Kumar One',
		contrast: 0.83,
		weight: 0.15,
		xHeight: 0.75,
		width: 0.65,
		category: 'Display',
	},
	'Kumar One Outline': {
		name: 'Kumar One Outline',
		contrast: 0.79,
		weight: 0.09,
		xHeight: 0.75,
		width: 0.65,
		category: 'Display',
	},
	Kurale: {
		name: 'Kurale',
		contrast: 0.5,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.46,
		category: 'Serif',
	},
	'La Belle Aurore': {
		name: 'La Belle Aurore',
		contrast: 0.45,
		weight: 0.03,
		xHeight: 0.68,
		width: 0.51,
		category: 'Handwriting',
	},
	Laila: {
		name: 'Laila',
		contrast: 0.37,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.45,
		category: 'Serif',
	},
	'Lakki Reddy': {
		name: 'Lakki Reddy',
		contrast: 0.65,
		weight: 0.07,
		xHeight: 0.8,
		width: 0.51,
		category: 'Handwriting',
	},
	Lalezar: {
		name: 'Lalezar',
		contrast: 0.36,
		weight: 0.1,
		xHeight: 0.81,
		width: 0.44,
		category: 'Display',
	},
	Lancelot: {
		name: 'Lancelot',
		contrast: 0.45,
		weight: 0.04,
		xHeight: 0.68,
		width: 0.39,
		category: 'Display',
	},
	Lateef: {
		name: 'Lateef',
		contrast: 0.68,
		weight: 0.03,
		xHeight: 0.68,
		width: 0.33,
		category: 'Handwriting',
	},
	Lato: {
		name: 'Lato',
		contrast: 0.25,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.42,
		category: 'Sans-Serif',
	},
	'League Script': {
		name: 'League Script',
		contrast: 0.88,
		weight: 0.02,
		xHeight: 0.46,
		width: 0.6,
		category: 'Handwriting',
	},
	'Leckerli One': {
		name: 'Leckerli One',
		contrast: 0.38,
		weight: 0.1,
		xHeight: 0.66,
		width: 0.55,
		category: 'Handwriting',
	},
	Ledger: {
		name: 'Ledger',
		contrast: 0.59,
		weight: 0.06,
		xHeight: 0.65,
		width: 0.51,
		category: 'Serif',
	},
	Lekton: {
		name: 'Lekton',
		contrast: 0.1,
		weight: 0.05,
		xHeight: 0.73,
		width: 0.36,
		category: 'Sans-Serif',
	},
	Lemon: {
		name: 'Lemon',
		contrast: 0.86,
		weight: 0.14,
		xHeight: 0.83,
		width: 0.6,
		category: 'Display',
	},
	Lemonada: {
		name: 'Lemonada',
		contrast: 0,
		weight: 0.09,
		xHeight: 0.83,
		width: 0.54,
		category: 'Display',
	},
	'Libre Barcode 128': {
		name: 'Libre Barcode 128',
		contrast: 0.57,
		weight: 0.07,
		xHeight: 1,
		width: 0.27,
		category: 'Display',
	},
	'Libre Barcode 128 Text': {
		name: 'Libre Barcode 128 Text',
		contrast: 0.57,
		weight: 0.08,
		xHeight: 1,
		width: 0.27,
		category: 'Display',
	},
	'Libre Barcode 39': {
		name: 'Libre Barcode 39',
		contrast: 0.5,
		weight: 0.1,
		xHeight: 1,
		width: 0.48,
		category: 'Display',
	},
	'Libre Barcode 39 Extended': {
		name: 'Libre Barcode 39 Extended',
		contrast: 0.2,
		weight: 0.16,
		xHeight: 1,
		width: 1.01,
		category: 'Display',
	},
	'Libre Barcode 39 Extended Text': {
		name: 'Libre Barcode 39 Extended Text',
		contrast: 0.2,
		weight: 0.17,
		xHeight: 1,
		width: 1.01,
		category: 'Display',
	},
	'Libre Barcode 39 Text': {
		name: 'Libre Barcode 39 Text',
		contrast: 0.5,
		weight: 0.12,
		xHeight: 1,
		width: 0.48,
		category: 'Display',
	},
	'Libre Baskerville': {
		name: 'Libre Baskerville',
		contrast: 0.57,
		weight: 0.08,
		xHeight: 0.69,
		width: 0.55,
		category: 'Serif',
	},
	'Libre Franklin': {
		name: 'Libre Franklin',
		contrast: 0.28,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.44,
		category: 'Sans-Serif',
	},
	'Life Savers': {
		name: 'Life Savers',
		contrast: 0.34,
		weight: 0.04,
		xHeight: 0.53,
		width: 0.49,
		category: 'Display',
	},
	'Lilita One': {
		name: 'Lilita One',
		contrast: 0.05,
		weight: 0.1,
		xHeight: 0.72,
		width: 0.45,
		category: 'Display',
	},
	'Lily Script One': {
		name: 'Lily Script One',
		contrast: 0.44,
		weight: 0.08,
		xHeight: 0.65,
		width: 0.54,
		category: 'Display',
	},
	Limelight: {
		name: 'Limelight',
		contrast: 0.87,
		weight: 0.09,
		xHeight: 0.76,
		width: 0.53,
		category: 'Display',
	},
	'Linden Hill': {
		name: 'Linden Hill',
		contrast: 0.59,
		weight: 0.05,
		xHeight: 0.59,
		width: 0.4,
		category: 'Serif',
	},
	Lobster: {
		name: 'Lobster',
		contrast: 0.6,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.52,
		category: 'Display',
	},
	'Lobster Two': {
		name: 'Lobster Two',
		contrast: 0.56,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.45,
		category: 'Display',
	},
	'Londrina Outline': {
		name: 'Londrina Outline',
		contrast: 0.56,
		weight: 0.03,
		xHeight: 0.71,
		width: 0.41,
		category: 'Display',
	},
	'Londrina Shadow': {
		name: 'Londrina Shadow',
		contrast: 0.79,
		weight: 0.04,
		xHeight: 0.71,
		width: 0.43,
		category: 'Display',
	},
	'Londrina Sketch': {
		name: 'Londrina Sketch',
		contrast: 0.79,
		weight: 0.04,
		xHeight: 0.72,
		width: 0.4,
		category: 'Display',
	},
	'Londrina Solid': {
		name: 'Londrina Solid',
		contrast: 0.22,
		weight: 0.09,
		xHeight: 0.71,
		width: 0.4,
		category: 'Display',
	},
	Lora: {
		name: 'Lora',
		contrast: 0.63,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.48,
		category: 'Serif',
	},
	'Love Ya Like A Sister': {
		name: 'Love Ya Like A Sister',
		contrast: 0.39,
		weight: 0.06,
		xHeight: 0.82,
		width: 0.5,
		category: 'Display',
	},
	'Loved by the King': {
		name: 'Loved by the King',
		contrast: 0.32,
		weight: 0.04,
		xHeight: 0.79,
		width: 0.28,
		category: 'Handwriting',
	},
	'Lovers Quarrel': {
		name: 'Lovers Quarrel',
		contrast: 0.69,
		weight: 0.02,
		xHeight: 0.43,
		width: 0.33,
		category: 'Handwriting',
	},
	'Luckiest Guy': {
		name: 'Luckiest Guy',
		contrast: 0.1,
		weight: 0.16,
		xHeight: 0.99,
		width: 0.58,
		category: 'Display',
	},
	Lusitana: {
		name: 'Lusitana',
		contrast: 0.56,
		weight: 0.05,
		xHeight: 0.61,
		width: 0.46,
		category: 'Serif',
	},
	Lustria: {
		name: 'Lustria',
		contrast: 0.57,
		weight: 0.06,
		xHeight: 0.72,
		width: 0.5,
		category: 'Serif',
	},
	Macondo: {
		name: 'Macondo',
		contrast: 0.62,
		weight: 0.05,
		xHeight: 0.66,
		width: 0.4,
		category: 'Display',
	},
	'Macondo Swash Caps': {
		name: 'Macondo Swash Caps',
		contrast: 0.62,
		weight: 0.05,
		xHeight: 0.66,
		width: 0.4,
		category: 'Display',
	},
	Mada: {
		name: 'Mada',
		contrast: 0.2,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.39,
		category: 'Sans-Serif',
	},
	Magra: {
		name: 'Magra',
		contrast: 0.19,
		weight: 0.07,
		xHeight: 0.76,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Maiden Orange': {
		name: 'Maiden Orange',
		contrast: 0.07,
		weight: 0.05,
		xHeight: 0.72,
		width: 0.35,
		category: 'Display',
	},
	Maitree: {
		name: 'Maitree',
		contrast: 0.36,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.47,
		category: 'Serif',
	},
	Mako: {
		name: 'Mako',
		contrast: 0.25,
		weight: 0.07,
		xHeight: 0.72,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Mallanna: {
		name: 'Mallanna',
		contrast: 0.29,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.37,
		category: 'Sans-Serif',
	},
	Mandali: {
		name: 'Mandali',
		contrast: 0.3,
		weight: 0.06,
		xHeight: 0.69,
		width: 0.41,
		category: 'Sans-Serif',
	},
	Manuale: {
		name: 'Manuale',
		contrast: 0.49,
		weight: 0.06,
		xHeight: 0.8,
		width: 0.47,
		category: 'Serif',
	},
	Marcellus: {
		name: 'Marcellus',
		contrast: 0.65,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.44,
		category: 'Serif',
	},
	'Marcellus SC': {
		name: 'Marcellus SC',
		contrast: 0.66,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.45,
		category: 'Serif',
	},
	'Marck Script': {
		name: 'Marck Script',
		contrast: 0,
		weight: 0.03,
		xHeight: 0.58,
		width: 0.43,
		category: 'Handwriting',
	},
	Margarine: {
		name: 'Margarine',
		contrast: 0.19,
		weight: 0.09,
		xHeight: 0.84,
		width: 0.46,
		category: 'Display',
	},
	'Marko One': {
		name: 'Marko One',
		contrast: 0.61,
		weight: 0.09,
		xHeight: 0.73,
		width: 0.55,
		category: 'Serif',
	},
	Marmelad: {
		name: 'Marmelad',
		contrast: 0.63,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.43,
		category: 'Sans-Serif',
	},
	Martel: {
		name: 'Martel',
		contrast: 0.5,
		weight: 0.07,
		xHeight: 0.72,
		width: 0.51,
		category: 'Serif',
	},
	'Martel Sans': {
		name: 'Martel Sans',
		contrast: 0.24,
		weight: 0.07,
		xHeight: 0.79,
		width: 0.44,
		category: 'Sans-Serif',
	},
	Marvel: {
		name: 'Marvel',
		contrast: 0.11,
		weight: 0.04,
		xHeight: 0.71,
		width: 0.31,
		category: 'Sans-Serif',
	},
	Mate: {
		name: 'Mate',
		contrast: 0.48,
		weight: 0.05,
		xHeight: 0.65,
		width: 0.44,
		category: 'Serif',
	},
	'Mate SC': {
		name: 'Mate SC',
		contrast: 0.46,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.45,
		category: 'Serif',
	},
	'Maven Pro': {
		name: 'Maven Pro',
		contrast: 0.09,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.4,
		category: 'Sans-Serif',
	},
	McLaren: {
		name: 'McLaren',
		contrast: 0.18,
		weight: 0.08,
		xHeight: 0.72,
		width: 0.5,
		category: 'Display',
	},
	Meddon: {
		name: 'Meddon',
		contrast: 0.66,
		weight: 0.06,
		xHeight: 0.38,
		width: 0.8,
		category: 'Handwriting',
	},
	MedievalSharp: {
		name: 'MedievalSharp',
		contrast: 0.93,
		weight: 0.05,
		xHeight: 0.75,
		width: 0.44,
		category: 'Display',
	},
	'Medula One': {
		name: 'Medula One',
		contrast: 0.18,
		weight: 0.05,
		xHeight: 0.76,
		width: 0.27,
		category: 'Display',
	},
	'Meera Inimai': {
		name: 'Meera Inimai',
		contrast: 0.1,
		weight: 0.07,
		xHeight: 0.72,
		width: 0.41,
		category: 'Sans-Serif',
	},
	Megrim: {
		name: 'Megrim',
		contrast: 0.06,
		weight: 0.04,
		xHeight: 0.83,
		width: 0.41,
		category: 'Display',
	},
	'Meie Script': {
		name: 'Meie Script',
		contrast: 0.75,
		weight: 0.03,
		xHeight: 0.41,
		width: 0.57,
		category: 'Handwriting',
	},
	Merienda: {
		name: 'Merienda',
		contrast: 0.71,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.47,
		category: 'Handwriting',
	},
	'Merienda One': {
		name: 'Merienda One',
		contrast: 0.75,
		weight: 0.1,
		xHeight: 0.74,
		width: 0.49,
		category: 'Handwriting',
	},
	Merriweather: {
		name: 'Merriweather',
		contrast: 0.51,
		weight: 0.08,
		xHeight: 0.75,
		width: 0.52,
		category: 'Serif',
	},
	'Merriweather Sans': {
		name: 'Merriweather Sans',
		contrast: 0.3,
		weight: 0.08,
		xHeight: 0.75,
		width: 0.45,
		category: 'Sans-Serif',
	},
	'Metal Mania': {
		name: 'Metal Mania',
		contrast: 0.26,
		weight: 0.08,
		xHeight: 0.82,
		width: 0.46,
		category: 'Display',
	},
	Metamorphous: {
		name: 'Metamorphous',
		contrast: 0.53,
		weight: 0.08,
		xHeight: 0.79,
		width: 0.55,
		category: 'Display',
	},
	Metrophobic: {
		name: 'Metrophobic',
		contrast: 0.21,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Michroma: {
		name: 'Michroma',
		contrast: 0.24,
		weight: 0.1,
		xHeight: 0.75,
		width: 0.65,
		category: 'Sans-Serif',
	},
	Milonga: {
		name: 'Milonga',
		contrast: 0.52,
		weight: 0.06,
		xHeight: 0.57,
		width: 0.49,
		category: 'Display',
	},
	Miltonian: {
		name: 'Miltonian',
		contrast: 0.74,
		weight: 0.07,
		xHeight: 0.64,
		width: 0.56,
		category: 'Display',
	},
	'Miltonian Tattoo': {
		name: 'Miltonian Tattoo',
		contrast: 0.74,
		weight: 0.09,
		xHeight: 0.64,
		width: 0.56,
		category: 'Display',
	},
	Miniver: {
		name: 'Miniver',
		contrast: 0.46,
		weight: 0.08,
		xHeight: 0.66,
		width: 0.56,
		category: 'Display',
	},
	'Miriam Libre': {
		name: 'Miriam Libre',
		contrast: 0.06,
		weight: 0.07,
		xHeight: 0.81,
		width: 0.44,
		category: 'Sans-Serif',
	},
	Mirza: {
		name: 'Mirza',
		contrast: 0.58,
		weight: 0.05,
		xHeight: 0.77,
		width: 0.36,
		category: 'Display',
	},
	'Miss Fajardose': {
		name: 'Miss Fajardose',
		contrast: 0.89,
		weight: 0.01,
		xHeight: 0.24,
		width: 0.44,
		category: 'Handwriting',
	},
	Mitr: {
		name: 'Mitr',
		contrast: 0.26,
		weight: 0.09,
		xHeight: 0.71,
		width: 0.48,
		category: 'Sans-Serif',
	},
	Modak: {
		name: 'Modak',
		contrast: 0.25,
		weight: 0.14,
		xHeight: 0.86,
		width: 0.53,
		category: 'Display',
	},
	'Modern Antiqua': {
		name: 'Modern Antiqua',
		contrast: 0.56,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.53,
		category: 'Display',
	},
	Mogra: {
		name: 'Mogra',
		contrast: 0.53,
		weight: 0.09,
		xHeight: 0.81,
		width: 0.54,
		category: 'Display',
	},
	Molengo: {
		name: 'Molengo',
		contrast: 0.21,
		weight: 0.06,
		xHeight: 0.67,
		width: 0.39,
		category: 'Sans-Serif',
	},
	Monda: {
		name: 'Monda',
		contrast: 0.23,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.41,
		category: 'Sans-Serif',
	},
	Monofett: {
		name: 'Monofett',
		contrast: 0.81,
		weight: 0.17,
		xHeight: 1,
		width: 0.58,
		category: 'Display',
	},
	Monoton: {
		name: 'Monoton',
		contrast: 0.03,
		weight: 0.18,
		xHeight: 0.87,
		width: 0.69,
		category: 'Display',
	},
	'Monsieur La Doulaise': {
		name: 'Monsieur La Doulaise',
		contrast: 0.9,
		weight: 0.02,
		xHeight: 0.17,
		width: 0.53,
		category: 'Handwriting',
	},
	Montaga: {
		name: 'Montaga',
		contrast: 0.56,
		weight: 0.05,
		xHeight: 0.67,
		width: 0.46,
		category: 'Serif',
	},
	Montez: {
		name: 'Montez',
		contrast: 0,
		weight: 0.03,
		xHeight: 0.43,
		width: 0.38,
		category: 'Handwriting',
	},
	Montserrat: {
		name: 'Montserrat',
		contrast: 0.16,
		weight: 0.08,
		xHeight: 0.76,
		width: 0.47,
		category: 'Sans-Serif',
	},
	'M PLUS 1p': {
		name: 'M PLUS 1p',
		contrast: 0.16,
		weight: 0.08,
		xHeight: 0.76,
		width: 0.47,
		category: 'Sans-Serif',
	},
	'M PLUS Rounded 1c': {
		name: 'M PLUS Rounded 1c',
		contrast: 0.16,
		weight: 0.08,
		xHeight: 0.76,
		width: 0.47,
		category: 'Sans-Serif',
	},
	'Montserrat Alternates': {
		name: 'Montserrat Alternates',
		contrast: 0.16,
		weight: 0.08,
		xHeight: 0.76,
		width: 0.46,
		category: 'Sans-Serif',
	},
	'Montserrat Subrayada': {
		name: 'Montserrat Subrayada',
		contrast: 0.17,
		weight: 0.15,
		xHeight: 1,
		width: 0.73,
		category: 'Sans-Serif',
	},
	'Mountains of Christmas': {
		name: 'Mountains of Christmas',
		contrast: 0.58,
		weight: 0.03,
		xHeight: 0.73,
		width: 0.41,
		category: 'Display',
	},
	'Mouse Memoirs': {
		name: 'Mouse Memoirs',
		contrast: 0.18,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.3,
		category: 'Sans-Serif',
	},
	'Mr Bedfort': {
		name: 'Mr Bedfort',
		contrast: 0.75,
		weight: 0.03,
		xHeight: 0.32,
		width: 0.49,
		category: 'Handwriting',
	},
	'Mr Dafoe': {
		name: 'Mr Dafoe',
		contrast: 0.67,
		weight: 0.05,
		xHeight: 0.49,
		width: 0.63,
		category: 'Handwriting',
	},
	'Mr De Haviland': {
		name: 'Mr De Haviland',
		contrast: 0.79,
		weight: 0.02,
		xHeight: 0.3,
		width: 0.39,
		category: 'Handwriting',
	},
	'Mrs Saint Delafield': {
		name: 'Mrs Saint Delafield',
		contrast: 0.62,
		weight: 0.02,
		xHeight: 0.3,
		width: 0.48,
		category: 'Handwriting',
	},
	'Mrs Sheppards': {
		name: 'Mrs Sheppards',
		contrast: 0.67,
		weight: 0.05,
		xHeight: 0.52,
		width: 0.54,
		category: 'Handwriting',
	},
	Mukta: {
		name: 'Mukta',
		contrast: 0.26,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.4,
		category: 'Sans-Serif',
	},
	'Mukta Mahee': {
		name: 'Mukta Mahee',
		contrast: 0.26,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.4,
		category: 'Sans-Serif',
	},
	'Mukta Malar': {
		name: 'Mukta Malar',
		contrast: 0.26,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.4,
		category: 'Sans-Serif',
	},
	'Mukta Vaani': {
		name: 'Mukta Vaani',
		contrast: 0.26,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Muli: {
		name: 'Muli',
		contrast: 0.2,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.43,
		category: 'Sans-Serif',
	},
	'Mystery Quest': {
		name: 'Mystery Quest',
		contrast: 0.58,
		weight: 0.06,
		xHeight: 0.78,
		width: 0.48,
		category: 'Display',
	},
	NTR: {
		name: 'NTR',
		contrast: 0.1,
		weight: 0.05,
		xHeight: 0.73,
		width: 0.35,
		category: 'Sans-Serif',
	},
	Neucha: {
		name: 'Neucha',
		contrast: 0.33,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.35,
		category: 'Handwriting',
	},
	Neuton: {
		name: 'Neuton',
		contrast: 0.51,
		weight: 0.05,
		xHeight: 0.71,
		width: 0.42,
		category: 'Serif',
	},
	'New Rocker': {
		name: 'New Rocker',
		contrast: 0.69,
		weight: 0.08,
		xHeight: 0.65,
		width: 0.45,
		category: 'Display',
	},
	'News Cycle': {
		name: 'News Cycle',
		contrast: 0.15,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.37,
		category: 'Sans-Serif',
	},
	Niconne: {
		name: 'Niconne',
		contrast: 0.69,
		weight: 0.03,
		xHeight: 0.42,
		width: 0.42,
		category: 'Handwriting',
	},
	'Nixie One': {
		name: 'Nixie One',
		contrast: 0.05,
		weight: 0.04,
		xHeight: 0.71,
		width: 0.53,
		category: 'Display',
	},
	Nobile: {
		name: 'Nobile',
		contrast: 0.27,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.46,
		category: 'Sans-Serif',
	},
	Norican: {
		name: 'Norican',
		contrast: 0.25,
		weight: 0.06,
		xHeight: 0.55,
		width: 0.49,
		category: 'Handwriting',
	},
	Nosifer: {
		name: 'Nosifer',
		contrast: 0.42,
		weight: 0.25,
		xHeight: 1.02,
		width: 0.82,
		category: 'Display',
	},
	'Nothing You Could Do': {
		name: 'Nothing You Could Do',
		contrast: 0.55,
		weight: 0.03,
		xHeight: 0.52,
		width: 0.51,
		category: 'Handwriting',
	},
	'Noticia Text': {
		name: 'Noticia Text',
		contrast: 0.47,
		weight: 0.06,
		xHeight: 0.8,
		width: 0.48,
		category: 'Serif',
	},
	'Noto Sans': {
		name: 'Noto Sans',
		contrast: 0.2,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.44,
		category: 'Sans-Serif',
	},
	'Noto Serif': {
		name: 'Noto Serif',
		contrast: 0.47,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.5,
		category: 'Serif',
	},
	'Noto Serif JP': {
		name: 'Noto Serif JP',
		contrast: 0.47,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.5,
		category: 'Serif',
	},
	'Nova Cut': {
		name: 'Nova Cut',
		contrast: 0.62,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.42,
		category: 'Display',
	},
	'Nova Flat': {
		name: 'Nova Flat',
		contrast: 0.13,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.4,
		category: 'Display',
	},
	'Nova Mono': {
		name: 'Nova Mono',
		contrast: 0.04,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.4,
		category: 'Monospace',
	},
	'Nova Oval': {
		name: 'Nova Oval',
		contrast: 0.15,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.39,
		category: 'Display',
	},
	'Nova Round': {
		name: 'Nova Round',
		contrast: 0.11,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.4,
		category: 'Display',
	},
	'Nova Script': {
		name: 'Nova Script',
		contrast: 0.22,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.46,
		category: 'Display',
	},
	'Nova Slim': {
		name: 'Nova Slim',
		contrast: 0.45,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.4,
		category: 'Display',
	},
	'Nova Square': {
		name: 'Nova Square',
		contrast: 0.12,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.4,
		category: 'Display',
	},
	Numans: {
		name: 'Numans',
		contrast: 0.22,
		weight: 0.08,
		xHeight: 0.76,
		width: 0.47,
		category: 'Sans-Serif',
	},
	Nunito: {
		name: 'Nunito',
		contrast: 0.19,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.42,
		category: 'Sans-Serif',
	},
	'Nunito Sans': {
		name: 'Nunito Sans',
		contrast: 0.19,
		weight: 0.06,
		xHeight: 0.69,
		width: 0.42,
		category: 'Sans-Serif',
	},
	Offside: {
		name: 'Offside',
		contrast: 0.13,
		weight: 0.08,
		xHeight: 0.81,
		width: 0.42,
		category: 'Display',
	},
	'Old Standard TT': {
		name: 'Old Standard TT',
		contrast: 0.73,
		weight: 0.05,
		xHeight: 0.64,
		width: 0.43,
		category: 'Serif',
	},
	Oldenburg: {
		name: 'Oldenburg',
		contrast: 0.13,
		weight: 0.07,
		xHeight: 0.66,
		width: 0.52,
		category: 'Display',
	},
	'Oleo Script': {
		name: 'Oleo Script',
		contrast: 0.69,
		weight: 0.07,
		xHeight: 0.62,
		width: 0.43,
		category: 'Display',
	},
	'Oleo Script Swash Caps': {
		name: 'Oleo Script Swash Caps',
		contrast: 0.69,
		weight: 0.07,
		xHeight: 0.62,
		width: 0.44,
		category: 'Display',
	},
	'Open Sans': {
		name: 'Open Sans',
		contrast: 0.2,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.43,
		category: 'Sans-Serif',
	},
	Oranienbaum: {
		name: 'Oranienbaum',
		contrast: 0.83,
		weight: 0.05,
		xHeight: 0.66,
		width: 0.41,
		category: 'Serif',
	},
	Orbitron: {
		name: 'Orbitron',
		contrast: 0.01,
		weight: 0.09,
		xHeight: 0.81,
		width: 0.55,
		category: 'Sans-Serif',
	},
	Oregano: {
		name: 'Oregano',
		contrast: 0.58,
		weight: 0.04,
		xHeight: 0.58,
		width: 0.35,
		category: 'Display',
	},
	Orienta: {
		name: 'Orienta',
		contrast: 0.22,
		weight: 0.08,
		xHeight: 0.76,
		width: 0.43,
		category: 'Sans-Serif',
	},
	'Original Surfer': {
		name: 'Original Surfer',
		contrast: 0.67,
		weight: 0.07,
		xHeight: 0.66,
		width: 0.47,
		category: 'Display',
	},
	Oswald: {
		name: 'Oswald',
		contrast: 0.26,
		weight: 0.08,
		xHeight: 0.71,
		width: 0.34,
		category: 'Sans-Serif',
	},
	'Over the Rainbow': {
		name: 'Over the Rainbow',
		contrast: 0.45,
		weight: 0.05,
		xHeight: 0.92,
		width: 0.46,
		category: 'Handwriting',
	},
	Overlock: {
		name: 'Overlock',
		contrast: 0.22,
		weight: 0.05,
		xHeight: 0.71,
		width: 0.38,
		category: 'Display',
	},
	'Overlock SC': {
		name: 'Overlock SC',
		contrast: 0.34,
		weight: 0.06,
		xHeight: 0.79,
		width: 0.4,
		category: 'Display',
	},
	Overpass: {
		name: 'Overpass',
		contrast: 0.09,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.4,
		category: 'Sans-Serif',
	},
	'Overpass Mono': {
		name: 'Overpass Mono',
		contrast: 0.1,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.43,
		category: 'Monospace',
	},
	Ovo: {
		name: 'Ovo',
		contrast: 0.6,
		weight: 0.05,
		xHeight: 0.69,
		width: 0.46,
		category: 'Serif',
	},
	Oxygen: {
		name: 'Oxygen',
		contrast: 0.2,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.43,
		category: 'Sans-Serif',
	},
	'Oxygen Mono': {
		name: 'Oxygen Mono',
		contrast: 0.24,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.44,
		category: 'Monospace',
	},
	'PT Mono': {
		name: 'PT Mono',
		contrast: 0.2,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.48,
		category: 'Monospace',
	},
	'PT Sans': {
		name: 'PT Sans',
		contrast: 0.16,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'PT Sans Caption': {
		name: 'PT Sans Caption',
		contrast: 0.21,
		weight: 0.08,
		xHeight: 0.75,
		width: 0.45,
		category: 'Sans-Serif',
	},
	'PT Sans Narrow': {
		name: 'PT Sans Narrow',
		contrast: 0.14,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.32,
		category: 'Sans-Serif',
	},
	'PT Serif': {
		name: 'PT Serif',
		contrast: 0.55,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.47,
		category: 'Serif',
	},
	'PT Serif Caption': {
		name: 'PT Serif Caption',
		contrast: 0.59,
		weight: 0.08,
		xHeight: 0.75,
		width: 0.53,
		category: 'Serif',
	},
	Pacifico: {
		name: 'Pacifico',
		contrast: 0.4,
		weight: 0.08,
		xHeight: 0.54,
		width: 0.57,
		category: 'Handwriting',
	},
	Padauk: {
		name: 'Padauk',
		contrast: 0.34,
		weight: 0.05,
		xHeight: 0.69,
		width: 0.37,
		category: 'Sans-Serif',
	},
	Palanquin: {
		name: 'Palanquin',
		contrast: 0.16,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Palanquin Dark': {
		name: 'Palanquin Dark',
		contrast: 0.17,
		weight: 0.09,
		xHeight: 0.71,
		width: 0.44,
		category: 'Sans-Serif',
	},
	Pangolin: {
		name: 'Pangolin',
		contrast: 0.3,
		weight: 0.07,
		xHeight: 0.77,
		width: 0.43,
		category: 'Handwriting',
	},
	Paprika: {
		name: 'Paprika',
		contrast: 0.48,
		weight: 0.09,
		xHeight: 0.75,
		width: 0.51,
		category: 'Display',
	},
	Parisienne: {
		name: 'Parisienne',
		contrast: 0.08,
		weight: 0.03,
		xHeight: 0.47,
		width: 0.49,
		category: 'Handwriting',
	},
	'Passero One': {
		name: 'Passero One',
		contrast: 0,
		weight: 0.08,
		xHeight: 0.83,
		width: 0.36,
		category: 'Display',
	},
	'Passion One': {
		name: 'Passion One',
		contrast: 0.25,
		weight: 0.09,
		xHeight: 0.77,
		width: 0.39,
		category: 'Display',
	},
	'Pathway Gothic One': {
		name: 'Pathway Gothic One',
		contrast: 0.19,
		weight: 0.06,
		xHeight: 0.77,
		width: 0.27,
		category: 'Sans-Serif',
	},
	'Patrick Hand': {
		name: 'Patrick Hand',
		contrast: 0.2,
		weight: 0.05,
		xHeight: 0.71,
		width: 0.35,
		category: 'Handwriting',
	},
	'Patrick Hand SC': {
		name: 'Patrick Hand SC',
		contrast: 0.09,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.33,
		category: 'Handwriting',
	},
	Pattaya: {
		name: 'Pattaya',
		contrast: 0.6,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.52,
		category: 'Sans-Serif',
	},
	'Patua One': {
		name: 'Patua One',
		contrast: 0.3,
		weight: 0.09,
		xHeight: 0.72,
		width: 0.49,
		category: 'Display',
	},
	Pavanam: {
		name: 'Pavanam',
		contrast: 0.27,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.37,
		category: 'Sans-Serif',
	},
	'Paytone One': {
		name: 'Paytone One',
		contrast: 0.24,
		weight: 0.12,
		xHeight: 0.73,
		width: 0.54,
		category: 'Sans-Serif',
	},
	Peddana: {
		name: 'Peddana',
		contrast: 0.59,
		weight: 0.03,
		xHeight: 0.72,
		width: 0.3,
		category: 'Serif',
	},
	Peralta: {
		name: 'Peralta',
		contrast: 0.58,
		weight: 0.1,
		xHeight: 0.7,
		width: 0.64,
		category: 'Display',
	},
	'Permanent Marker': {
		name: 'Permanent Marker',
		contrast: 0.3,
		weight: 0.11,
		xHeight: 0.8,
		width: 0.58,
		category: 'Handwriting',
	},
	'Petit Formal Script': {
		name: 'Petit Formal Script',
		contrast: 0.78,
		weight: 0.05,
		xHeight: 0.75,
		width: 0.65,
		category: 'Handwriting',
	},
	Petrona: {
		name: 'Petrona',
		contrast: 0.56,
		weight: 0.06,
		xHeight: 0.69,
		width: 0.46,
		category: 'Serif',
	},
	Philosopher: {
		name: 'Philosopher',
		contrast: 0.58,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.44,
		category: 'Sans-Serif',
	},
	Piedra: {
		name: 'Piedra',
		contrast: 0,
		weight: 0.09,
		xHeight: 0.72,
		width: 0.44,
		category: 'Display',
	},
	'Pinyon Script': {
		name: 'Pinyon Script',
		contrast: 0.8,
		weight: 0.03,
		xHeight: 0.49,
		width: 0.58,
		category: 'Handwriting',
	},
	'Pirata One': {
		name: 'Pirata One',
		contrast: 0.51,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.37,
		category: 'Display',
	},
	Plaster: {
		name: 'Plaster',
		contrast: 0.78,
		weight: 0.13,
		xHeight: 0.75,
		width: 0.62,
		category: 'Display',
	},
	Play: {
		name: 'Play',
		contrast: 0.36,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.41,
		category: 'Sans-Serif',
	},
	Playball: {
		name: 'Playball',
		contrast: 0.68,
		weight: 0.05,
		xHeight: 0.57,
		width: 0.52,
		category: 'Display',
	},
	'Playfair Display': {
		name: 'Playfair Display',
		contrast: 0.79,
		weight: 0.06,
		xHeight: 0.73,
		width: 0.48,
		category: 'Serif',
	},
	'Playfair Display SC': {
		name: 'Playfair Display SC',
		contrast: 0.82,
		weight: 0.08,
		xHeight: 0.86,
		width: 0.56,
		category: 'Serif',
	},
	Podkova: {
		name: 'Podkova',
		contrast: 0.2,
		weight: 0.06,
		xHeight: 0.73,
		width: 0.48,
		category: 'Serif',
	},
	'Poiret One': {
		name: 'Poiret One',
		contrast: 0.15,
		weight: 0.03,
		xHeight: 0.6,
		width: 0.38,
		category: 'Display',
	},
	'Poller One': {
		name: 'Poller One',
		contrast: 0.63,
		weight: 0.14,
		xHeight: 0.67,
		width: 0.63,
		category: 'Display',
	},
	Poly: {
		name: 'Poly',
		contrast: 0.46,
		weight: 0.06,
		xHeight: 0.68,
		width: 0.47,
		category: 'Serif',
	},
	Pompiere: {
		name: 'Pompiere',
		contrast: 0.15,
		weight: 0.03,
		xHeight: 0.47,
		width: 0.26,
		category: 'Display',
	},
	'Pontano Sans': {
		name: 'Pontano Sans',
		contrast: 0.29,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.39,
		category: 'Sans-Serif',
	},
	Poppins: {
		name: 'Poppins',
		contrast: 0.14,
		weight: 0.08,
		xHeight: 0.78,
		width: 0.48,
		category: 'Sans-Serif',
	},
	'Port Lligat Sans': {
		name: 'Port Lligat Sans',
		contrast: 0.33,
		weight: 0.06,
		xHeight: 0.72,
		width: 0.36,
		category: 'Sans-Serif',
	},
	'Port Lligat Slab': {
		name: 'Port Lligat Slab',
		contrast: 0.33,
		weight: 0.06,
		xHeight: 0.73,
		width: 0.39,
		category: 'Serif',
	},
	'Pragati Narrow': {
		name: 'Pragati Narrow',
		contrast: 0.15,
		weight: 0.06,
		xHeight: 0.77,
		width: 0.31,
		category: 'Sans-Serif',
	},
	Prata: {
		name: 'Prata',
		contrast: 0.8,
		weight: 0.06,
		xHeight: 0.65,
		width: 0.52,
		category: 'Serif',
	},
	'Press Start 2P': {
		name: 'Press Start 2P',
		contrast: 0.43,
		weight: 0.18,
		xHeight: 0.75,
		width: 0.83,
		category: 'Display',
	},
	Pridi: {
		name: 'Pridi',
		contrast: 0.29,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.49,
		category: 'Serif',
	},
	'Princess Sofia': {
		name: 'Princess Sofia',
		contrast: 0.67,
		weight: 0.03,
		xHeight: 0.51,
		width: 0.46,
		category: 'Handwriting',
	},
	Prociono: {
		name: 'Prociono',
		contrast: 0.49,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.46,
		category: 'Serif',
	},
	Prompt: {
		name: 'Prompt',
		contrast: 0.23,
		weight: 0.08,
		xHeight: 0.69,
		width: 0.46,
		category: 'Sans-Serif',
	},
	'Prosto One': {
		name: 'Prosto One',
		contrast: 0.57,
		weight: 0.1,
		xHeight: 0.74,
		width: 0.54,
		category: 'Display',
	},
	'Proza Libre': {
		name: 'Proza Libre',
		contrast: 0.31,
		weight: 0.07,
		xHeight: 0.72,
		width: 0.45,
		category: 'Sans-Serif',
	},
	Puritan: {
		name: 'Puritan',
		contrast: 0.25,
		weight: 0.06,
		xHeight: 0.79,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Purple Purse': {
		name: 'Purple Purse',
		contrast: 0.88,
		weight: 0.07,
		xHeight: 0.6,
		width: 0.5,
		category: 'Display',
	},
	Quando: {
		name: 'Quando',
		contrast: 0.38,
		weight: 0.09,
		xHeight: 0.79,
		width: 0.56,
		category: 'Serif',
	},
	Quantico: {
		name: 'Quantico',
		contrast: 0.32,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Quattrocento: {
		name: 'Quattrocento',
		contrast: 0.49,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.47,
		category: 'Serif',
	},
	'Quattrocento Sans': {
		name: 'Quattrocento Sans',
		contrast: 0.29,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Questrial: {
		name: 'Questrial',
		contrast: 0.1,
		weight: 0.06,
		xHeight: 0.76,
		width: 0.43,
		category: 'Sans-Serif',
	},
	Quicksand: {
		name: 'Quicksand',
		contrast: 0.07,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.42,
		category: 'Sans-Serif',
	},
	Quintessential: {
		name: 'Quintessential',
		contrast: 0.84,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.47,
		category: 'Handwriting',
	},
	Qwigley: {
		name: 'Qwigley',
		contrast: 0.78,
		weight: 0.03,
		xHeight: 0.63,
		width: 0.36,
		category: 'Handwriting',
	},
	'Racing Sans One': {
		name: 'Racing Sans One',
		contrast: 0.67,
		weight: 0.1,
		xHeight: 0.68,
		width: 0.54,
		category: 'Display',
	},
	Radley: {
		name: 'Radley',
		contrast: 0.67,
		weight: 0.06,
		xHeight: 0.69,
		width: 0.47,
		category: 'Serif',
	},
	Rajdhani: {
		name: 'Rajdhani',
		contrast: 0.11,
		weight: 0.05,
		xHeight: 0.79,
		width: 0.34,
		category: 'Sans-Serif',
	},
	Rakkas: {
		name: 'Rakkas',
		contrast: 0.59,
		weight: 0.08,
		xHeight: 0.67,
		width: 0.46,
		category: 'Display',
	},
	Raleway: {
		name: 'Raleway',
		contrast: 0.13,
		weight: 0.06,
		xHeight: 0.73,
		width: 0.44,
		category: 'Sans-Serif',
	},
	'Raleway Dots': {
		name: 'Raleway Dots',
		contrast: 0.4,
		weight: 0.02,
		xHeight: 0.74,
		width: 0.41,
		category: 'Display',
	},
	Ramabhadra: {
		name: 'Ramabhadra',
		contrast: 0.35,
		weight: 0.1,
		xHeight: 0.77,
		width: 0.46,
		category: 'Sans-Serif',
	},
	Ramaraja: {
		name: 'Ramaraja',
		contrast: 0.71,
		weight: 0.05,
		xHeight: 0.67,
		width: 0.42,
		category: 'Serif',
	},
	Rambla: {
		name: 'Rambla',
		contrast: 0.2,
		weight: 0.07,
		xHeight: 0.76,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Rammetto One': {
		name: 'Rammetto One',
		contrast: 0.37,
		weight: 0.18,
		xHeight: 0.78,
		width: 0.65,
		category: 'Display',
	},
	Ranchers: {
		name: 'Ranchers',
		contrast: 0.34,
		weight: 0.12,
		xHeight: 0.73,
		width: 0.42,
		category: 'Display',
	},
	Rancho: {
		name: 'Rancho',
		contrast: 0.52,
		weight: 0.04,
		xHeight: 0.58,
		width: 0.33,
		category: 'Handwriting',
	},
	Ranga: {
		name: 'Ranga',
		contrast: 0.18,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.36,
		category: 'Display',
	},
	Rasa: {
		name: 'Rasa',
		contrast: 0.52,
		weight: 0.05,
		xHeight: 0.73,
		width: 0.41,
		category: 'Serif',
	},
	Rationale: {
		name: 'Rationale',
		contrast: 0.3,
		weight: 0.06,
		xHeight: 0.73,
		width: 0.31,
		category: 'Sans-Serif',
	},
	'Ravi Prakash': {
		name: 'Ravi Prakash',
		contrast: 0.56,
		weight: 0.05,
		xHeight: 0.77,
		width: 0.38,
		category: 'Display',
	},
	Redressed: {
		name: 'Redressed',
		contrast: 0,
		weight: 0.04,
		xHeight: 0.53,
		width: 0.36,
		category: 'Handwriting',
	},
	'Reem Kufi': {
		name: 'Reem Kufi',
		contrast: 0.05,
		weight: 0.06,
		xHeight: 0.58,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Reenie Beanie': {
		name: 'Reenie Beanie',
		contrast: 0.3,
		weight: 0.02,
		xHeight: 0.71,
		width: 0.29,
		category: 'Handwriting',
	},
	Revalia: {
		name: 'Revalia',
		contrast: 0.25,
		weight: 0.12,
		xHeight: 0.76,
		width: 0.59,
		category: 'Display',
	},
	'Rhodium Libre': {
		name: 'Rhodium Libre',
		contrast: 0.2,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.52,
		category: 'Serif',
	},
	Ribeye: {
		name: 'Ribeye',
		contrast: 0.73,
		weight: 0.08,
		xHeight: 0.72,
		width: 0.59,
		category: 'Display',
	},
	'Ribeye Marrow': {
		name: 'Ribeye Marrow',
		contrast: 0.85,
		weight: 0.07,
		xHeight: 0.72,
		width: 0.59,
		category: 'Display',
	},
	Righteous: {
		name: 'Righteous',
		contrast: 0.02,
		weight: 0.1,
		xHeight: 0.75,
		width: 0.47,
		category: 'Display',
	},
	Risque: {
		name: 'Risque',
		contrast: 0.55,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.49,
		category: 'Display',
	},
	Roboto: {
		name: 'Roboto',
		contrast: 0.19,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.41,
		category: 'Sans-Serif',
	},
	'Roboto Condensed': {
		name: 'Roboto Condensed',
		contrast: 0.15,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.35,
		category: 'Sans-Serif',
	},
	'Roboto Mono': {
		name: 'Roboto Mono',
		contrast: 0.19,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.45,
		category: 'Monospace',
	},
	'Roboto Slab': {
		name: 'Roboto Slab',
		contrast: 0.22,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.48,
		category: 'Serif',
	},
	Rochester: {
		name: 'Rochester',
		contrast: 0.56,
		weight: 0.05,
		xHeight: 0.47,
		width: 0.41,
		category: 'Handwriting',
	},
	'Rock Salt': {
		name: 'Rock Salt',
		contrast: 0.45,
		weight: 0.11,
		xHeight: 0.74,
		width: 0.77,
		category: 'Handwriting',
	},
	Rokkitt: {
		name: 'Rokkitt',
		contrast: 0.24,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.43,
		category: 'Serif',
	},
	Romanesco: {
		name: 'Romanesco',
		contrast: 0.68,
		weight: 0.04,
		xHeight: 0.57,
		width: 0.34,
		category: 'Handwriting',
	},
	'Ropa Sans': {
		name: 'Ropa Sans',
		contrast: 0.17,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.34,
		category: 'Sans-Serif',
	},
	Rosario: {
		name: 'Rosario',
		contrast: 0.28,
		weight: 0.06,
		xHeight: 0.68,
		width: 0.41,
		category: 'Sans-Serif',
	},
	Rosarivo: {
		name: 'Rosarivo',
		contrast: 0.67,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.5,
		category: 'Serif',
	},
	'Rouge Script': {
		name: 'Rouge Script',
		contrast: 0,
		weight: 0.02,
		xHeight: 0.5,
		width: 0.35,
		category: 'Handwriting',
	},
	'Rozha One': {
		name: 'Rozha One',
		contrast: 0.87,
		weight: 0.07,
		xHeight: 0.82,
		width: 0.5,
		category: 'Serif',
	},
	Rubik: {
		name: 'Rubik',
		contrast: 0.18,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.43,
		category: 'Sans-Serif',
	},
	'Rubik Mono One': {
		name: 'Rubik Mono One',
		contrast: 0.28,
		weight: 0.22,
		xHeight: 1,
		width: 0.73,
		category: 'Sans-Serif',
	},
	Ruda: {
		name: 'Ruda',
		contrast: 0.17,
		weight: 0.07,
		xHeight: 0.84,
		width: 0.39,
		category: 'Sans-Serif',
	},
	Rufina: {
		name: 'Rufina',
		contrast: 0.78,
		weight: 0.06,
		xHeight: 0.73,
		width: 0.5,
		category: 'Serif',
	},
	'Ruge Boogie': {
		name: 'Ruge Boogie',
		contrast: 0,
		weight: 0.03,
		xHeight: 0.62,
		width: 0.32,
		category: 'Handwriting',
	},
	Ruluko: {
		name: 'Ruluko',
		contrast: 0.3,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.37,
		category: 'Sans-Serif',
	},
	'Rum Raisin': {
		name: 'Rum Raisin',
		contrast: 0.35,
		weight: 0.08,
		xHeight: 0.76,
		width: 0.36,
		category: 'Sans-Serif',
	},
	'Ruslan Display': {
		name: 'Ruslan Display',
		contrast: 0.77,
		weight: 0.12,
		xHeight: 1.17,
		width: 0.61,
		category: 'Display',
	},
	'Russo One': {
		name: 'Russo One',
		contrast: 0.23,
		weight: 0.13,
		xHeight: 0.76,
		width: 0.52,
		category: 'Sans-Serif',
	},
	Ruthie: {
		name: 'Ruthie',
		contrast: 0.8,
		weight: 0.02,
		xHeight: 0.48,
		width: 0.41,
		category: 'Handwriting',
	},
	Rye: {
		name: 'Rye',
		contrast: 0.62,
		weight: 0.11,
		xHeight: 0.75,
		width: 0.6,
		category: 'Display',
	},
	Sacramento: {
		name: 'Sacramento',
		contrast: 0.06,
		weight: 0.02,
		xHeight: 0.41,
		width: 0.45,
		category: 'Handwriting',
	},
	Sahitya: {
		name: 'Sahitya',
		contrast: 0.51,
		weight: 0.05,
		xHeight: 0.71,
		width: 0.43,
		category: 'Serif',
	},
	Sail: {
		name: 'Sail',
		contrast: 0.84,
		weight: 0.05,
		xHeight: 0.58,
		width: 0.44,
		category: 'Display',
	},
	Saira: {
		name: 'Saira',
		contrast: 0.14,
		weight: 0.07,
		xHeight: 0.74,
		width: 0.41,
		category: 'Sans-Serif',
	},
	'Saira Condensed': {
		name: 'Saira Condensed',
		contrast: 0.17,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.31,
		category: 'Sans-Serif',
	},
	'Saira Extra Condensed': {
		name: 'Saira Extra Condensed',
		contrast: 0.15,
		weight: 0.05,
		xHeight: 0.74,
		width: 0.27,
		category: 'Sans-Serif',
	},
	'Saira Semi Condensed': {
		name: 'Saira Semi Condensed',
		contrast: 0.17,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.36,
		category: 'Sans-Serif',
	},
	Salsa: {
		name: 'Salsa',
		contrast: 0.45,
		weight: 0.08,
		xHeight: 0.71,
		width: 0.46,
		category: 'Display',
	},
	Sanchez: {
		name: 'Sanchez',
		contrast: 0.17,
		weight: 0.07,
		xHeight: 0.69,
		width: 0.5,
		category: 'Serif',
	},
	Sancreek: {
		name: 'Sancreek',
		contrast: 0.59,
		weight: 0.12,
		xHeight: 0.84,
		width: 0.51,
		category: 'Display',
	},
	Sansita: {
		name: 'Sansita',
		contrast: 0.5,
		weight: 0.08,
		xHeight: 0.77,
		width: 0.41,
		category: 'Sans-Serif',
	},
	Sarala: {
		name: 'Sarala',
		contrast: 0.14,
		weight: 0.08,
		xHeight: 0.73,
		width: 0.43,
		category: 'Sans-Serif',
	},
	Sarina: {
		name: 'Sarina',
		contrast: 0.44,
		weight: 0.13,
		xHeight: 0.67,
		width: 0.86,
		category: 'Display',
	},
	Sarpanch: {
		name: 'Sarpanch',
		contrast: 0.32,
		weight: 0.07,
		xHeight: 0.78,
		width: 0.43,
		category: 'Sans-Serif',
	},
	Satisfy: {
		name: 'Satisfy',
		contrast: 0.67,
		weight: 0.05,
		xHeight: 0.56,
		width: 0.52,
		category: 'Handwriting',
	},
	Scada: {
		name: 'Scada',
		contrast: 0.3,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Scheherazade: {
		name: 'Scheherazade',
		contrast: 0.68,
		weight: 0.03,
		xHeight: 0.68,
		width: 0.33,
		category: 'Serif',
	},
	Schoolbell: {
		name: 'Schoolbell',
		contrast: 0.04,
		weight: 0.06,
		xHeight: 0.64,
		width: 0.41,
		category: 'Handwriting',
	},
	'Scope One': {
		name: 'Scope One',
		contrast: 0.23,
		weight: 0.05,
		xHeight: 0.78,
		width: 0.47,
		category: 'Serif',
	},
	'Seaweed Script': {
		name: 'Seaweed Script',
		contrast: 0.47,
		weight: 0.04,
		xHeight: 0.48,
		width: 0.53,
		category: 'Display',
	},
	'Secular One': {
		name: 'Secular One',
		contrast: 0.15,
		weight: 0.1,
		xHeight: 0.79,
		width: 0.47,
		category: 'Sans-Serif',
	},
	'Sedgwick Ave': {
		name: 'Sedgwick Ave',
		contrast: 0.45,
		weight: 0.08,
		xHeight: 0.78,
		width: 0.47,
		category: 'Handwriting',
	},
	'Sedgwick Ave Display': {
		name: 'Sedgwick Ave Display',
		contrast: 0.55,
		weight: 0.08,
		xHeight: 0.78,
		width: 0.44,
		category: 'Handwriting',
	},
	Sevillana: {
		name: 'Sevillana',
		contrast: 0.79,
		weight: 0.04,
		xHeight: 0.51,
		width: 0.42,
		category: 'Display',
	},
	'Seymour One': {
		name: 'Seymour One',
		contrast: 0.51,
		weight: 0.16,
		xHeight: 0.72,
		width: 0.75,
		category: 'Sans-Serif',
	},
	'Shadows Into Light': {
		name: 'Shadows Into Light',
		contrast: 0.46,
		weight: 0.04,
		xHeight: 0.91,
		width: 0.34,
		category: 'Handwriting',
	},
	'Shadows Into Light Two': {
		name: 'Shadows Into Light Two',
		contrast: 0.25,
		weight: 0.05,
		xHeight: 0.72,
		width: 0.39,
		category: 'Handwriting',
	},
	Shanti: {
		name: 'Shanti',
		contrast: 0.22,
		weight: 0.07,
		xHeight: 0.72,
		width: 0.42,
		category: 'Sans-Serif',
	},
	Share: {
		name: 'Share',
		contrast: 0.2,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.34,
		category: 'Display',
	},
	'Share Tech': {
		name: 'Share Tech',
		contrast: 0.12,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.33,
		category: 'Sans-Serif',
	},
	'Share Tech Mono': {
		name: 'Share Tech Mono',
		contrast: 0.12,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.37,
		category: 'Monospace',
	},
	Shojumaru: {
		name: 'Shojumaru',
		contrast: 0.52,
		weight: 0.17,
		xHeight: 0.78,
		width: 0.73,
		category: 'Display',
	},
	'Short Stack': {
		name: 'Short Stack',
		contrast: 0.15,
		weight: 0.08,
		xHeight: 0.81,
		width: 0.52,
		category: 'Handwriting',
	},
	Shrikhand: {
		name: 'Shrikhand',
		contrast: 0.65,
		weight: 0.14,
		xHeight: 0.79,
		width: 0.68,
		category: 'Display',
	},
	'Sigmar One': {
		name: 'Sigmar One',
		contrast: 0.75,
		weight: 0.18,
		xHeight: 0.87,
		width: 0.62,
		category: 'Display',
	},
	Signika: {
		name: 'Signika',
		contrast: 0.26,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.4,
		category: 'Sans-Serif',
	},
	'Signika Negative': {
		name: 'Signika Negative',
		contrast: 0.26,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.4,
		category: 'Sans-Serif',
	},
	Simonetta: {
		name: 'Simonetta',
		contrast: 0.69,
		weight: 0.04,
		xHeight: 0.64,
		width: 0.45,
		category: 'Display',
	},
	Sintony: {
		name: 'Sintony',
		contrast: 0.19,
		weight: 0.08,
		xHeight: 0.78,
		width: 0.44,
		category: 'Sans-Serif',
	},
	'Sirin Stencil': {
		name: 'Sirin Stencil',
		contrast: 0.91,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.39,
		category: 'Display',
	},
	'Six Caps': {
		name: 'Six Caps',
		contrast: 0.26,
		weight: 0.06,
		xHeight: 0.89,
		width: 0.17,
		category: 'Sans-Serif',
	},
	Skranji: {
		name: 'Skranji',
		contrast: 0.34,
		weight: 0.11,
		xHeight: 0.8,
		width: 0.46,
		category: 'Display',
	},
	'Slabo 13px': {
		name: 'Slabo 13px',
		contrast: 0.19,
		weight: 0.07,
		xHeight: 0.78,
		width: 0.48,
		category: 'Serif',
	},
	'Slabo 27px': {
		name: 'Slabo 27px',
		contrast: 0.24,
		weight: 0.05,
		xHeight: 0.72,
		width: 0.41,
		category: 'Serif',
	},
	Slackey: {
		name: 'Slackey',
		contrast: 0.53,
		weight: 0.15,
		xHeight: 0.89,
		width: 0.62,
		category: 'Display',
	},
	Smokum: {
		name: 'Smokum',
		contrast: 0.76,
		weight: 0.06,
		xHeight: 0.67,
		width: 0.34,
		category: 'Display',
	},
	Smythe: {
		name: 'Smythe',
		contrast: 0.29,
		weight: 0.05,
		xHeight: 0.73,
		width: 0.33,
		category: 'Display',
	},
	Sniglet: {
		name: 'Sniglet',
		contrast: 0.13,
		weight: 0.08,
		xHeight: 0.72,
		width: 0.44,
		category: 'Display',
	},
	Snippet: {
		name: 'Snippet',
		contrast: 0.09,
		weight: 0.05,
		xHeight: 0.73,
		width: 0.41,
		category: 'Sans-Serif',
	},
	'Snowburst One': {
		name: 'Snowburst One',
		contrast: 0.16,
		weight: 0.05,
		xHeight: 0.8,
		width: 0.52,
		category: 'Display',
	},
	'Sofadi One': {
		name: 'Sofadi One',
		contrast: 0.54,
		weight: 0.06,
		xHeight: 0.64,
		width: 0.47,
		category: 'Display',
	},
	Sofia: {
		name: 'Sofia',
		contrast: 0.8,
		weight: 0.06,
		xHeight: 0.57,
		width: 0.48,
		category: 'Handwriting',
	},
	'Sonsie One': {
		name: 'Sonsie One',
		contrast: 0.67,
		weight: 0.16,
		xHeight: 0.69,
		width: 0.9,
		category: 'Display',
	},
	'Sorts Mill Goudy': {
		name: 'Sorts Mill Goudy',
		contrast: 0.68,
		weight: 0.05,
		xHeight: 0.6,
		width: 0.45,
		category: 'Serif',
	},
	'Source Code Pro': {
		name: 'Source Code Pro',
		contrast: 0.2,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.45,
		category: 'Monospace',
	},
	'Source Sans Pro': {
		name: 'Source Sans Pro',
		contrast: 0.2,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Source Serif Pro': {
		name: 'Source Serif Pro',
		contrast: 0.52,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.47,
		category: 'Serif',
	},
	'Space Mono': {
		name: 'Space Mono',
		contrast: 0.08,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.47,
		category: 'Monospace',
	},
	'Special Elite': {
		name: 'Special Elite',
		contrast: 0.73,
		weight: 0.07,
		xHeight: 0.7,
		width: 0.54,
		category: 'Display',
	},
	Spectral: {
		name: 'Spectral',
		contrast: 0.55,
		weight: 0.05,
		xHeight: 0.68,
		width: 0.44,
		category: 'Serif',
	},
	'Spicy Rice': {
		name: 'Spicy Rice',
		contrast: 0.19,
		weight: 0.11,
		xHeight: 0.79,
		width: 0.48,
		category: 'Display',
	},
	Spinnaker: {
		name: 'Spinnaker',
		contrast: 0.18,
		weight: 0.07,
		xHeight: 0.71,
		width: 0.45,
		category: 'Sans-Serif',
	},
	Spirax: {
		name: 'Spirax',
		contrast: 0.91,
		weight: 0.06,
		xHeight: 0.67,
		width: 0.43,
		category: 'Display',
	},
	'Squada One': {
		name: 'Squada One',
		contrast: 0.38,
		weight: 0.08,
		xHeight: 0.75,
		width: 0.34,
		category: 'Display',
	},
	'Sree Krushnadevaraya': {
		name: 'Sree Krushnadevaraya',
		contrast: 0.7,
		weight: 0.05,
		xHeight: 0.67,
		width: 0.41,
		category: 'Serif',
	},
	Sriracha: {
		name: 'Sriracha',
		contrast: 0.35,
		weight: 0.08,
		xHeight: 0.73,
		width: 0.5,
		category: 'Handwriting',
	},
	Stalemate: {
		name: 'Stalemate',
		contrast: 0.66,
		weight: 0.01,
		xHeight: 0.36,
		width: 0.25,
		category: 'Handwriting',
	},
	'Stalinist One': {
		name: 'Stalinist One',
		contrast: 0.48,
		weight: 0.21,
		xHeight: 0.76,
		width: 0.94,
		category: 'Display',
	},
	'Stardos Stencil': {
		name: 'Stardos Stencil',
		contrast: 0.87,
		weight: 0.06,
		xHeight: 0.69,
		width: 0.46,
		category: 'Display',
	},
	'Stint Ultra Condensed': {
		name: 'Stint Ultra Condensed',
		contrast: 0.15,
		weight: 0.04,
		xHeight: 0.69,
		width: 0.26,
		category: 'Display',
	},
	'Stint Ultra Expanded': {
		name: 'Stint Ultra Expanded',
		contrast: 0.14,
		weight: 0.06,
		xHeight: 0.69,
		width: 0.64,
		category: 'Display',
	},
	Stoke: {
		name: 'Stoke',
		contrast: 0.46,
		weight: 0.08,
		xHeight: 0.65,
		width: 0.59,
		category: 'Serif',
	},
	Strait: {
		name: 'Strait',
		contrast: 0.24,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.33,
		category: 'Sans-Serif',
	},
	'Sue Ellen Francisco': {
		name: 'Sue Ellen Francisco',
		contrast: 0.1,
		weight: 0.03,
		xHeight: 0.42,
		width: 0.26,
		category: 'Handwriting',
	},
	'Suez One': {
		name: 'Suez One',
		contrast: 0.34,
		weight: 0.11,
		xHeight: 0.78,
		width: 0.53,
		category: 'Serif',
	},
	Sumana: {
		name: 'Sumana',
		contrast: 0.5,
		weight: 0.06,
		xHeight: 0.66,
		width: 0.46,
		category: 'Serif',
	},
	Sunshiney: {
		name: 'Sunshiney',
		contrast: 0.22,
		weight: 0.04,
		xHeight: 0.5,
		width: 0.37,
		category: 'Handwriting',
	},
	'Supermercado One': {
		name: 'Supermercado One',
		contrast: 0.24,
		weight: 0.08,
		xHeight: 0.8,
		width: 0.36,
		category: 'Display',
	},
	Sura: {
		name: 'Sura',
		contrast: 0.4,
		weight: 0.07,
		xHeight: 0.7,
		width: 0.47,
		category: 'Serif',
	},
	Suranna: {
		name: 'Suranna',
		contrast: 0.8,
		weight: 0.04,
		xHeight: 0.65,
		width: 0.41,
		category: 'Serif',
	},
	Suravaram: {
		name: 'Suravaram',
		contrast: 0.52,
		weight: 0.04,
		xHeight: 0.73,
		width: 0.38,
		category: 'Serif',
	},
	'Swanky and Moo Moo': {
		name: 'Swanky and Moo Moo',
		contrast: 0.21,
		weight: 0.03,
		xHeight: 0.74,
		width: 0.43,
		category: 'Handwriting',
	},
	Syncopate: {
		name: 'Syncopate',
		contrast: 0.15,
		weight: 0.09,
		xHeight: 1,
		width: 0.68,
		category: 'Sans-Serif',
	},
	Tangerine: {
		name: 'Tangerine',
		contrast: 0.67,
		weight: 0.01,
		xHeight: 0.37,
		width: 0.35,
		category: 'Handwriting',
	},
	Tauri: {
		name: 'Tauri',
		contrast: 0.24,
		weight: 0.09,
		xHeight: 0.72,
		width: 0.43,
		category: 'Sans-Serif',
	},
	Taviraj: {
		name: 'Taviraj',
		contrast: 0.59,
		weight: 0.06,
		xHeight: 0.67,
		width: 0.5,
		category: 'Serif',
	},
	Teko: {
		name: 'Teko',
		contrast: 0.21,
		weight: 0.06,
		xHeight: 0.78,
		width: 0.27,
		category: 'Sans-Serif',
	},
	Telex: {
		name: 'Telex',
		contrast: 0.23,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.43,
		category: 'Sans-Serif',
	},
	'Tenali Ramakrishna': {
		name: 'Tenali Ramakrishna',
		contrast: 0.44,
		weight: 0.04,
		xHeight: 0.73,
		width: 0.32,
		category: 'Sans-Serif',
	},
	'Tenor Sans': {
		name: 'Tenor Sans',
		contrast: 0.58,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.43,
		category: 'Sans-Serif',
	},
	'Text Me One': {
		name: 'Text Me One',
		contrast: 0.05,
		weight: 0.04,
		xHeight: 0.71,
		width: 0.35,
		category: 'Sans-Serif',
	},
	'The Girl Next Door': {
		name: 'The Girl Next Door',
		contrast: 0.4,
		weight: 0.04,
		xHeight: 0.84,
		width: 0.45,
		category: 'Handwriting',
	},
	Tienne: {
		name: 'Tienne',
		contrast: 0.52,
		weight: 0.07,
		xHeight: 0.73,
		width: 0.54,
		category: 'Serif',
	},
	Tillana: {
		name: 'Tillana',
		contrast: 0.28,
		weight: 0.05,
		xHeight: 0.74,
		width: 0.44,
		category: 'Handwriting',
	},
	Timmana: {
		name: 'Timmana',
		contrast: 0.3,
		weight: 0.07,
		xHeight: 0.78,
		width: 0.41,
		category: 'Sans-Serif',
	},
	Tinos: {
		name: 'Tinos',
		contrast: 0.56,
		weight: 0.06,
		xHeight: 0.7,
		width: 0.42,
		category: 'Serif',
	},
	'Titan One': {
		name: 'Titan One',
		contrast: 0.48,
		weight: 0.17,
		xHeight: 0.82,
		width: 0.59,
		category: 'Display',
	},
	'Titillium Web': {
		name: 'Titillium Web',
		contrast: 0.16,
		weight: 0.06,
		xHeight: 0.72,
		width: 0.39,
		category: 'Sans-Serif',
	},
	'Trade Winds': {
		name: 'Trade Winds',
		contrast: 0.55,
		weight: 0.09,
		xHeight: 0.74,
		width: 0.62,
		category: 'Display',
	},
	Trirong: {
		name: 'Trirong',
		contrast: 0.61,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.51,
		category: 'Serif',
	},
	Trocchi: {
		name: 'Trocchi',
		contrast: 0.48,
		weight: 0.08,
		xHeight: 0.71,
		width: 0.55,
		category: 'Serif',
	},
	Trochut: {
		name: 'Trochut',
		contrast: 0.65,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.33,
		category: 'Display',
	},
	Trykker: {
		name: 'Trykker',
		contrast: 0.5,
		weight: 0.06,
		xHeight: 0.68,
		width: 0.48,
		category: 'Serif',
	},
	'Tulpen One': {
		name: 'Tulpen One',
		contrast: 0.11,
		weight: 0.03,
		xHeight: 0.73,
		width: 0.17,
		category: 'Display',
	},
	Ubuntu: {
		name: 'Ubuntu',
		contrast: 0.16,
		weight: 0.08,
		xHeight: 0.75,
		width: 0.41,
		category: 'Sans-Serif',
	},
	'Ubuntu Condensed': {
		name: 'Ubuntu Condensed',
		contrast: 0.14,
		weight: 0.06,
		xHeight: 0.76,
		width: 0.32,
		category: 'Sans-Serif',
	},
	'Ubuntu Mono': {
		name: 'Ubuntu Mono',
		contrast: 0.14,
		weight: 0.06,
		xHeight: 0.75,
		width: 0.39,
		category: 'Monospace',
	},
	Ultra: {
		name: 'Ultra',
		contrast: 0.49,
		weight: 0.17,
		xHeight: 0.75,
		width: 0.7,
		category: 'Serif',
	},
	'Uncial Antiqua': {
		name: 'Uncial Antiqua',
		contrast: 0.77,
		weight: 0.09,
		xHeight: 0.59,
		width: 0.61,
		category: 'Display',
	},
	Underdog: {
		name: 'Underdog',
		contrast: 0.31,
		weight: 0.06,
		xHeight: 0.73,
		width: 0.47,
		category: 'Display',
	},
	'Unica One': {
		name: 'Unica One',
		contrast: 0.14,
		weight: 0.07,
		xHeight: 1,
		width: 0.32,
		category: 'Display',
	},
	UnifrakturMaguntia: {
		name: 'UnifrakturMaguntia',
		contrast: 0.83,
		weight: 0.06,
		xHeight: 0.77,
		width: 0.4,
		category: 'Display',
	},
	Unkempt: {
		name: 'Unkempt',
		contrast: 0.34,
		weight: 0.04,
		xHeight: 0.65,
		width: 0.49,
		category: 'Display',
	},
	Unlock: {
		name: 'Unlock',
		contrast: 0.62,
		weight: 0.12,
		xHeight: 0.87,
		width: 0.51,
		category: 'Display',
	},
	Unna: {
		name: 'Unna',
		contrast: 0.7,
		weight: 0.05,
		xHeight: 0.7,
		width: 0.42,
		category: 'Serif',
	},
	VT323: {
		name: 'VT323',
		contrast: 0.59,
		weight: 0.04,
		xHeight: 0.71,
		width: 0.31,
		category: 'Monospace',
	},
	'Vampiro One': {
		name: 'Vampiro One',
		contrast: 0.47,
		weight: 0.11,
		xHeight: 0.78,
		width: 0.69,
		category: 'Display',
	},
	Varela: {
		name: 'Varela',
		contrast: 0.09,
		weight: 0.08,
		xHeight: 0.73,
		width: 0.44,
		category: 'Sans-Serif',
	},
	'Varela Round': {
		name: 'Varela Round',
		contrast: 0.09,
		weight: 0.08,
		xHeight: 0.73,
		width: 0.44,
		category: 'Sans-Serif',
	},
	'Vast Shadow': {
		name: 'Vast Shadow',
		contrast: 0.43,
		weight: 0.11,
		xHeight: 0.78,
		width: 0.72,
		category: 'Display',
	},
	'Vesper Libre': {
		name: 'Vesper Libre',
		contrast: 0.56,
		weight: 0.06,
		xHeight: 0.69,
		width: 0.47,
		category: 'Serif',
	},
	Vibur: {
		name: 'Vibur',
		contrast: 0.48,
		weight: 0.05,
		xHeight: 0.55,
		width: 0.43,
		category: 'Handwriting',
	},
	Vidaloka: {
		name: 'Vidaloka',
		contrast: 0.82,
		weight: 0.07,
		xHeight: 0.7,
		width: 0.46,
		category: 'Serif',
	},
	Viga: {
		name: 'Viga',
		contrast: 0.12,
		weight: 0.1,
		xHeight: 0.8,
		width: 0.43,
		category: 'Sans-Serif',
	},
	Voces: {
		name: 'Voces',
		contrast: 0.36,
		weight: 0.08,
		xHeight: 0.74,
		width: 0.43,
		category: 'Display',
	},
	Volkhov: {
		name: 'Volkhov',
		contrast: 0.56,
		weight: 0.08,
		xHeight: 0.71,
		width: 0.53,
		category: 'Serif',
	},
	Vollkorn: {
		name: 'Vollkorn',
		contrast: 0.53,
		weight: 0.06,
		xHeight: 0.68,
		width: 0.45,
		category: 'Serif',
	},
	Voltaire: {
		name: 'Voltaire',
		contrast: 0.06,
		weight: 0.06,
		xHeight: 0.67,
		width: 0.32,
		category: 'Sans-Serif',
	},
	'Waiting for the Sunrise': {
		name: 'Waiting for the Sunrise',
		contrast: 0.27,
		weight: 0.03,
		xHeight: 0.76,
		width: 0.38,
		category: 'Handwriting',
	},
	Wallpoet: {
		name: 'Wallpoet',
		contrast: 0.89,
		weight: 0.09,
		xHeight: 0.77,
		width: 0.57,
		category: 'Display',
	},
	'Walter Turncoat': {
		name: 'Walter Turncoat',
		contrast: 0.33,
		weight: 0.07,
		xHeight: 0.75,
		width: 0.48,
		category: 'Handwriting',
	},
	Warnes: {
		name: 'Warnes',
		contrast: 0,
		weight: 0.1,
		xHeight: 0.76,
		width: 0.76,
		category: 'Display',
	},
	Wellfleet: {
		name: 'Wellfleet',
		contrast: 0.1,
		weight: 0.09,
		xHeight: 0.74,
		width: 0.5,
		category: 'Display',
	},
	'Wendy One': {
		name: 'Wendy One',
		contrast: 0.52,
		weight: 0.11,
		xHeight: 0.78,
		width: 0.52,
		category: 'Sans-Serif',
	},
	'Wire One': {
		name: 'Wire One',
		contrast: 0.1,
		weight: 0.02,
		xHeight: 0.72,
		width: 0.2,
		category: 'Sans-Serif',
	},
	'Work Sans': {
		name: 'Work Sans',
		contrast: 0.14,
		weight: 0.07,
		xHeight: 0.76,
		width: 0.44,
		category: 'Sans-Serif',
	},
	'Yanone Kaffeesatz': {
		name: 'Yanone Kaffeesatz',
		contrast: 0.23,
		weight: 0.06,
		xHeight: 0.71,
		width: 0.3,
		category: 'Sans-Serif',
	},
	Yantramanav: {
		name: 'Yantramanav',
		contrast: 0.22,
		weight: 0.06,
		xHeight: 0.74,
		width: 0.37,
		category: 'Sans-Serif',
	},
	'Yatra One': {
		name: 'Yatra One',
		contrast: 0.6,
		weight: 0.08,
		xHeight: 0.72,
		width: 0.48,
		category: 'Display',
	},
	Yellowtail: {
		name: 'Yellowtail',
		contrast: 0.2,
		weight: 0.05,
		xHeight: 0.62,
		width: 0.49,
		category: 'Handwriting',
	},
	'Yeseva One': {
		name: 'Yeseva One',
		contrast: 0.88,
		weight: 0.09,
		xHeight: 0.71,
		width: 0.54,
		category: 'Display',
	},
	Yesteryear: {
		name: 'Yesteryear',
		contrast: 0,
		weight: 0.04,
		xHeight: 0.38,
		width: 0.46,
		category: 'Handwriting',
	},
	Yrsa: {
		name: 'Yrsa',
		contrast: 0.52,
		weight: 0.05,
		xHeight: 0.73,
		width: 0.41,
		category: 'Serif',
	},
	Zeyada: {
		name: 'Zeyada',
		contrast: 0.46,
		weight: 0.02,
		xHeight: 0.6,
		width: 0.39,
		category: 'Handwriting',
	},
	'Zilla Slab': {
		name: 'Zilla Slab',
		contrast: 0.19,
		weight: 0.05,
		xHeight: 0.68,
		width: 0.46,
		category: 'Serif',
	},
	'Zilla Slab Highlight': {
		name: 'Zilla Slab Highlight',
		contrast: 0.82,
		weight: 0.29,
		xHeight: 1,
		width: 0.53,
		category: 'Display',
	},
	Inter: {
		name: 'Inter',
		contrast: 0.19,
		weight: 0.07,
		xHeight: 0.799,
		width: 0.41,
		category: 'Sans-Serif',
	},
	Newsreader: {
		name: 'Newsreader',
		contrast: 0.19,
		weight: 0.6,
		xHeight: 0.299,
		width: 0.61,
		category: 'Sans-Serif',
	},
};

export const getFontCombination = (key: FontCategory): FontPack => {
	return { ...TypsetCombinations[key], subsets: ['cyrillic'] };
};

/**
 * { bodyFont: 'name', headingFont: 'name'} =>
 * FontSet
 */
