export const getLocalState =
	reducerKey =>
	(storageKey, defaultValue, isObject = true) => {
		const value = localStorage.getItem(`Store::${reducerKey}.${storageKey}`);
		if (value && isObject) {
			return JSON.parse(value);
		} else if (value) {
			return value;
		}
		return defaultValue;
	};

export const setLocalState =
	reducerKey =>
	(storageKey, value, isObject = true) => {
		localStorage.setItem(
			`Store::${reducerKey}.${storageKey}`,
			isObject ? JSON.stringify(value) : value
		);
	};

export const getLocalSetting = getLocalState('settings');
