import en from './en';
import ru from './ru';

const resources = {
	'en-US': {
		translation: en,
	},
	'ru-RU': {
		translation: ru,
	},
};
export default resources;
