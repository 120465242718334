import { FontsDetail } from './fontsInfo';
import {
	ItemSizeValue,
	ScaleCalculator,
	FontDetails,
	FontVariantBuilder,
	TypographyBuilder,
	BaseWidthCalculator,
	LineHeightCalculator,
	HeightCalculator,
	MaxWidth,
	ScaleInfo,
} from 'types/typography.types';
import { getThemeByName } from '../helpers';
import { Size } from 'types/base.types';

const defaults = {
	cpl: 73,
	widthFactor: 34,
};

const GoldenRatio = (1 + Math.sqrt(5)) / 2;
const MinorThird = 6 / 5;
const PerfectForth = 4 / 3;
const MinorSeventh = 16 / 9;
const Octave = 2;
const AugmentedForth = Math.sqrt(2);

export enum ItemSize {
	Xs = 'Xs',
	S = 'S',
	M = 'M',
	L = 'L',
	Xl = 'Xl',
	Xxl = 'Xxl',
}

const FontScaleFactor: ItemSizeValue = {
	Xs: MinorThird, // 5:6
	S: PerfectForth, // 3:4
	M: AugmentedForth, // Math.sqrt(2)
	L: GoldenRatio, // Golden ratio ~1.6
	Xl: MinorSeventh, // 9:16
	Xxl: Octave, // 1:2
};

const BaseFontSize: ItemSizeValue = {
	Xs: 1.2,
	S: 1.4,
	M: 1.6,
	L: 1.8,
	Xl: 2.2,
	Xxl: 2.5,
};

const spaceUnitsCalculator: ScaleCalculator = ({ size, ratio }) => {
	return {
		Xs: size / Math.pow(ratio, 4),
		S: size / Math.pow(ratio, 3),
		M: size / Math.pow(ratio, 2),
		L: size / ratio,
		Xl: size,
		Xxl: size * ratio,
	};
};
const fontScaleCalculator: ScaleCalculator = ({ size, ratio }) => {
	return {
		Xs: size / Math.sqrt(ratio),
		S: size,
		M: size * Math.sqrt(ratio),
		L: size * ratio,
		Xl: size * Math.pow(ratio, 3 / 2),
		Xxl: size * Math.pow(ratio, 2),
	};
};

const lineHeightCalculator: LineHeightCalculator = ({ size, ratio, fontFamily }) => {
	const { width, xHeight } = FontsDetail[fontFamily];
	const baseWidth = calculateBaseWidth({
		cWidth: width,
		size,
		cpl: defaults.cpl,
	});
	return (size: number) => {
		return calculateHeight({
			size,
			ratio,
			baseWidth,
			widthFactor: defaults.widthFactor,
			xHeight,
		});
	};
};

const fontDetails = (size: number, height: number): FontDetails => {
	return {
		fontSize: `${size}rem`,
		lineHeight: `${height}rem`,
		letterSpacing: '0',
		fontWeight: 'inherit',
		color: 'inherit',
		fs: size,
		lh: height,
	};
};

const fontVariant: FontVariantBuilder = (fontScale, lineHeightCalculator) => size =>
	fontDetails(fontScale[size], lineHeightCalculator(fontScale[size]));

export const calculateHeight: HeightCalculator = ({
	size,
	ratio,
	baseWidth,
	widthFactor,
	xHeight,
}) => {
	return (
		size *
		(3 -
			ratio +
			((2 * ratio - 3) * (baseWidth / (size * widthFactor)) +
				(xHeight - (ratio - 1)) / ratio))
	);
};

const buildScaleInfo = (size: Size): ScaleInfo => {
	const ratio = FontScaleFactor[size];
	return {
		a: 100 / ratio,
		b: 100 - 100 / ratio,
	};
};

export const calculateBaseWidth: BaseWidthCalculator = ({ cpl, cWidth, size }) => {
	return cpl * cWidth * size;
};

export const Typography: TypographyBuilder = ({
	BaseCPL = 72,
	Scale = 'L',
	Size = 'M',
	Theme = 'DefaultTheme',
}) => {
	const theme = getThemeByName(Theme);
	const size = BaseFontSize[Size];
	const ratio = FontScaleFactor[Scale];
	const bodyLineHeight = lineHeightCalculator({
		size,
		ratio,
		fontFamily: theme.combination.body.font,
	})(size);
	const headingVariants = fontVariant(
		fontScaleCalculator({ size, ratio }),
		lineHeightCalculator({
			size,
			ratio,
			fontFamily: theme.combination.headings.font,
		})
	);
	const headings: Record<ItemSize, FontDetails> = Object.keys(ItemSize).reduce(
		(accumulator, current) => {
			accumulator[current as ItemSize] = headingVariants(current as ItemSize);
			return accumulator;
		},
		{} as Record<ItemSize, FontDetails>
	) as Record<ItemSize, FontDetails>;
	const bodySettings = fontDetails(size, bodyLineHeight);
	return {
		body: bodySettings,
		headings,
		space: spaceUnitsCalculator({ size: bodyLineHeight, ratio }),
		bodyFont: theme.combination.body.font,
		headingsFont: theme.combination.headings.font,
		scale: buildScaleInfo(Scale),
		width: {
			body: {
				maxWidth: `${calculateBaseWidth({
					cpl: BaseCPL,
					cWidth: FontsDetail[theme.combination.body.font].width,
					size: bodySettings.fs,
				})}rem`,
				mw: calculateBaseWidth({
					cpl: BaseCPL,
					cWidth: FontsDetail[theme.combination.body.font].width,
					size: bodySettings.fs,
				}),
			},
			headings: Object.keys(ItemSize).reduce((accumulator, current) => {
				accumulator[current as ItemSize] = {
					maxWidth: `${calculateBaseWidth({
						cpl: BaseCPL,
						cWidth: FontsDetail[theme.combination.headings.font].width,
						size: headings[current as ItemSize].fs,
					})}rem`,
					mw: calculateBaseWidth({
						cpl: BaseCPL,
						cWidth: FontsDetail[theme.combination.headings.font].width,
						size: headings[current as ItemSize].fs,
					}),
				};
				return accumulator;
			}, {} as Record<ItemSize, MaxWidth>) as Record<ItemSize, MaxWidth>,
		},
	};
};
