export default {
	common: {
		today: 'Сегодня',
		tomorrow: 'Завтра',
		yesterday: 'Вчера',
	},
	settings: {
		language: 'Сменить язык',
		ru: 'Русский',
		en: 'English',
		ua: 'Українська',
		de: 'Deutsch',
		signout: 'Выход',
	},
	board: {
		schedule: 'Расписание',
		todo: 'Задачи',
		focus: 'Фокус',
		goals: 'Цели',
		today: 'Сегодня',
		configure: 'Настроить доски',
		addNew: 'Добавить доску',
		boards: 'Доски',
		templates: 'Шаблоны',
		copyBoardContent: 'Копировать контент текущей доски',
		createNewBoardTitle: 'Создать новую доску',
		type: 'Тип доски',
		title: 'Название доски',
		name: 'Имя доски (уникальное, будет использовано в адресной строке браузера)',
		private: 'Личная',
		shared: 'Общая',
		demo: 'Демо',
		save: 'Сохранить',
		add: 'Создать',
		switchBackground:
			'Кликните для переключения фона или зажмите для просмотра текущего изображения',
	},
	document: {
		multiday: {
			daysLeft: 'осталось {{daysLeft}} дней',
			oneDayLeft: 'остался один день',
			endsHere: 'завершение',
		},
		actions: {
			pinToFocus: 'Закрепить в фокусе',
			unpinFromFocus: 'Убрать из фокуса',
			markUndone: 'Пометить как невыполненное',
			repeat: 'Повторить',
			moveRemaining: 'Выделить не сделанные',
			moveDone: 'Выделить сделанные',
			moveToCurrentPeriod: 'На текущий период',
			moveToNextPeriod: 'На следующий период',
			moveToPrevPeriod: 'На предыдущий период',
			moveToday: 'На сегодня',
			moveUp: 'На предыдущий день',
			moveDown: 'На следующий день',
			split: 'Разделить задачи',
			splitGoal: 'Разделить задачи',
			sendToBoard: 'Отправить на доску',
			remove: 'Удалить',
			moreActions: 'Еще действия',
			duplicate: 'Сделать копию',
			edit: 'Редактировать',
		},
		editor: {
			icon_color: 'Иконка и цвет',
			time: 'Время начала',
			time_q: '-',
			startDate: 'Дата начала',
			startDate_q: 'Дата начала',
			endDate: 'Дата окончания',
			endDate_q: 'Задать дату окончания',
			topic: 'Сфера, область',
			setTopic: 'Задать сферу, область',
			location: 'Место',
			enterLocation: 'Задать место',
			noIcon: 'Нет иконки',
			fullScreenMode: 'На полный экран',
			template: 'Шаблон',
		},
		hydra: {
			newTask: 'Какие мысли ?',
			enterNewNotes: '',
			todo: 'Задача',
			hintMessage1: `<0>
						Нажмите
						<1>Enter</1>
						чтобы добавить детали.
					</0>
				`,
			hintMessage2: `<0>
						Двойной <1>Пробел</1>
						вначале строки создаст
						<3>задачу</3>.
					</0>
				`,
			hintMessage3: `<0>
						Двойное <1>—</1> (тире)
						и <1>Пробел</1>
						в начале строки, чтобы создать
						<5>Разделитель</5>.
					</0>
				`,
			hintMessage4: `<0>
					Для <5>сохранения</5> используйте
					<1>{{modEnterHotkey}}</1>
					,
					<1>Esc</1>
					или просто закройте всплывающее окно.
				</0>
				`,
		},
	},
	focus: {
		searchInFocus: 'Искать в фокусе',
	},
	goals: {
		WEEKLY: 'Неделя',
		MONTHLY: 'Месяц',
		QUARTERLY: 'Квартал',
		YEARLY: 'Год',
		week: '{{weekKind}} Неделя',
		weekKindThis: '',
		weekKindNext: 'Следующая',
		weekKindPrev: 'Прошлая',
		quarter: 'Квартал',
		year: 'Год',
		weeklyGoals: 'Цели на неделю',
		review: '',
		noGoals: 'Нет целей',
		goalsStat: 'Цели: {{done}} из {{total}}',
		startOfWeek: 'Начало недели {{span}}',
		endOfWeek: 'Конец недели {{span}}',
		currentWeek: 'Текущая неделя {{span}}',
		// goalsStat_plural: 'Выполнено {{done}} из {{total}} целей'
	},
	timeline: {
		today: 'Сегодня',
		yesterday: 'вчера',
		tomorrow: 'завтра',
		theMonthOf: 'Месяц',
		addNewTask: 'Задачу',
		addNewNote: 'Заметка',
		addNewTaskList: 'Список задач',
		addNewEvent: 'Событие',
		addTemplate: 'Добавить шаблон',
		addNewGoal: 'Добавить новую цель',
		whatInYourFocus: 'Что сейчас важно?',
		noEvents: '- нет планов на этот день -',
	},
	templates: {
		hideTemplate: 'Убрать с панели шаблонов',
		showTemplate: 'Добавить на парель шаблонов',
		saveAsTemplate: 'Добавить шаблон',
		searchInTemplates: 'Искать в шаблонах',
	},
};
