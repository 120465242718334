import { themeColorMap as colors, getColorsSet as cc } from '@smarttime/core-ui';

const baseColor = 210;
const baseSaturation = 15;
export const defaults = fonts => ({
	...colors({
		button1: [[0, 0, 96], undefined, undefined, [0, 0, 92]],
		button: cc([baseColor, baseSaturation, 96], 3, 3),
		cta: cc([146, 66, 40], 20),
		accent: cc([0, 91, 55], 19),
		text: cc([240, 13, 34]),
		'text-opposite': cc([240, 35, 100], -13, 0),
		icon: cc([240, 13, 50]),
		hl: cc([baseColor, baseSaturation + 10, 62], 13),
		bg: [[0, 0, 100]],
		'bg-secondary': cc([baseColor, baseSaturation, 92], 2, 3),
		stroke: cc([baseColor, baseSaturation, 90], 3, 7),
		black: cc([0, 0, 20], 28, 0),
		skyblue: cc([200, 97, 45]),
		mandarin: cc([25, 100, 50]),
		parakeet: cc([146, 66, 37]),
		emerald: cc([91, 52, 26], 33),
		garnet: cc([0, 97, 54]),
		sunset: cc([319, 100, 44]),
		violet: cc([271, 70, 60]),
		purple: cc([271, 34, 36]),
		goldenglow: cc([50, 95, 41], 27),
	}),

	/**
	 * Fonts section
	 * */
	'--display-font-family': `"${fonts.display}", -apple-system, BlinkMacSystemFont, "Open Sans", "Lato", "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
	'--main-font-family': `"${fonts.text}", -apple-system, BlinkMacSystemFont, "Open Sans", "Lato", "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,

	// '--cta-color':
	// 	'hsla(var(--cta-color-hue, 146), var(--cta-color-saturation, 66%), var(--cta-color-lightness, 40%), var(--cta-color-opacity, 1))',
	// '--cta-color-lighter':
	// 	'hsla(var(--cta-color-hue, 146), var(--cta-color-saturation, 66%), var(--cta-color-lightness-lighter, 45%), var(--cta-color-opacity, 1))',
	// '--cta-color-lightest':
	// 	'hsla(var(--cta-color-hue, 146), var(--cta-color-saturation, 66%), var(--cta-color-lightness-lightest, 77%), var(--cta-color-opacity, 1))',
	// '--cta-color-darker': 'hsla(146, 66%, 34%,1)',
	// '--text-color':
	// 	'hsla(var(--text-color-hue, 240), var(--text-color-saturation, 35%), var(--text-color-lightness, 24%), var(--text-color-opacity, 1))',
	// '--text-color-lighter':
	// 	'hsla(var(--text-color-hue, 240), var(--text-color-saturation, 35%), var(--text-color-lightness-lighter, 34%), var(--text-color-opacity, 1))',
	// '--text-color-lightest':
	// 	'hsla(var(--text-color-hue, 240), var(--text-color-saturation, 35%), var(--text-color-lightness-lightest, 88%), var(--text-color-opacity, 1))',
	// '--text-opposite-color':
	// 	'hsla(var(--text-opposite-color-hue, 240), var(--text-opposite-color-saturation, 35%), var(--text-opposite-color-lightness, 100%), var(--text-opposite-color-opacity, 1))',
	// '--text-opposite-color-lighter':
	// 	'hsla(var(--text-opposite-color-hue, 240), var(--text-opposite-color-saturation, 35%), var(--text-opposite-color-lightness-lighter, 87%), var(--text-opposite-color-opacity, 1))',
	// '--text-opposite-color-lightest':
	// 	'hsla(var(--text-opposite-color-hue, 240), var(--text-opposite-color-saturation, 35%), var(--text-opposite-color-lightness-lightest, 52%), var(--text-opposite-color-opacity, 1))',
	'--text-secondary-color':
		'hsla(var(--text-secondary-color-hue, 240), var(--text-secondary-color-saturation, 11%), var(--text-secondary-color-lightness, 63%), var(--text-secondary-color-opacity, 1))',
	'--icon-button-color':
		'hsla(var(--text-color-hue, 240), var(--text-color-saturation, 35%), var(--text-color-lightness, 24%), var(--icon-button-default-opacity, 0.6))',
	'--fade-color': 'hsla(0, 0%, 1%, .1)',
	'--hint-color': 'hsla(var(--main-text-hue), 35%, 24%, var(--hint-opacity))',
	'--active-hint-color': 'hsla(var(--main-text-hue), 35%, 24%, var(--active-hint-opacity))',
	'--success-color': 'var(--parakeet-color)',
	'--success-color-lightest': 'var(--parakeet-color-lightest)',
	'--error-color': 'var(--garnet-color)',
	'--error-color-lightest': 'var(--garnet-color-lightest)',
	'--failed-color': 'var(--mandarin-color)',
	'--failed-color-lightest': 'var(--mandarin-color-lightest)',
	'--info-color': 'var(--hl-color)',
	'--info-color-lightest': 'var(--hl-color-lightest)',
	'--disabled-color': 'var(--text-color-lightest)',

	/**
	 * Borders and layout section
	 * */

	'--primary-border-style': '1px solid var(--stroke-color)',
	'--primary-border-selected-style': '1px solid var(--sky--hl-color)',
	/**
	 * Fonts section
	 * */
	// prettier-ignore
});
