import uuid from 'node-uuid';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import isSameDay from 'date-fns/isSameDay';
// import isEqual from 'date-fns/isEqual';
import { Node } from 'slate';
import endOfDay from 'date-fns/endOfDay';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

// import { Node as DocumentContentNode } from 'slate';

import {
	DocumentInfo,
	DocumentType,
	DocumentState,
	DocumentPreset,
	// TimelinePosition,
} from '../../models';

// export type InitialDocumentCreator = (preset: DocumentPreset) => DocumentInfo;
const TIME_FORMAT = 'HH:mm';

export type DocumentCreator = (preset: DocumentPreset, basePreset?: DocumentPreset) => DocumentInfo;

export const newClientId: () => string = () => {
	return uuid.v4();
};

const initDateProperty = (
	document: DocumentInfo,
	property: 'startDate' | 'dueDate' | 'endDate' | 'createdAt' | 'updatedAt',
	value: Date | undefined | string
): void => {
	if (typeof value === 'string') {
		document[property] = new Date(value);
	}
};
export type PositionInTime = 'past' | 'today' | 'future' | undefined;

const emptyValue = [
	{
		type: 'p',
		children: [{ text: '' }],
	},
];
export const createDocument: DocumentCreator = (preset, basePreset) => {
	const cid = preset.cid || newClientId();

	const item: DocumentInfo = {
		content: [],
		state: DocumentState.Todo,
		type: DocumentType.General,
		...basePreset,
		...preset,
		cid,
	};
	if (!item.content || item.content.length === 0) {
		item.content = emptyValue;
	}

	delete item.startTime;
	delete item.endTime;
	delete item.daysDiff;
	delete item.isMultiDay;
	delete item.isPast;
	delete item.isToday;
	delete item.isFuture;
	delete item.isNotDone;
	delete item.isOverdue;

	// delete item.isNew;
	// delete item.isUpdated;
	// delete item.isRemoved;
	// delete item.isTemplate;

	initDateProperty(item, 'startDate', item.startDate);
	initDateProperty(item, 'endDate', item.endDate);
	initDateProperty(item, 'dueDate', item.dueDate);
	initDateProperty(item, 'createdAt', item.createdAt);
	initDateProperty(item, 'updatedAt', item.updatedAt);

	item.title = Node.string(item.content[0]) || '';

	const today = startOfDay(new Date()); //
	const endOfToday = endOfDay(new Date()); //

	if (item.startDate) {
		item.isToday = isSameDay(item.startDate, new Date());
		item.isPast = !item.isToday && isAfter(today, startOfDay(item.startDate));
		item.isFuture = !item.isToday && !item.isPast && isAfter(startOfDay(item.startDate), today);
		item.startTime = item.withStartTime ? format(item.startDate, TIME_FORMAT) : '';

		if (item.endDate) {
			item.daysDiff = differenceInCalendarDays(item.endDate, item.startDate);
			item.isMultiDay = true;
			item.endTime = item.withEndTime ? format(item.endDate, TIME_FORMAT) : '';
		}
		if (item.isPast) {
			item.isNotDone = item.state === DocumentState.Todo;
		}
	}

	if (item.dueDate) {
		item.isOverdue = item.state === DocumentState.Todo && isAfter(endOfToday, item.dueDate);
	}
	return item;
};
