export enum DocumentState {
	None = -1,
	Todo = 0,
	Done = 1,
	Failed = 2,
	Cancelled = 3,
}
export enum DocumentSavingState {
	Idle = 0,
	InProgress = 1,
	Saved = 2,
}
