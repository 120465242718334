import { types } from 'mobx-state-tree';
import { BoardType } from '@smarttime/core';

export const UserBoardModel = types.model('UserBoard', {
	id: types.identifier,
	title: types.string,
	type: types.optional(types.integer, BoardType.OriginalBoard),
	background: types.optional(types.string, ''),
	colorScheme: types.optional(types.string, 'light'),
});
