import { TypographyTheme } from '../../types/theme.types';

export const DomineTheme: TypographyTheme = {
	title: 'Domine',
	combination: {
		headings: {
			font: 'Domine',
			weight: [300, 400, 500, 700],
		},
		body: {
			font: 'Open Sans',
			weight: [300, 400, '400i', 700, '700i'],
		},
		subsets: ['cyrillic'],
	},
	style: 'Sans-Serif',
};
