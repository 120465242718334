// import React from 'react';
import { styled } from '@linaria/react';

export interface StyledBoxProps {
	margin: string;
	padding: string;
	width: string;
}

const StyledBox = styled.div`
	margin: ${(props: StyledBoxProps) => props.margin};
	padding: ${(props: StyledBoxProps) => props.padding};
	max-width: ${(props: StyledBoxProps) => props.width};
	border: 4px solid red;
`;

StyledBox.displayName = 'StyledBox';

export { StyledBox };
