import { TypographyTheme } from '../../types/theme.types';

export const RobotoSlabPtSansTheme: TypographyTheme = {
	title: 'Roboto Slab PtSans',
	combination: {
		headings: {
			font: 'Roboto Slab',
			weight: [300, 400, 500, 600, 700],
		},
		body: {
			font: 'PT Sans',
			weight: [400, 500, 600, 700],
		},
		subsets: ['cyrillic'],
	},
	style: 'Sans-Serif',
};
