export const fontsConfig = {
	// display: 'Playfair Display',
	display: 'Rubik',
	// display: 'Volkhov',
	// display: 'Grenze Gotisch',
	// display: 'Averia Serif Libre',
	// display: 'Arima Madurai', // 900
	// display: 'Carter One',
	// display: 'Fredoka One',
	// display: 'Comfortaa',
	// display: 'Baloo Tammudu 2',
	text: 'Rubik',
};

export const fontSubsets = ['cyrillic', 'latin-ext'];
