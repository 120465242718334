import { TypographyTheme } from '../../types/theme.types';

export const RubikTheme: TypographyTheme = {
	title: 'Rubik',
	combination: {
		headings: {
			font: 'Rubik',
			weight: [400, 500, 600, 700],
		},
		body: {
			font: 'Rubik',
			// font: 'PT Sans',
			weight: [100, 200, 300, 400, 500, 600, 700],
		},
		subsets: ['cyrillic'],
	},
	style: 'Sans-Serif',
};
