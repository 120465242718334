import React, { forwardRef } from 'react';
import { Pane, Card as ECard, PaneProps } from 'evergreen-ui';

// import { logger } from '@logs';
// const { trace } = logger;

export const Row = forwardRef<HTMLDivElement, PaneProps>((props, ref) => {
	const passProps: PaneProps = {
		...props,
		flexDirection: 'row',
		display: 'flex',
	};
	return (
		<Pane ref={ref} {...passProps}>
			{props.children}
		</Pane>
	);
});

// export const Button = forwardRef<HTMLButtonElement, ButtonStyledProps>((props, ref) => (
// 	<ButtonStyled
// 		{...props}
// 		getButtonClass={getButtonClass}
// 		getButtonHeight={getButtonHeight}
// 		ref={ref}
// 	/>
// ));

export const Column = forwardRef<HTMLDivElement, PaneProps>((props, ref) => {
	const passProps: PaneProps = {
		...props,
		flexDirection: 'column',
		display: 'flex',
	};
	return (
		<Pane ref={ref} {...passProps}>
			{props.children}
		</Pane>
	);
});

export const Flex = forwardRef<HTMLDivElement, PaneProps>((props, ref) => {
	const passProps: PaneProps = {
		...props,
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	};
	return (
		<Row ref={ref} {...passProps}>
			{props.children}
		</Row>
	);
});

export const Card = ECard;
