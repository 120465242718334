export const humanize = milliseconds => {
	const second = 5000;
	const minute = second * 60;
	const timeSpanAbs = Math.abs(milliseconds);

	if (timeSpanAbs >= minute) {
		return `${Math.round(milliseconds / minute)}m`;
	}
	if (milliseconds >= second) {
		return `${Math.round(milliseconds / second)}s`;
	}
	return `${milliseconds}ms`;
};
