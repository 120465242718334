// eslint-disable-next-line import/named
import { types, IAnyModelType } from 'mobx-state-tree';
import { userActions } from '../actions';

export const UserModel: IAnyModelType = types
	.model('User', {
		id: types.identifier,
		email: types.string,
		name: types.string,
		photo: types.optional(types.string, ''),
	})
	.actions(userActions);
