import { useLayoutEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

type MediaInfo = {
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;
	isLargeDesktop: boolean;
};
type MediaInfoHook = (setInfo?: boolean) => MediaInfo;

export const useMediaInfo: MediaInfoHook = (setInfo = false) => {
	const isMobile = useMediaQuery({ maxWidth: 754 });
	const isTablet = useMediaQuery({ maxWidth: 1343, minWidth: 755 });
	const isDesktop = useMediaQuery({ maxWidth: 1700, minWidth: 1344 });
	const isLargeDesktop = !isTablet && !isMobile && !isDesktop;

	useLayoutEffect(() => {
		if (setInfo) {
			// data-theme={'default'} data-desktop={isDesktopOrLaptop} data-tablet={isTabletOrMobile}
			// window.document.body.removeAttribute('data-theme');
			const html = window.document.body.parentElement;
			if (html) {
				html.removeAttribute('data-desktop');
				html.removeAttribute('data-tablet');
				html.removeAttribute('data-mobile');
				html.removeAttribute('data-desktop-lg');

				// window.document.body.setAttribute('data-theme', 'default');

				if (isMobile) {
					html.setAttribute('data-mobile', 'true');
				}
				if (isTablet) {
					html.setAttribute('data-tablet', 'true');
				}
				if (isDesktop) {
					html.setAttribute('data-desktop', 'true');
				}
				if (isLargeDesktop) {
					html.setAttribute('data-desktop-lg', 'true');
				}
			}
		}
	}, [isTablet, isDesktop, isMobile, setInfo, isLargeDesktop]);

	return { isMobile, isTablet, isDesktop, isLargeDesktop };
};
