import { Size } from 'types/base.types';
import { AvailableFonts } from 'types/font.types';
import { TypographyElement } from 'types/theme.types';
import { buildVariableName } from './variables';

const resetElementStyle = (
	variant: TypographyElement,
	styles: Array<string>
): string => `${variant} {
  ${styles.join('\n')}
}`;

const resetSpace = `
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 0;
    padding: 0;
`;

const maxWidth = (size: Size | 'default') => `max-width: var(--max-width-size-${size});`;

const fontSettings = (
	name: TypographyElement,
	size: Size | 'default',
	fontFamily: AvailableFonts
) => `
    font-size: var(${buildVariableName(name, size, 'fontSize')});
    line-height: var(${buildVariableName(name, size, 'lineHeight')});
    font-family: '${fontFamily}';
`;

const extraSpace = (size: Size | 'default') => `margin-bottom: var(--space-size-${size});`;

export const resetBodyStyle = (font: AvailableFonts): string => {
	return resetElementStyle('body', [resetSpace, fontSettings('body', 'default', font)]);
};

type ResetStyleType = (
	element: Exclude<TypographyElement, 'body'>,
	size: Size | 'default',
	font: AvailableFonts,
	variant: TypographyElement,
	useMaxWidth: boolean
) => string;

export const resetStyle: ResetStyleType = (
	element,
	size,
	font,
	variant,
	useMaxWidth = true
): string => {
	const styles = [resetSpace, fontSettings(variant, size, font), extraSpace(size)];
	if (useMaxWidth) {
		maxWidth(size);
	}
	return resetElementStyle(element, styles);
};
