import { css } from '@linaria/core';

const login = css`
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	position: relative;
	margin: 0;
	overflow: auto;
	background: #000000;
	.block {
		animation: app-appear 1s ease-out;

		@keyframes app-appear {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
		position: relative;
		/* justify-content: center; */
		/* align-items: center; */
		width: 60rem;
		/* border-radius: 50%; */
		margin: calc(40vh - 30rem) auto auto calc(20vw - 10rem);
		z-index: 2;
		background: var(--bg-color);
		border-radius: 4px;
		box-shadow: 0 0 15px #00000021;
		padding: 3rem 8rem 5rem;
		/* text-align: center; */
	}

	h1 {
		font-weight: normal;
		font-size: 3.2rem;
		margin: 3rem 0;
	}

	p,
	div,
	li {
		max-width: initial;
		font-size: 1.8rem;
		line-height: 2.5rem;
	}
	p,
	h1,
	h3 {
		text-align: center;
	}

	.why-us {
		flex-wrap: wrap;
		div {
			flex: 40%;
			//box-shadow: 0 0 7px #00000013;
			padding: 3rem 1rem;
			text-align: center;
			margin: 0.5rem;
			font-size: 1.5rem;
			text-transform: lowercase;
			/* position: relative;
			.icon {
				position: absolute;
				top: 10%;
				left: 2rem;
			} */
			b {
				font-size: 2rem;
				text-transform: uppercase;
				font-weight: normal;
			}
			&.first {
				border-radius: 2rem 0 0 0;
				box-shadow: -3px -3px 5px #00000011;
			}
			&.last {
				border-radius: 0 0 2rem 0;
				box-shadow: 3px 3px 5px #00000011;
			}
		}
	}
	.login-button {
		text-decoration: none;
		font-size: 1.8rem;
		font-weight: normal;
		padding: 2rem 3rem;
		line-height: 3rem;
		width: 100%;
		justify-content: center;
		align-items: center;
		height: 5rem;
		color: var(--text-color);
		box-shadow: 0 0 3px var(--cta-color) !important;
		/* border: 2px solid transparent; */
		/* border: 1px solid var(--stroke-color) !important; */
		img {
			margin-right: 2rem;
		}
	}
	.demoLink {
		color: #8bb1d4;
	}

	&[data-mobile='true'] {
		background: var(--bg-color);
		.loginLayout {
			width: 100%;
			margin: 0;
			padding: 0;
		}
		.block {
			width: 100%;
			padding: 2rem;
			margin: 0;
			border-radius: 0;
			box-shadow: none;
		}
		h1 {
			font-weight: normal;
			font-size: 2.5rem;
			margin: 1rem 0;
		}
		p,
		div,
		li {
			max-width: initial;
			font-size: 1.4rem;
			line-height: 2rem;
		}
		.why-us > div,
		.why-us > div.first,
		.why-us > div.last {
			/* flex: 1; */
			box-shadow: none;
			padding: 2rem 1rem;
		}
	}
`;

export default login;
