import { Node } from 'slate';

export enum DocumentContextType {
	None = 0,
	Date = 1, // => DocumentContextDate
	Period = 2, // => DocumentContextPeriod
	Recurrent = 3, // => DocumentContextRecurrent
	Path = 4, // => string
	Custom = 5, // => custom implementation and positioning rules,
}

export type Project = {
	id: string;
	dbId?: string;
	board?: string;
	title: string;
} & ProjectPreset;

export type ProjectPreset = Partial<{
	icon: string;
	content: Node[];
	color: string;
	fav: boolean;
	// tracking: {
	// 	sets: Array<{ id: string; target: number; current: number }>;
	// 	currentSet: string;
	// };
}>;

export type ProjectMeta = Partial<{
	id: string;
	title: string;
	color: string;
	icon: string;
	fav: boolean;
}>;

export type UserProfile = {
	user: string;
	name: string;
} & UserProfileMeta;

export type UserProfileMeta = Partial<{
	photo: string;
	defaultBoard: string;
	language: string;
	onBoarded: string[];
	timelineViewMode: number;
	timelineScrollSnap: boolean;
	background: string;

	// user specific settings should be here
}>;

export enum BoardType {
	OriginalBoard = 0,
	DemoProfile = 1,
	PrivateBoard = 2,
	SharedAdminBoard = 3,
}

export type UserBoard = {
	id: string;
} & UserBoardMeta;

export type UserBoardMeta = {
	title: string;
	type: BoardType;
	background?: string;
	color?: string;
};

export type DocumentContextRecurrent = {
	recurrence: Array<RecurrencePattern>;
};

export type RecurrencePattern = {
	scale: DatePeriodScale;
	conditions: Array<unknown>;
};

export enum DatePeriodScale {
	Y = 'Y', // yearly
	Q = 'Q', // quarterly
	M = 'M', // monthly
	W = 'W', // weekly
	D = 'D', // daily
	C = 'C', // custom
}
