import { SansSerifDefaultTheme } from './SansSerifDefault';

export * from './DisplayDefault';
export * from './MonospaceDefault';
export * from './SansSerifDefault';
export * from './SerifDefault';
export * from './USWebStandard';
export * from './Domenie';
export * from './Alegreya';
export * from './RobotoCondensedCabin';
export * from './LatoAbrilFatface';
export * from './LatoArvo';
export * from './LatoKarla';
export * from './Palanquin';
export * from './MeToMe';
export * from './Bitter';
export * from './Rubik';
export * from './Newsreader';
export * from './CharterInter';
export * from './RobotoSlabPtSans';
export * from './MetomeLanding';
export const DefaultTheme = SansSerifDefaultTheme;
