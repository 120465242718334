import { buildThemeOverridesVariableName } from './variables';
import { TypographyElement, FontThemeOverride } from 'types/theme.types';

const themeOverrideAttributes: Record<keyof FontThemeOverride, string> = {
	opacity: 'opacity',
	weight: 'font-weight',
	letterSpacing: 'letter-spacing',
	transform: 'text-transform',
};

export const overrideElementStyles = (
	variant: TypographyElement,
	override: Partial<FontThemeOverride>
): Array<string> => {
	return Object.keys(override).map(
		key =>
			`${
				themeOverrideAttributes[key as keyof FontThemeOverride]
			}: var(${buildThemeOverridesVariableName(variant, key as keyof FontThemeOverride)});`
	);
};
