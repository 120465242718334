export enum DocumentType {
	General = 1,
	Event = 2, // "EVENT"
	Note = 3, // "NOTE",
	Task = 4, // "TASK",
	TaskList = 5, // "TASK",
	DayTitle = 6,
	Goal = 10, // "GOAL"
	Freewriting = 100,
}
export type DocumentTypeDescriptor = {
	id: string;
	// implement custom type descriptor
	// which can contain template, specific data structure
};
