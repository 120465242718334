import { humanize } from './time';
import { selectColor } from '../utils';
import { loggingSettings } from './loggerSettings';

const colored = str => `%c${str}%c`;

const timeAppender = colors => {
	let previousTime;
	const ellapsed = () => {
		const currentTime = Number(new Date());
		const diff = currentTime - (previousTime || currentTime);
		previousTime = currentTime;
		return humanize(diff);
	};
	return (...args) => {
		const [message, ...rest] = args.reduce((acc, val) => acc.concat(val), []);
		return [`${message} %c${ellapsed()}`, ...rest, ...colors];
	};
};

const prefixAppender =
	(prefix, colors, separator = ' → ') =>
	(...args) => {
		const [message, ...rest] = args.reduce((acc, val) => acc.concat(val), []);
		return [`${colored(prefix)}${separator}${message}`, ...colors, ...rest];
	};

const compose =
	(...fns) =>
	value =>
		fns.reverse().reduce((acc, fn) => fn(acc), value);

const colorStr = color => `color: ${color}`;

const functionCallFormatter = (namespace, colors) => {
	const styles = colors || [colorStr(selectColor(namespace)), 'inherit'];
	const pre = prefixAppender(namespace, styles);
	const tail = timeAppender(styles.slice(0, 1));

	const prefixedMessage = (prefix = pre, ...args) => {
		let [first, ...rest] = args;
		const format = compose(tail, prefix);

		if (typeof first !== 'string') {
			first = ['%O', first];
		}
		return [...format(first), ...rest];
	};

	const message = (...args) => prefixedMessage(pre, ...args);

	return { message, prefixedMessage, styles };
};

class Logger {
	constructor(key, namespace, enabled = false) {
		this.key = key;
		this.namespace = namespace;
		this.instances = [];
		this.scopeInstance = {};
		this.prepareMessage = functionCallFormatter(namespace);
		this.log =
			this.scope =
			this.scopeEnd =
				() => {
					// do nothing
				};
		if (enabled) {
			this.log = console.log;
			this.scope = console.group;
			this.scopeEnd = console.groupEnd;
		}

		this.trace = (...args) => {
			this.log(...this.prepareMessage.message(...args));
		};
	}

	startScope(label) {
		this.scope(...this.prepareMessage.message(label));
		this.instances.unshift([this.prepareMessage, label]);
		this.prepareMessage = functionCallFormatter('⮑ ');
	}

	endScope() {
		const [[newFormat, label]] = this.instances.splice(0, 1);
		this.prepareMessage = newFormat;
		const { prefixedMessage } = newFormat;
		this.log(...prefixedMessage(prefixAppender(`✓ ${label}`, newFormat.styles), 'Completed!'));
		this.scopeEnd();
	}

	extend(name) {
		return loggerFactory(this.key)(`${this.namespace}:${name}`);
	}
}

const factory = key => {
	const loggerKey = key || 'debug';
	const loggers = {};
	const rules = loggingSettings(loggerKey);
	return namespace => {
		if (!{}.hasOwnProperty.call(loggers, namespace)) {
			loggers[namespace] = new Logger(loggerKey, namespace, rules.enabled(namespace));
		}
		return loggers[namespace];
	};
};

export const loggerFactory = key => factory(key);
