import { types } from 'mobx-state-tree';
import { startOfDay } from 'date-fns';

import { UserModel } from './user.model';
import { DocumentInfoModel } from './documentInfo.model';
import { DocumentAreasModelCreator } from './day.model';
import { UserBoardModel } from './userBoard.model';
import { rootActionsUserData, rootActionsDocuments, rootActionsAuth } from '../actions';
import { rootViews } from '../views';
import { storeLogger } from '../../logger';
import { CoreModelCreator, StoreInitializer } from '../store.types';

const trace = storeLogger.extend('model').trace;

export const CoreModel: CoreModelCreator = coreExtensions => {
	const userModel = coreExtensions.userModel
		? types.compose(UserModel, coreExtensions.userModel)
		: UserModel;

	const userBoardModel = coreExtensions.userBoardModel
		? types.compose(UserBoardModel, coreExtensions.userBoardModel)
		: UserBoardModel;

	const documentInfoModel = coreExtensions.documentInfoModel
		? types.compose(DocumentInfoModel, coreExtensions.documentInfoModel)
		: DocumentInfoModel;

	const coreModel = types.model({
		user: types.maybeNull(userModel),

		boards: types.optional(types.map(userBoardModel), {}),
		activeBoard: types.maybeNull(types.reference(userBoardModel)),

		documents: types.optional(types.map(documentInfoModel), {}),
		selectedDocument: types.maybeNull(types.reference(documentInfoModel)),

		areas: types.optional(types.map(DocumentAreasModelCreator(documentInfoModel)), {}),

		selectedDay: types.optional(types.Date, startOfDay(new Date())),
	});
	return coreModel;
};

// const testModel1 = types.model('TestModel1', {
// 	id: types.identifier,
// 	prop1: types.string,
// });

// const testModel2 = types.model('TestModel2', {
// 	prop2: types.string,
// });
// const rootTestModel1 = types.model({
// 	ttt: testModel1,
// });
// const rootTestModel2 = types.model({
// 	ttt: testModel2,
// });

// const TestResultModel = types.compose(rootTestModel1, rootTestModel2);
// interface ITRes extends Instance<typeof TestResultModel> {}
// const TestModel = TestResultModel.create({ ttt: { id: 'asdfasdf', prop1: 'Hi', prop2: 'world' } });
// trace('TEST MODEL', TestModel, TestModel.toJSON());

export const StoreModelCreator: StoreInitializer = (
	name,
	dependencies,
	extensions,
	storeViews,
	storeActions
) => {
	const rootModel = CoreModel(extensions)
		.views(rootViews)
		.actions(self => {
			return {
				...rootActionsAuth(self),
				...rootActionsDocuments(self),
				...rootActionsUserData(self),
			};
		});
	const storeModel = types
		.compose(rootModel, extensions.rootModel)
		.views(storeViews)
		.actions(storeActions)
		.named(name);

	trace('STORE deps:', dependencies);
	return [storeModel.create({}, dependencies), storeModel];
};
