import { TypographySettings, CssBaselineData } from 'types/typography.types';
import { generateElementClasses, generateRootClass } from './CSSStyles';
import { TypographyTheme } from 'types/theme.types';

export const buildCSS = (
	typography: TypographySettings,
	theme: TypographyTheme
): CssBaselineData => {
	return {
		cssVariables: generateRootClass(typography, theme),
		cssResetStyles: generateElementClasses(typography, theme).join('\n'),
	};
};
