import { TypographyTheme } from '../../types/theme.types';

export const SansSerifDefaultTheme: TypographyTheme = {
	title: 'Sans-Serif default',
	combination: {
		headings: {
			font: 'Merriweather',
			weight: [400, 700],
		},
		body: {
			font: 'Open Sans',
			weight: [400, '400i', 600, '600i', 700],
		},
		subsets: ['cyrillic'],
	},
	style: 'Sans-Serif',
};
