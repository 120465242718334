import { loggerFactory } from '@smarttime/core';

export const factory = loggerFactory('debug');

export const logger = factory('debug');
export const trace = logger.trace;

export const storeLogger = factory('store');
export const storeTrace = storeLogger.trace;

export const appSyncLogger = factory('appsync');
export const appSyncTrace = appSyncLogger.trace;
