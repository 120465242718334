import { TypographyTheme } from '../../types/theme.types';

export const UsWebStandardTheme: TypographyTheme = {
	title: 'US Web Standard',
	combination: {
		headings: {
			font: 'Merriweather',
			weight: [700],
		},
		body: {
			font: 'Source Sans Pro',
			weight: [400, '400i', 700],
		},
		subsets: ['cyrillic'],
	},
	style: 'Sans-Serif',
};
