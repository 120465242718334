import { Project, DatePeriodScale } from './DocumentContext';
import { Node } from 'slate';
import { DocumentType, DocumentTypeDescriptor } from './DocumentType';
import { DocumentState } from './DocumentState';

export type DocumentUserAccess = {
	user: string;
	access: DocumentAccess;
};

export enum DocumentAccess {
	Read = 0,
	ModifyContent = 2, // edit content
	Modify = 3, // edit content and position
	Full = 5, // including remove, move, etc.
}
export type TimelinePosition = 'past' | 'today' | 'future';

export type DocumentContentNode = Node;
export { Node };

export type Document = {
	cid: string;
	type: DocumentType | DocumentTypeDescriptor;
	content: DocumentContentNode[];
	state: DocumentState;
} & Partial<{
	dbId: string;
	// created: Date;
	board: string;
	project: Project | string;
	startDate: Date;
	withStartTime: boolean;
	endDate: Date;
	withEndTime: boolean;
	isFocused: boolean;
	periodYear: number;
	periodScale: DatePeriodScale;
	dueDate: Date;
	createdAt: Date;
	updatedAt: Date;
	isTemplate: boolean;
	icon: string;
	color: string;
	location: string; // string or Location object (with maps integration)
	owner: string;
	audience: Array<DocumentUserAccess>;
	beforeItemCid: string;
	mood: string;
	duration: number;
}>;

export type DocumentPreset = Partial<Document>;

export type DocumentInfo = Document &
	Partial<{
		title: string;
		startTime: string;
		endTime: string;
		daysDiff: number;
		isMultiDay: boolean;
		isPast: boolean;
		isToday: boolean;
		isFuture: boolean;
		isNotDone: boolean;
		isOverdue: boolean;
		isNew: boolean;
		isUpdated: boolean;
		isRemoved: boolean;
		isTemplate: boolean;
	}>;
