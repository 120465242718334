import React from 'react';
import ReactDOM from 'react-dom';

import { initI18n } from '@smarttime/core-ui';
import resources from '@src/resources';

import './index.css';
// import 'react-day-picker/lib/style.css';
// import 'emoji-mart/css/emoji-mart.css';

import { AppIndex } from '@src/pages';

initI18n(resources);

// if (process.env.NODE_ENV !== 'production') {
// 	// eslint-disable-next-line @typescript-eslint/no-var-requires
// 	const whyDidYouRender = require('@welldone-software/why-did-you-render');
// 	whyDidYouRender(React);
// }

ReactDOM.render(
	<React.StrictMode>
		<AppIndex />
	</React.StrictMode>,
	document.getElementById('root')
);
