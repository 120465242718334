// eslint-disable-next-line
import { types, IAnyModelType } from 'mobx-state-tree';
import { values } from 'mobx';
import { isAfter } from 'date-fns';

import { DocumentInfoModel } from '../models';
import { IDocumentInfo } from '../store.types';

export const DocumentAreasModelCreator: (documentInfoModel: IAnyModelType) => IAnyModelType =
	documentInfoModel =>
		types
			.model('DocumentAreas', {
				key: types.identifier,
				items: types.optional(types.array(types.reference(documentInfoModel)), []),
			})
			.views(self => ({
				get length() {
					return self.items.length;
				},
			}));

export const DocumentAreasModel: IAnyModelType = DocumentAreasModelCreator(DocumentInfoModel);

export const DayModel: IAnyModelType = types
	.model('Day', {
		id: types.identifier,
		date: types.Date,
		items: types.optional(types.array(types.reference(DocumentInfoModel)), []),
	})
	.views(self => ({
		get itemsSorted() {
			return (values(self.items) as IDocumentInfo[]).sort(
				(a: IDocumentInfo, b: IDocumentInfo) => {
					return isAfter(a.startDate as Date, b.startDate as Date) ? -1 : 1;
				}
			);
		},
		get length() {
			return self.items.length;
		},
		get lengthWithTime() {
			return self.items.filter(doc => doc.withStartTime).length;
		},
	}));
