import { TypographyTheme } from '../../types/theme.types';

export const LatoKarlaTheme: TypographyTheme = {
	title: 'Lato + Karla',
	combination: {
		headings: {
			font: 'Lato',
			weight: [500],
		},
		body: {
			font: 'Karla',
			weight: [400, '400i', 700, '700i'],
		},
		subsets: ['cyrillic'],
	},
	style: 'Sans-Serif',
};
