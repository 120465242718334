import React, { forwardRef } from 'react';
import { css } from '@linaria/core';
import {
	IconButtonStyled,
	ButtonStyled,
	ButtonStyledProps,
	GetButtonClass,
	GetButtonHeight,
	IconButtonStyledProps,
} from '@smarttime/core-ui';

const baseButtonClass = css`
	box-shadow: none !important;

	&:not([disabled]):not([data-disabled]) {
		color: var(--text-color) !important;
	}

	&:not([disabled]):not([data-disabled]):active {
		/* outline: 2px solid var(--button-color) !important; */
		box-shadow: 0px 0px 10px var(--button-color-darker) !important;
	}
	&[disabled] {
		color: var(--disabled-color) !important;
	}
	svg {
		/* fill: red !important; */
		fill: var(--icon-color) !important;
	}
`;
const defaultButtonClass = css`
	&:not([disabled]):not([data-disabled]) {
		color: var(--text-color) !important;
	}

	background: var(--button-color) !important;
	border: 1px solid var(--button-color) !important;
	box-shadow: none !important;
	&:hover {
		background: var(--button-color-darker) !important;
		border: 1px solid var(--button-color-darker) !important;
	}
`;
const minimalButtonClass = css`
	background: transparent !important;
	border: 1px solid transparent !important;
	&:hover {
		background: var(--button-color) !important;
		border: 1px solid var(--button-color) !important;
		/* color: white !important; */
	}
`;
const primaryButtonClass = css`
	background: var(--cta-color) !important;
	border: 1px solid var(--cta-color) !important;
	&:not([disabled]):not([data-disabled]) {
		color: var(--text-opposite-color) !important;
	}
	svg {
		fill: var(--text-opposite-color) !important;
	}
	&:hover {
		background: var(--cta-color-darker) !important;
		border-color: var(--cta-color-darker) !important;
	}
`;

const getButtonClass: GetButtonClass = (appearance, className) => {
	let appearanceClass = defaultButtonClass;
	if (appearance === 'minimal') {
		appearanceClass = minimalButtonClass;
	} else if (appearance === 'primary') {
		appearanceClass = primaryButtonClass;
	}
	return [appearanceClass, baseButtonClass, className].join(' ');
};
const getButtonHeight: GetButtonHeight = (sz, height) =>
	height ? height : sz === 'default' ? 30 : sz === 'small' ? 24 : 40;

// export const Button =
export const Button = forwardRef<HTMLButtonElement, ButtonStyledProps>((props, ref) => (
	<ButtonStyled
		{...props}
		getButtonClass={getButtonClass}
		getButtonHeight={getButtonHeight}
		ref={ref}
	/>
));
export const IconButton = forwardRef<HTMLButtonElement, IconButtonStyledProps>((props, ref) => (
	<IconButtonStyled
		{...props}
		getButtonClass={getButtonClass}
		getButtonHeight={getButtonHeight}
		ref={ref}
	/>
));
