import { TypographyTheme } from '../../types/theme.types';

export const MeToMeTheme: TypographyTheme = {
	title: 'MeToMe',
	combination: {
		headings: {
			font: 'Lora',
			// font: 'M PLUS Rounded 1c',
			// font: 'Bellota Text',
			// font: 'Newsreader',
			weight: [300, 400, 500, 700],
		},
		body: {
			// font: 'Bitter',
			// font: 'Inter',
			// font: 'Raleway',
			// font: 'Noto Serif',
			// font: 'Montserrat',
			font: 'Nunito',
			// font: 'Bellota Text',
			weight: [300, 400, '400i', 500, '500i', 600, 700],
		},
		subsets: ['cyrillic'],
	},
	style: 'Sans-Serif',
};
