export default {
	/**
	 * Colors section
	 * */

	/** hsla(151, 98%, 41%, 1) */
	'--cta-color-hue': '151',
	'--cta-color-saturation': '98%',
	'--cta-color-lightness': '41%',
	'--cta-color-lightness-lighter': '77%',
	'--cta-color-lightness-lightest': '93%',
	'--cta-color-opacity': '1',

	/** hsla(216, 11%, 91%, 1) */
	'--cta-outline-color-hue': '216',
	'--cta-outline-color-saturation': '11%',
	'--cta-outline-color-lightness': '91%',
	'--cta-outline-color-opacity': '1',

	/** hsla(44, 100%, 50%, 1) */
	'--accent-color-hue': '44',
	'--accent-color-saturation': '100%',
	'--accent-color-lightness': '50%',
	'--accent-color-lightness-lighter': '77%',
	'--accent-color-lightness-lightest': '93%',
	'--accent-color-opacity': '1',

	/** hsla(300, 100%, 100%, 1) */
	'--text-color-hue': '300',
	'--text-color-saturation': '100%',
	'--text-color-lightness': '100%',
	'--text-color-lightness-lighter': '88%',
	'--text-color-lightness-lightest': '64%',
	'--text-color-opacity': '1',

	/** hsla(240, 23%, 10%, 1) */
	'--text-opposite-color-hue': '240',
	'--text-opposite-color-saturation': '35%',
	'--text-opposite-color-lightness': '100%',
	'--text-opposite-color-lightness-lighter': '30%',
	'--text-opposite-color-lightness-lightest': '51%',
	'--text-opposite-color-opacity': '1',

	/** hsla(240, 7%, 60%, 1) */
	'--text-secondary-color-hue': '240',
	'--text-secondary-color-saturation': '11%',
	'--text-secondary-color-lightness': '63%',
	'--text-secondary-color-opacity': '1',

	/** hsla(233, 23%, 22%, 1) */
	'--bg-color-hue': '233',
	'--bg-color-saturation': '23%',
	'--bg-color-lightness': '22%',
	'--bg-color-opacity': '1',

	/** hsla(233, 21%, 23%, 1) */
	'--bg-secondary-color-hue': '233',
	'--bg-secondary-color-saturation': '21%',
	'--bg-secondary-color-lightness': '23%',
	'--bg-secondary-color-opacity': '1',

	/** hsla(233, 21%, 25%, 1) */
	'--stroke-color-hue': '233',
	'--stroke-color-saturation': '21%',
	'--stroke-color-lightness': '25%',
	'--stroke-color-opacity': '1',

	/** Custom colors */

	/** hsla(200, 97%, 45%, 1) */
	'--sky--hl-color-hue': '200',
	'--sky--hl-color-saturation': '97%',
	'--sky--hl-color-lightness': '45%',
	'--sky--hl-color-opacity': '1',

	/** hsla(227, 100%, 33%, 1) */
	'--hl-color-hue': '227',
	'--hl-color-saturation': '100%',
	'--hl-color-lightness': '33%',
	'--hl-color-opacity': '1',

	/** hsla(58, 100%, 49%, 1) */
	'--goldenglow-color-hue': '58',
	'--goldenglow-color-saturation': '100%',
	'--goldenglow-color-lightness': '49%',
	'--goldenglow-color-opacity': '1',

	/** hsla(25, 100%, 50%, 1) */
	'--mandarin-color-hue': '25',
	'--mandarin-color-saturation': '100%',
	'--mandarin-color-lightness': '50%',
	'--mandarin-color-opacity': '1',

	/** hsla(146, 66%, 41%, 1) */
	'--parakeet-color-hue': '146',
	'--parakeet-color-saturation': '66%',
	'--parakeet-color-lightness': '37%',
	'--parakeet-color-opacity': '1',

	/** hsla(137, 52%, 25%, 1) */
	'--emerald-color-hue': '137',
	'--emerald-color-saturation': '52%',
	'--emerald-color-lightness': '25%',
	'--emerald-color-opacity': '1',

	/** hsla(44, 100%, 50%, 1) */
	'--garnet-color-hue': '44',
	'--garnet-color-saturation': '100%',
	'--garnet-color-lightness': '57%',
	'--garnet-color-opacity': '1',

	/** hsla(338, 100%, 44%, 1) */
	'--sunset-color-hue': '338',
	'--sunset-color-saturation': '100%',
	'--sunset-color-lightness': '44%',
	'--sunset-color-opacity': '1',

	/** hsla(271, 79%, 60%, 1) */
	'--violet-color-hue': '271',
	'--violet-color-saturation': '70%',
	'--violet-color-lightness': '60%',
	'--violet-color-opacity': '1',

	/** hsla(271, 34%, 36%, 1) */
	'--purple-color-hue': '271',
	'--purple-color-saturation': '34%',
	'--purple-color-lightness': '36%',
	'--purple-color-opacity': '1',

	/** Legacy */
	'--hint-opacity': '0.4',
	'--active-hint-opacity': '0.8',

	'--attention-color-hue': '25',
	'--attention-color-saturation': '',
	'--attention-color-lightness': '',
	'--attention-color-opacity': '1',
	'--icon-button-default-opacity': '0.6',

	'--gray-scale-percentage': '0.5',
};
