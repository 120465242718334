export default {
	common: {
		today: 'Today',
		tomorrow: 'Tomorrow',
		yesterday: 'Yesterday',
	},
	settings: {
		language: 'Language',
		ru: 'Русский',
		en: 'English',
		ua: 'Українська',
		de: 'Deutsch',
		signout: 'Sign Out',
	},
	board: {
		schedule: 'Schedule',
		todo: 'Todo',
		focus: 'Focus',
		goals: 'Goals',
		today: 'Today',
		configure: 'Configure',
		addNew: 'Add New Board',
		boards: 'Boards',
		templates: 'Templates',
		copyBoardContent: 'Copy content from current board',
		createNewBoardTitle: 'Create new board',
		type: 'Type',
		title: 'Title',
		name: 'Name (unique, will be used in url)',
		private: 'Private',
		shared: 'Shared',
		demo: 'Demo',
		save: 'Save boardq',
		add: 'Add board',
		switchBackground: 'Click to switch background or hold to see current image',
	},
	document: {
		multiday: {
			daysLeft: '{{daysLeft}} days left',
			oneDayLeft: 'one day left',
			endsHere: 'ends here',
		},
		actions: {
			pinToFocus: 'Pin to Focus',
			unpinFromFocus: 'Unpin From Focus',
			markUndone: 'Mark As Not Done',
			repeat: 'Repeat',
			moveRemaining: 'Move Remaining',
			moveDone: 'Move Done',
			moveToCurrentPeriod: 'To Current Period',
			moveToNextPeriod: 'To Next Period',
			moveToPrevPeriod: 'To Previous Period',
			moveToday: 'To Today',
			moveUp: 'To Previous Day',
			moveDown: 'To Next Day',
			split: 'Split Item',
			sendToBoard: 'Send To Board',
			remove: 'Remove',
			moreActions: 'More Actions',
			duplicate: 'Duplicate',
			edit: 'Edit',
		},
		editor: {
			icon_color: 'Icon / Color',
			time: 'Time',
			time_q: '-',
			startDate: 'Start Date',
			startDate_q: 'Set Start Date',
			endDate: 'End Date',
			endDate_q: 'Set End Date',
			topic: 'Area',
			setTopic: 'Set Area',
			location: 'Location',
			enterLocation: 'Enter Location',
			noIcon: 'No Icon',
			fullScreenMode: 'Fullscreen Mode',
			template: 'Template',
		},
		hydra: {
			newTask: "What's on your mind?",
			enterNewNotes: '',
			todo: 'To-do',
			hintMessage1: `<0>
						Press
						<1>Enter</1>
						to add details.
					</0>
				`,
			hintMessage2: `<0>
						Double <1>Space</1>
						at the beginning of the line creates
						<3>To-do item</3>.
					</0>
				`,
			hintMessage3: `<0>
						Double <1>—</1> (dash)
						and then <1>Space</1>
						at the beginning of the line will create
						<5>Divider</5>.
					</0>
				`,
			hintMessage4: `<0>
					Then use
					<1>{{modEnterHotkey}}</1>
					or
					<1>Esc</1>
					(or just close popup window) to <5>store your item</5>.
				</0>
				`,
		},
	},
	focus: {
		searchInFocus: 'Search in your Focus',
	},
	goals: {
		WEEKLY: 'Weekly',
		MONTHLY: 'Monthly',
		QUARTERLY: 'Quarterly',
		YEARLY: 'Yearly',
		week: '{{weekKind}} Week',
		weekKindThis: 'This',
		weekKindNext: 'Next',
		weekKindPrev: 'Previous',
		quarter: 'quarter',
		year: 'Year',
		weeklyGoals: 'Weekly Goals',
		review: 'Review',
		noGoals: 'No Goals',
		goalsStat: '{{done}} of {{total}} goals',
		startOfWeek: 'Start of week {{span}}',
		endOfWeek: 'End of week {{span}}',
		currentWeek: 'Current week {{span}}',
		// goalsStat_plural: '{{done}} of {{total}} goals'
	},
	timeline: {
		today: 'Today',
		yesterday: 'yesterday',
		tomorrow: 'tomorrow',
		theMonthOf: 'The month of',
		addNewTask: 'Task',
		addNewNote: 'Note',
		addNewTaskList: 'Todo List',
		addNewEvent: 'Event',
		addTemplate: 'Add New Template',
		addNewGoal: 'Add New Goal',
		whatInYourFocus: "What's in your focus?",
		noEvents: '- no plans yet -',
	},

	templates: {
		hideTemplate: 'Remove from toolbar',
		showTemplate: 'Show in toolbar',
		saveAsTemplate: 'Add template',
		searchInTemplates: 'Search in templates',
	},
};
