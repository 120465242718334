import { TypographyTheme } from '../../types/theme.types';

export const DisplayDefaultTheme: TypographyTheme = {
	title: 'Display default',
	combination: {
		headings: {
			font: 'Playfair Display',
			weight: [400, 500, 700],
		},
		body: {
			font: 'Comfortaa',
			weight: [400, 500, 600, 700],
		},
		subsets: ['cyrillic'],
	},
	style: 'Serif',
};
