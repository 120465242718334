export const loggingSettings = storageKey => {
	let storage;
	try {
		storage = localStorage || null;
	} catch {
		storage = null;
	}
	if (storage) {
		const allowed = [];
		const skipped = [];

		const enabled = name => {
			if (skipped.some(rule => rule.test(name))) {
				return false;
			}

			if (allowed.some(rule => rule.test(name))) {
				return true;
			}
			return false;
		};

		const savedRules = str => {
			return (typeof str === 'string' ? str : '')
				.split(/[\s,]+/)
				.filter(_ => _)
				.map(n => n.replace(/\*/g, '.*?'));
		};

		for (const rule of savedRules(storage.getItem(storageKey))) {
			if (rule[0] === '-') {
				skipped.push(new RegExp(`^${rule.substr(1)}$`));
			} else {
				allowed.push(new RegExp(`^${rule}$`));
			}
		}

		return { enabled };
	}
	return { enabled: true };
};

export const loggingRules = loggingSettings('debug');
