import { FontPack, Typeset, FontPair, FontWeight } from 'types/font.types';
import { NonEmptyArray } from 'types/base.types';

export const buildFontURI = (props: FontPack): string => {
	const families = Object.values<Typeset>(props as FontPair)
		.reduce((acc: Array<Typeset>, typeset: Typeset) => {
			const existing = acc.find(set => set.font === typeset.font);
			if (existing) {
				existing.weight = [
					...new Set([...existing.weight, ...typeset.weight]),
				] as NonEmptyArray<FontWeight>;
			} else {
				acc.push(typeset);
			}
			return acc;
		}, [])
		.reduce((acc: Array<string>, typeset: Typeset) => {
			if ('font' in typeset) {
				acc.push(`${typeset.font.replace(/ +/g, '+')}:${typeset.weight.join(',')}`);
			}
			return acc;
		}, [])
		.join('|');
	let uri = `https://fonts.googleapis.com/css?family=${families}`;
	if (props.subsets.length > 0) {
		uri += `&subset=${props.subsets.join(',')}`;
	}
	uri += `&display=swap`;
	return uri;
};
