// import { getEnv } from 'mobx-state-tree';
import { UserProfileMeta } from '@smarttime/core';
import {
	UserActionsList,
	// UpdateUserProfileAction,
} from '../store.types';
// import { setLocale, getLocale } from '../../resources';

// import { storeLogger } from '../../logger';

// const logger = storeLogger.extend('axn');
// const { trace } = logger;

// const updateUserProfilePhotoAction: UserAction<UpdateUserProfileAction<
// 	string
// >> = self => value => {
// 	self.photo = value;
// 	return updateUserData(self);
// };

// const updateUserProfileDefaultBoardAction: UserAction<UpdateUserProfileAction<
// 	string
// >> = self => value => {
// 	self.defaultBoard = value;
// };

export const userActions: UserActionsList = self => {
	// const dataApi = getEnv(self).data;

	const update = async (changes: UserProfileMeta) => {
		// setIfPresent(changes, self, 'defaultBoard');
		// setIfPresent(changes, self, 'onBoarded');
		// setIfPresent(changes, self, 'language');
		// setIfPresent(changes, self, 'background');
		// setIfPresent(changes, self, 'timelineViewMode');
		if (changes.language) {
			self.language = changes.language;
		}
		if (changes.onBoarded) {
			self.onBoarded = changes.onBoarded;
		}
		if (changes.defaultBoard) {
			self.defaultBoard = changes.defaultBoard;
		}
		if (typeof changes.timelineViewMode !== 'undefined') {
			self.timelineViewMode = changes.timelineViewMode;
		}
		if (typeof changes.timelineScrollSnap !== 'undefined') {
			self.timelineScrollSnap = changes.timelineScrollSnap;
		}
		if (typeof changes.background !== 'undefined') {
			self.background = changes.background;
		}


	};
	// const updateUserProfile: UpdateUserData = userProfile => {
	// 	return dataApi.updateUserProfile(userProfile);
	// };
	return {
		update,
		// updateUserProfilePhoto: updateUserProfilePhotoAction,
		// updateUserProfileDefaultBoard: updateUserProfileDefaultBoardAction,
		// updateUserProfile,
	};
};
