import { TypographyTheme } from '../../types/theme.types';

export const LatoAbrilFatfaceTheme: TypographyTheme = {
	title: 'Lato + Abril Fatface',
	combination: {
		headings: {
			font: 'Abril Fatface',
			weight: [400],
		},
		body: {
			font: 'Lato',
			weight: [400, '400i', 700, '700i'],
		},
		subsets: ['cyrillic'],
	},
	style: 'Sans-Serif',
};
