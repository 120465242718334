import React, { useCallback } from 'react';
// import { Auth } from 'aws-amplify';
import { ArrowRightIcon, Background, Column, Flex, Row, useMediaInfo } from '@smarttime/core-ui';

import { Button } from '@components';
import loginStyles from './Login.styles';
// import { trace } from '@logs';

const Login = () => {
	const { isMobile } = useMediaInfo();

	const onGoogleLoginClick = useCallback(() => {
		// store.googleLogin();
		window.location.href = 'https://app.smarttime.com/signin';
	}, []);

	return (<div className={loginStyles} data-mobile={isMobile}>
		{isMobile ? null : (
			<Background
				// src="https://source.unsplash.com/random"
				src={`https://source.unsplash.com/collection/319663/2560x1440`}
				placeholderSrc="./bg1-min-ph.jpg"
				data-id="background-image"
			// rand={store.ui.randImageId}
			/>
			// <UnsplashImage rand={store.ui.randImageId} />
		)}
		{/* {!isMobile ? (
				<Background
					src="https://images.unsplash.com/photo-1487611459768-bd414656ea10?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2880&q=80"
					// src="./bg2-min.png"
					placeholderSrc="./bg1-min-ph.jpg"
				/>
			) : null} */}
		<div className="layout">
			<Column className="block">
				{/* <TextLogo /> */}
				<Flex>
					<img src="/Registration/logo2561.svg" width="40%" />
				</Flex>
				<h1>Bring clarity and focus to your life!</h1>
				<p>We can help you to be more productive, inspired and focused day by day.</p>
				<br />
				<Row className="why-us">
					<div className="first">
						Manage <br />
						<b>Tasks</b>
					</div>
					<div>
						Take <br />
						<b>Notes</b>
					</div>
					<div>
						Schedule <br />
						<b>Events</b>
					</div>
					<div className="last">
						Set Up <br /> <b>Goals</b>
					</div>
				</Row>
				<br />
				<br />
				<p>Free up your mind and see what's important! Interested? </p>
				<br />
				<Button
					className="login-button"
					iconAfter={ArrowRightIcon}
					appearance="primary"
					sz="large"
					onClick={onGoogleLoginClick}
				>
					Get Started
				</Button>
			</Column>
		</div>
	</div>);

};

export default Login;
