import React from 'react';
import toArray from 'rc-util/lib/Children/toArray';
import { Direction, Alignment } from '../../types/component.types';
import { Size } from '../../types/base.types';
import { StyledSpaceContainer, SpaceItem } from './Space.style';
import { v4 as uuidv4 } from 'uuid';

export interface SpaceProps {
	size: Size;
	direction: Direction;
	alignment?: Alignment;
}

export const Space: React.FC<SpaceProps> = ({ children, size, direction }) => {
	const childNodes = toArray(children, { keepEmpty: false });
	const nodes = childNodes.map((child, index) => {
		return (
			<SpaceItem
				key={`space-item-${uuidv4()}-${index}`}
				isFirst={index === 0}
				size={size}
				direction={direction}
			>
				{child}
			</SpaceItem>
		);
	});
	return (
		<>
			<StyledSpaceContainer
				key={uuidv4()}
				wrap={direction === 'vertical' ? 'nowrap' : 'wrap'}
				direction={direction === 'vertical' ? 'column' : 'row'}
				alignment={direction === 'vertical' ? 'stretch' : 'auto'}
			>
				{nodes}
			</StyledSpaceContainer>
		</>
	);
};
