import { DocumentInfo, DocumentPreset } from '../../models/Document';

export enum DocumentAreas {
	SCHEDULED = 'scheduled',
	TODO = 'todo',
	MULTIDAY = 'multi',
	DAY = 'day',
	MONTH = 'month',
	WEEK = 'week',
	YEAR = 'year',
	QUARTER = 'quarter',
	DUE = 'due',
	INBOX = 'inbox',
	FOCUSED = 'focus',
	PROJECTS = 'projects',
	TEMPLATES = 'templates',
	GOALS = 'goals',
	ALL = 'items',
}

export type AreaMappingRule = (item: DocumentInfo) => string[];
export type AreaMappingRules = Record<string, AreaMappingRule[]>;

export class DocumentAreasMap {
	rules: AreaMappingRules;
	allRules: AreaMappingRule[];

	constructor(rules: AreaMappingRules = {}) {
		this.rules = rules;
		this.allRules = this.getRulesList();
	}

	getRulesList(affectedProps?: string[]): AreaMappingRule[] {
		const arr: AreaMappingRule[] = [];
		if (affectedProps) {
			affectedProps.forEach(prop => {
				(this.rules[prop] || []).forEach(rule => {
					if (!arr.includes(rule)) {
						arr.push(rule);
					}
				});
			});
		} else {
			([] as AreaMappingRule[]).concat(...Object.values(this.rules)).forEach(rule => {
				if (!arr.includes(rule)) {
					arr.push(rule);
				}
			});
		}
		return arr;
	}

	addRule(key: string, rule: AreaMappingRule): void {
		this.rules[key].push(rule);
	}

	getAllKeys(document: DocumentInfo): string[] {
		// console.log('RULES', { all: this.allRules });
		return this.getKeysByRules(document, this.allRules);
	}

	getKeysByRules(document: DocumentInfo, rules: AreaMappingRule[]): string[] {
		const keys: string[] = [];
		rules.forEach((rule: AreaMappingRule) => {
			(rule(document) || []).forEach(key => !keys.includes(key) && keys.push(key));
		});
		return keys;
	}
	getKeysDiff(
		document: DocumentInfo,
		newDocument: DocumentInfo,
		changes: DocumentPreset
	): { toRemove: string[]; toAdd: string[] } {
		const toRemove: string[] = [];
		const toAdd: string[] = [];

		const affectedRules = this.getRulesList(Object.keys(changes));
		// console.log('DIFF', { affectedProps: Object.keys(changes), affectedRules });

		const keysBefore = this.getKeysByRules(document, affectedRules);
		const keysAfter = this.getKeysByRules(newDocument, affectedRules);
		keysBefore.forEach(key => {
			if (!keysAfter.includes(key) && !toRemove.includes(key)) {
				toRemove.push(key);
			}
		});
		keysAfter.forEach(key => {
			if (!keysBefore.includes(key) && !toAdd.includes(key)) {
				toAdd.push(key);
			}
		});

		return { toRemove, toAdd };
	}

	static getPath(...pathKeys: string[]): string {
		return pathKeys.join('/');
	}
}
